import { RateQuote } from '../../modules/rate-quote/types';

export const CLOSE_LINK_MODAL_ACTION_TYPE = 'LINK_MODAL__CLOSE_ACTION' as const;
export const closeLinkModal = () => ({ type: CLOSE_LINK_MODAL_ACTION_TYPE });
export type CloseLinkModal = ReturnType<typeof closeLinkModal>;

// Publish

export const PUBLISH_RATE_QUOTE_ACTION_TYPE = 'RATE_QUOTE__PUBLISH_REQUEST_ACTION' as const;
export const publishRateQuoteRequest = (payload: string) => ({
  type: PUBLISH_RATE_QUOTE_ACTION_TYPE,
  payload
});
export type PublishRateQuoteRequestAction = ReturnType<typeof publishRateQuoteRequest>;

export const PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE = 'RATE_QUOTE__PUBLISH_SUCCESS_ACTION' as const;
export const publishRateQuoteSuccess = (payload: { rateQuote: RateQuote; link: string }) => ({
  type: PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  payload
});
export type PublishRateQuoteSuccessAction = ReturnType<typeof publishRateQuoteSuccess>;

export const PUBLISH_RATE_QUOTE_FAILURE_ACTION_TYPE = 'RATE_QUOTE__PUBLISH_FAILURE_ACTION' as const;
export const publishRateQuoteFailure = (payload: string) => ({
  type: PUBLISH_RATE_QUOTE_FAILURE_ACTION_TYPE,
  payload
});
export type PublishRateQuoteFailureAction = ReturnType<typeof publishRateQuoteFailure>;

// Preview

export const PREVIEW_RATE_QUOTE_ACTION_TYPE = 'RATE_QUOTE__PREVIEW_REQUEST_ACTION' as const;
export const previewRateQuoteRequest = (payload: string) => ({
  type: PREVIEW_RATE_QUOTE_ACTION_TYPE,
  payload
});
export type PreviewRateQuoteAction = ReturnType<typeof previewRateQuoteRequest>;

export const PREVIEW_RATE_QUOTE_SUCCESS_ACTION_TYPE = 'RATE_QUOTE__PREVIEW_SUCCESS_ACTION' as const;
export const previewRateQuoteSuccess = (payload: string) => ({
  type: PREVIEW_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  payload
});
export type PreviewRateQuoteSuccessAction = ReturnType<typeof previewRateQuoteSuccess>;

export const PREVIEW_RATE_QUOTE_FAILURE_ACTION_TYPE = 'RATE_QUOTE__PREVIEW_FAILURE_ACTION' as const;
export const previewRateQuoteFailure = (payload: string) => ({
  type: PREVIEW_RATE_QUOTE_FAILURE_ACTION_TYPE,
  payload
});
export type PreviewRateQuoteFailureAction = ReturnType<typeof previewRateQuoteFailure>;

// Submit

export const SUBMIT_RATE_QUOTE_ACTION_TYPE = 'RATE_QUOTE__SUBMIT_REQUEST_ACTION' as const;
export const submitRateQuoteRequest = (payload: {
  rateQuoteId: string;
  rateQuoteRequestId: string;
}) => ({
  type: SUBMIT_RATE_QUOTE_ACTION_TYPE,
  payload
});
export type SubmitRateQuoteAction = ReturnType<typeof submitRateQuoteRequest>;

export const SUBMIT_RATE_QUOTE_SUCCESS_ACTION_TYPE = 'RATE_QUOTE__SUBMIT_SUCCESS_ACTION' as const;
export const submitRateQuoteSuccess = () => ({
  type: SUBMIT_RATE_QUOTE_SUCCESS_ACTION_TYPE
});
export type SubmitRateQuoteSuccessAction = ReturnType<typeof submitRateQuoteSuccess>;

export const SUBMIT_RATE_QUOTE_FAILURE_ACTION_TYPE = 'RATE_QUOTE__SUBMIT_FAILURE_ACTION' as const;
export const submitRateQuoteFailure = (payload: string) => ({
  type: SUBMIT_RATE_QUOTE_FAILURE_ACTION_TYPE,
  payload
});
export type SubmitRateQuoteFailureAction = ReturnType<typeof submitRateQuoteFailure>;
