import React from 'react';

export const UpArrowIcon = (color: string) => (
  <span>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75673 5.70689L4.90222 0.852379L0.0477103 5.70689H9.75673Z"
        fill={`${color}`}
      />
    </svg>
  </span>
);

export const DownArrowIcon = (color: string) => (
  <span style={{ display: 'inline-flex', transform: 'rotate(180deg)' }}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75673 5.70689L4.90222 0.852379L0.0477103 5.70689H9.75673Z"
        fill={`${color}`}
      />
    </svg>
  </span>
);
