import { styled } from 'baseui';
import React from 'react';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { H2 } from '../headers';
import { AlertIcon } from '../icons';

const UnlockContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const IconWrapper = styled('div', {
  paddingTop: '6px',
  paddingRight: '10px'
});
const UnlockWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const TextWrapper = styled('div', {
  padding: '25px'
});

export const UnlockScreen = ({
  deleteLock,
  cancel
}: {
  deleteLock: () => void;
  cancel: () => void;
}) => (
  <UnlockContainer>
    <UnlockWrapper>
      <IconWrapper>
        <AlertIcon />
      </IconWrapper>
      <H2>Unlock Rate?</H2>
    </UnlockWrapper>

    <TextWrapper>Changes you made to this lock will not be saved.</TextWrapper>
    <UnlockWrapper>
      <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
      <PrimaryButton onClick={deleteLock} width="Long">
        Yes, Unlock Rate
      </PrimaryButton>
    </UnlockWrapper>
  </UnlockContainer>
);
