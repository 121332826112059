import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery
} from 'redux-saga/effects';
import { ApplicationState } from '../..';
import {
  clearModalCacheState,
  getModalCacheState,
  storeModalCacheState
} from '../../../modules/offer-details/local-storage';
import {
  cacheProductDetails,
  getProductDetails as fetchProductDetails,
  GetProductDetailsStandardizedResponse
} from '../../../modules/rate-quote';
import { rateQuoteIdSelector } from '../../../selectors/rate-quote/rate-quote';
import {
  FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  FetchRateQuoteSuccessAction
} from '../../rate-quote/actions';
import {
  CACHE_MODAL_STATE_ACTION_TYPE,
  cacheModalState,
  CacheModalStateAction,
  CLEAR_MODAL_STATE_CACHE_ACTION_TYPE,
  clearModalStateCache,
  ClearModalStateCacheAction,
  CLOSE_OFFER_DETAILS_ACTION_TYPE,
  CloseOfferDetailsAction,
  GET_PRODUCT_DETAILS_ACTION_TYPE,
  GetProductDetailsAction,
  getProductDetailsFailure,
  getProductDetailsSuccess,
  restoreModalStateCache,
  SEED_INITIAL_OFFER_ACTION_TYPE,
  SEED_OFFER_ACTION_TYPE,
  UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE,
  UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE
} from '../actions';
import { EditableOffer } from '../state';

/**
 * Caches the input ModalOffer for the current RateQuoteId
 *
 * @param payload - Offer Modal State
 */
function* handleModalCache({
  payload
}: CacheModalStateAction): Generator<CallEffect | SelectEffect> {
  const rateQuoteId = yield select(rateQuoteIdSelector);
  yield call(storeModalCacheState, rateQuoteId as string, payload);
}

/**
 * Raises a modal cache clear action.
 *
 * @param _ Not used
 */
function* handleModalClosed(_: CloseOfferDetailsAction) {
  yield put(clearModalStateCache());
}

/**
 * Checks to see if there's a WIP modal that hasn't been invalidated by new
 *   work on the network.
 *
 * @param payload Editing rate quote.
 */
function* handleFetchRateQuoteSuccess({
  payload
}: FetchRateQuoteSuccessAction): Generator<CallEffect | PutEffect> {
  const modalState = yield call(getModalCacheState, payload.rateQuoteId, payload.lastModifiedTime);
  if (modalState) {
    yield put(restoreModalStateCache(modalState as { offer: EditableOffer; allQuotes: boolean }));
  }
}

/**
 * Updates the modal state cache based on your current state:
 *   Triggers a cache write if a modal is open.
 *   Ensures the modal is cleared if no modal is open.
 */
function* updateModalStateCache(): Generator<SelectEffect | PutEffect> {
  const offer = yield select(({ offerDetails: { modalOffer } }: ApplicationState) => modalOffer);
  const allQuotes = yield select(
    ({ offerDetails: { isOpenAllQuotes } }: ApplicationState) => isOpenAllQuotes
  );
  if (offer) {
    yield put(
      cacheModalState({ offer, allQuotes } as {
        offer: EditableOffer;
        allQuotes: boolean;
      })
    );
  } else {
    yield put(clearModalStateCache());
  }
}

/**
 * Clears the modal cache.
 *
 * @param _ Not used
 */
function* handleClearModalCache(
  _: ClearModalStateCacheAction
): Generator<SelectEffect | CallEffect> {
  const rateQuoteId = yield select(rateQuoteIdSelector);
  yield call(clearModalCacheState, rateQuoteId as string);
}

function* handleGetProductDetails({ payload: { productId, searchId } }: GetProductDetailsAction) {
  try {
    const result: GetProductDetailsStandardizedResponse = yield call(fetchProductDetails, {
      productId,
      searchId
    });
    if (result.success) {
      const key = `${productId}${searchId}`;
      yield put(getProductDetailsSuccess(result.json));
      yield call(cacheProductDetails, { key, details: result.json });
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    console.error('Could not get product details:', error);
    yield put(getProductDetailsFailure());
  }
}

export const modalSaga = function* () {
  yield all([
    takeEvery(CACHE_MODAL_STATE_ACTION_TYPE, handleModalCache),
    takeEvery(CLOSE_OFFER_DETAILS_ACTION_TYPE, handleModalClosed),
    takeEvery(FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE, handleFetchRateQuoteSuccess),
    takeEvery(CLEAR_MODAL_STATE_CACHE_ACTION_TYPE, handleClearModalCache),
    // Modal Sync Actions
    takeEvery(SEED_OFFER_ACTION_TYPE, updateModalStateCache),
    takeEvery(UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE, updateModalStateCache),
    takeEvery(UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE, updateModalStateCache),
    takeEvery(SEED_INITIAL_OFFER_ACTION_TYPE, updateModalStateCache),
    takeEvery(GET_PRODUCT_DETAILS_ACTION_TYPE, handleGetProductDetails)
  ]);
};
