import React from 'react';

export const Error = () => (
  <div
    style={{
      filter: 'brightness(118%) contrast(119%)'
    }}
  >
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Scenario-1-—-2" transform="translate(-469.000000, -441.000000)">
          <g id="Group-31" transform="translate(187.000000, 236.000000)">
            <g id="Group-6">
              <g id="Group-15" transform="translate(268.000000, 192.000000)">
                <g id="Group-11" transform="translate(14.000000, 13.000000)">
                  <g id="icon_error">
                    <rect
                      id="Rectangle"
                      fill="currentColor"
                      x="0"
                      y="0"
                      width="22"
                      height="22"
                      rx="2"
                    ></rect>
                    <text
                      id="!"
                      fontFamily="Graphik-Semibold, Graphik"
                      fontSize="14"
                      fontWeight="500"
                      fill="#FFFFFF"
                    >
                      <tspan x="9.16666667" y="16">
                        !
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
