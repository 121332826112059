import * as t from 'type-shift';

export const loanType = t.oneOf([
  'Standard',
  'FHA',
  'USDA',
  'Homeready',
  'Homepossible',
  'Homestyle',
  'FHA 203k',
  'Mass Housing',
  'NH Housing',
  'VA'
]);

export type LoanType = t.TypeOf<typeof loanType>;
