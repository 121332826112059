import React from 'react';

export const Unlock = () => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9884 6.33473H4.33841V3.70973C4.33841 2.71807 5.15508 1.84307 6.20508 1.84307C7.25508 1.84307 8.07174 2.65973 8.07174 3.70973V4.23473C8.07174 4.7014 8.42174 5.0514 8.88841 5.0514C9.35508 5.0514 9.70508 4.7014 9.70508 4.23473V3.70973C9.70508 1.84307 8.18841 0.268066 6.26341 0.268066C4.33841 0.268066 2.82175 1.78473 2.82175 3.70973V6.33473H1.48008C1.18841 6.33473 0.955078 6.56807 0.955078 6.85973V13.7431C0.955078 14.0347 1.18841 14.2681 1.48008 14.2681H10.9884C11.2801 14.2681 11.5134 14.0347 11.5134 13.7431V6.85973C11.5134 6.56807 11.2801 6.33473 10.9884 6.33473Z"
      fill="#143F3D"
    />
  </svg>
);
