import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OwnUpHeader } from './components/ownup-header';
import { pickFromHash } from './modules/pick-from-hash';
import { fetchRateQuoteError, fetchRateQuoteRequest } from './redux/rate-quote/actions';
import { ProgressiveLoading } from './views/progressive-loading';
import { RateQuotePanel } from './views/rate-quote-panel';
import { SavePrompt } from './views/save-prompt';

export const App = () => {
  const { isLoggedIn, login } = useInternalAuth();
  const { rateQuoteId } = useParams<{ rateQuoteId: string }>();

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoggedIn) {
      const toQuery = rateQuoteId || pickFromHash(window.location.hash);
      toQuery
        ? dispatch(fetchRateQuoteRequest(toQuery))
        : dispatch(fetchRateQuoteError('Missing ID', 404));
    } else {
      void login(window.location.href);
    }
  }, [isLoggedIn, rateQuoteId, dispatch, login]);

  return (
    <React.Fragment>
      <ProgressiveLoading />
      <OwnUpHeader />
      <RateQuotePanel />
      <SavePrompt />
    </React.Fragment>
  );
};
