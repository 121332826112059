import * as t from 'type-shift';

export interface ErrorResponse {
  success: false;
  json: null;
  error: string;
  code: number;
}

export interface ValidResponse<T> {
  success: true;
  json: T;
  error: null;
  code: number;
}

export type StandardizedResponse<T> = ErrorResponse | ValidResponse<T>;

export const standardizeResponse = async <T>(
  response: Response,
  model: t.Converter<T>
): Promise<StandardizedResponse<T>> => {
  try {
    if (response.ok) {
      const body = response.status === 204 ? null : await response.json();
      const valid = model(body);
      return {
        success: true as const,
        json: valid,
        error: null,
        code: response.status
      };
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    return {
      success: false as const,
      json: null,
      error,
      code: response.status
    };
  }
};
