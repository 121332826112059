import { currency, rate as percent } from '@rategravity/frontend/modules/numbers';
import { styled } from 'baseui';
import React from 'react';
import { useConditionalColumns } from '../../hooks/use-conditional-columns';
import { rateLockDaysLabel } from '../../modules/offer-details/rate-lock-days-label';
import { NotificationGroup } from '../checkable-table/notification-group';
import { Link } from '../link';
import { SortableTable } from '../table-sortable';
import { AllQuotesCTA, CTAProps } from './ctas';

const LenderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});

const InvestorWrapper = styled(LenderWrapper, {
  maxWidth: '80px'
});

export interface AllQuoteTableProps {
  offerId: string;
  productId?: number;
  searchId?: string;
  lender: {
    name: string;
    url: string;
  };
  rate: number;
  pAndI: number;
  mi: { value?: number; required: boolean };
  rateLockPeriod: number;
  closingCosts: number;
  lenderCreditOrPoints: number;
  totalLoanAmount: number;
  dti: number;
  expired: boolean;
  dtiStatus: string | null;
  investor?: string | null;
  price: number;
  published: boolean;
}

export const AllQuotesTable = ({
  data,
  includeOffer,
  viewDetails,
  includeOfferLoading,
  bestOffers
}: Pick<CTAProps, 'viewDetails' | 'includeOffer' | 'includeOfferLoading'> & {
  data: AllQuoteTableProps[];
  bestOffers?: string[];
}) => {
  const { mi: excludeMi } = useConditionalColumns(
    { mi: ({ required }: AllQuoteTableProps['mi']) => !required },
    data
  );
  return (
    <SortableTable
      data={data}
      columns={[
        {
          name: 'LENDER',
          // eslint-disable-next-line react/display-name
          format: ({ lender, expired, dtiStatus, published }: any) => (
            <LenderWrapper>
              <Link href={lender.url}>{lender.name}</Link>
              <NotificationGroup dtiStatus={dtiStatus} expired={expired} published={published} />
            </LenderWrapper>
          ),
          sort: (data1, data2) =>
            data1.lender.name.toLowerCase() > data2.lender.name.toLowerCase() ? 1 : -1
        },
        {
          name: 'INVESTOR',
          // eslint-disable-next-line react/display-name
          format: ({ investor }: any) => <InvestorWrapper>{investor || '-'}</InvestorWrapper>,
          selector: ({ investor }) => investor || NaN
        },
        {
          name: 'LOAN',
          format: ({ totalLoanAmount }: any) => currency(totalLoanAmount, 0),
          selector: ({ totalLoanAmount }) => totalLoanAmount
        },
        {
          name: 'LOCK',
          format: ({ rateLockPeriod }: any) => rateLockDaysLabel(rateLockPeriod),
          selector: ({ rateLockPeriod }) => rateLockPeriod
        },
        {
          name: 'RATE',
          format: ({ rate }: any) => percent(rate, 3),
          selector: ({ rate }) => rate
        },
        {
          name: 'PRICE',
          format: ({ price }: any) => price,
          selector: ({ price }) => price
        },
        {
          name: 'P&I',
          format: ({ pAndI }: any) => (pAndI ? currency(pAndI, 0) : '-'),
          selector: ({ pAndI }) => pAndI || NaN
        },
        {
          name: 'LC/POINTS',
          format: ({ lenderCreditOrPoints }) => currency(lenderCreditOrPoints, 0),
          selector: ({ lenderCreditOrPoints }) => lenderCreditOrPoints
        },
        {
          name: 'CLOSING',
          format: ({ closingCosts }: any) => currency(closingCosts, 0),
          selector: ({ closingCosts }) => closingCosts
        },
        {
          name: 'DTI',
          format: ({ dti }) => percent(dti * 100, 1)
        },
        {
          name: 'MI',
          format: ({ mi: { value } }) => (value ? currency(value, 0) : '-'),
          selector: ({ mi: { value } }) => value || NaN,
          exclude: excludeMi
        },
        {
          name: 'ACTIONS',
          // eslint-disable-next-line react/display-name
          format: ({ offerId, productId, searchId }: any) => (
            <AllQuotesCTA
              offerId={offerId}
              productId={productId}
              searchId={searchId}
              includeOffer={includeOffer}
              includeOfferLoading={includeOfferLoading}
              viewDetails={viewDetails}
            />
          )
        }
      ]}
      defaultSort={[
        ['RATE', 'ASC'],
        ['CLOSING', 'ASC'],
        ['P&I', 'ASC']
      ]}
      includeList={bestOffers}
    />
  );
};
