import { styled, withStyle } from 'baseui';
import { Delete } from 'baseui/icon';
import {
  Modal as BaseModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  StyledClose
} from 'baseui/modal';
import React, { Fragment } from 'react';
import { H1 } from '../headers';
import { Spinner } from '../spinner';

const Header = styled('div', {
  marginLeft: '25px'
});

const HeaderBorder = withStyle(ModalHeader, ({ $theme }) => ({
  borderBottom: `1px solid ${$theme.colors!.mono400}`,
  marginTop: '0',
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0'
}));

const Flex = withStyle(ModalBody, {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  width: '80vw',
  marginTop: '40px'
});

const FooterWrapper = withStyle(ModalFooter, {
  marginBottom: '40px'
});

const CloseIcon = styled(Delete, () => ({
  height: '45px !important',
  width: '45px !important'
}));

const CloseWrapper = withStyle(StyledClose, () => ({
  height: '50px',
  width: '50px'
}));

type LayoutOptions = 'Centered' | 'Regular';

interface ExtendedProps extends ModalProps {
  title?: string | JSX.Element | null;
  buttons?: JSX.Element[];
  loading?: boolean;
  message?: string | null;
  status?: string;
  link?: string | null;
  layout: LayoutOptions;
  networkError?: string | null;
}

export const Modal = ({
  title,
  children,
  buttons,
  loading,
  link,
  message,
  layout,
  networkError,
  onClose,
  ...props
}: ExtendedProps) => {
  const layouts = {
    Centered: { display: 'flex', justifyContent: 'center' },
    Regular: { display: 'block', justifyContent: 'center' }
  };

  const ModalHeaderWithLayout = withStyle(HeaderBorder, {
    display: layouts[layout].display,
    justifyContent: layouts[layout].justifyContent
  });

  const ModalFooterWithLayout = withStyle(FooterWrapper, {
    display: !buttons ? 'none' : layouts[layout].display,
    justifyContent: layouts[layout].justifyContent,
    border: 'none'
  });

  const CloseComponent = () => (
    <CloseWrapper onClick={onClose}>
      <CloseIcon />
    </CloseWrapper>
  );

  return (
    <Fragment>
      <BaseModal
        size={'auto'}
        closeable
        {...props}
        overrides={{
          Dialog: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors!.mono100,
              borderTopLeftRadius: '5px ',
              borderTopRightRadius: '5px ',
              borderBottomLeftRadius: '5px ',
              borderBottomRightRadius: '5px '
            })
          },
          Close: {
            component: CloseComponent
          }
        }}
      >
        <ModalHeaderWithLayout>
          {typeof title === 'string' ? (
            <Header>
              <H1>{title}</H1>
            </Header>
          ) : (
            title
          )}
        </ModalHeaderWithLayout>

        <Flex>
          {loading ? <Spinner size={60} centerVertically={layout !== 'Centered'} /> : children}
        </Flex>
        <ModalFooterWithLayout>
          {buttons && buttons.map((button, i) => <span key={i}>{button}</span>)}
        </ModalFooterWithLayout>
      </BaseModal>
    </Fragment>
  );
};
