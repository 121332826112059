import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '..';

export const allQuotesFilterOptionsSelector = createSelector(
  ({ allQuotesFilterOptions }: ApplicationState) => allQuotesFilterOptions,
  (allQuotesFilterOptions) =>
    of(allQuotesFilterOptions)
      .map(({ lenders, investors, loading }) => ({
        loading,
        lenders: lenders.map((lender) => ({ value: lender })),
        investors: investors.map((investor) => ({ value: investor }))
      }))
      .orElse(() => ({ loading: false, lenders: [], investors: [] }))
);
