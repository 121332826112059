import { currency, rate } from '@rategravity/frontend/modules/numbers';
import { styled } from 'baseui';
import React from 'react';
import { getQuoteRate } from '../../../modules/compute-rate-quote-request-display/get-quote-rate';
import { closingCostLabel, closingToCoverLabel } from '../../../modules/rate-quote/types';
import { EditableOffer } from '../../../redux/offer-details/state';
import { H4 } from '../../headers';
import { Label1, Label2 } from '../../labels';
import { Link } from '../../link';

const MarginDiv = styled('div', {
  marginBottom: '15px'
});

interface AttributeRowProps<T> {
  label: React.ReactNode;
  value: T;
  format?: (arg: T) => React.ReactNode;
}

const AttributeRow = <T,>({ label, value, format = (i) => i }: AttributeRowProps<T>) => {
  return (
    <React.Fragment>
      <MarginDiv>
        <Label1>{label}</Label1>
        <Label2>{format(value)}</Label2>
      </MarginDiv>
    </React.Fragment>
  );
};

export type FullAttributes = EditableOffer['attributes'] & {
  loanSize: number | string;
  closingCost?: number;
};

interface AttributesPanelProps {
  fullAttributes: FullAttributes;
}

export const AttributesPanel = ({
  fullAttributes: {
    product,
    closingCostOption,
    closingToCover,
    loanType,
    escrowOptions,
    interestOptions,
    helocLine,
    fullLender,
    loanSize,
    ltv,
    ...rest
  }
}: AttributesPanelProps) => (
  <div>
    <H4>ATTRIBUTES</H4>
    <div>
      <AttributeRow
        value={fullLender}
        label={'Lender'}
        format={({ url, name }) => <Link href={url}>{name}</Link>}
      />
      <AttributeRow value={product} label={'Product'} format={getQuoteRate} />
      {'downPayment' in rest ? (
        <AttributeRow
          value={rest.downPayment}
          label={'Down Payment'}
          format={(dp) => currency(dp, 0)}
        />
      ) : (
        <AttributeRow
          value={rest.totalCashOut}
          label={'Total cash out'}
          format={(co) => currency(co, 0)}
        />
      )}
      {'upFrontInsuranceFee' in rest && rest.upFrontInsuranceFee !== undefined ? (
        <AttributeRow
          value={rest.upFrontInsuranceFee.value}
          label={rest.upFrontInsuranceFee.label}
          format={(insuranceFee) => currency(insuranceFee || 0, 0)}
        />
      ) : null}
      <AttributeRow
        value={closingCostOption}
        label={'Closing Costs'}
        format={(cc) => closingCostLabel(cc)}
      />
      <AttributeRow
        value={closingToCover}
        label={'Closing to Cover'}
        format={(c2c) => closingToCoverLabel(c2c)}
      />
      <AttributeRow
        value={loanSize}
        label={'Total Loan Amount'}
        format={(size) => (typeof size === 'string' ? size : currency(size, 0))}
      />
      <AttributeRow value={ltv} label={'LTV'} format={(v) => rate(v * 100, 2)} />
      <AttributeRow value={loanType} label={'Loan type'} />
      <AttributeRow
        value={escrowOptions}
        label={'Escrow options'}
        format={(esc) => (esc === 'waive' ? 'Waive' : 'Include')}
      />
      <AttributeRow
        value={interestOptions}
        label={'Interest options'}
        format={(i) => (i === 'interestOnly' ? 'Interest Only' : 'Principal And Interest')}
      />
      <AttributeRow value={helocLine} label={'HELOC line'} format={(h) => currency(h, 0)} />
    </div>
  </div>
);
