import { styled } from 'baseui';
import { StatefulPanel } from 'baseui/accordion';
import { ChevronDown, ChevronRight } from 'baseui/icon';
import React from 'react';
import { StyleObject } from 'styletron-react';
import { Header } from '../headers';

const Toggler = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: $theme.colors!.primary300,
  ...($theme.typography!.font300 as Partial<StyleObject>)
}));

const ToggleComponent = ({ $expanded }: any) => (
  <Toggler>
    {$expanded ? 'HIDE' : 'SHOW'}
    {$expanded ? <ChevronDown size={24} /> : <ChevronRight size={24} />}
  </Toggler>
);

export const Panel = ({
  title,
  children,
  disabled = false,
  expanded = true
}: React.PropsWithChildren<{ title: string; disabled?: boolean; expanded?: boolean }>) => (
  <StatefulPanel
    title={<Header>{title}</Header>}
    initialState={{ expanded }}
    disabled={disabled}
    overrides={{
      ToggleIcon: {
        component: ToggleComponent
      },
      Header: {
        style: ({ $theme }) => ({
          ':hover': {
            backgroundColor: $theme.colors!.mono200
          },
          ':focus': {
            outline: 'none'
          },
          borderBottom: 'none',
          backgroundColor: 'none',
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0
        })
      },
      Content: {
        style: {
          backgroundColor: 'none',
          borderBottom: 'none',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          boxShadow: 'rgba(0,0,0,0.16) 0px 1px 4px'
        }
      }
    }}
  >
    {children}
  </StatefulPanel>
);
