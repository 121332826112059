import React from 'react';

export const Alert = () => (
  <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.672" cy="12.4479" r="11.2413" stroke="#C83434" strokeWidth="2" />
    <svg
      x="0.5"
      y="5"
      width="25"
      height="15"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66576 13.5471C2.46786 13.5471 3.10953 12.9411 3.10953 12.1747C3.10953 11.3904 2.46786 10.7844 1.66576 10.7844C0.881492 10.7844 0.239818 11.3904 0.239818 12.1747C0.239818 12.9411 0.881492 13.5471 1.66576 13.5471ZM0.810195 9.59014H2.55698L2.89564 0.677988H0.489358L0.810195 9.59014Z"
        fill="#C83434"
      />
    </svg>
  </svg>
);
