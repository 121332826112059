import { styled } from 'baseui';
import { Card, StyledBody } from 'baseui/card';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Label2 } from 'baseui/typography';
import React from 'react';
import { H3 } from '../headers';
import { DocumentIcon, HeadIcon, HouseIcon } from '../icons';

const VerticalLine = styled('div', ({ $theme }) => ({
  borderLeft: `1px solid ${$theme.colors!.mono300}`,
  height: '100%'
}));

const ColumnHeader = styled('span', {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center'
});

const DetailLabel = styled('div', ({ $theme }) => ({
  display: 'table-cell',
  color: $theme.colors.primary200,
  paddingRight: '20px',
  paddingBottom: '5px'
}));

const DetailContent = styled('div', {
  display: 'table-cell',
  whiteSpace: 'pre-line',
  paddingBottom: '5px'
});

interface RateQuoteRequestDetailsProps {
  scenario: string[][];
  summary: string[][];
  assumptions: string[][];
}

export const RateQuoteRequestDetails = ({
  scenario,
  summary,
  assumptions
}: RateQuoteRequestDetailsProps) => (
  <div>
    <Card
      overrides={{
        Root: {
          style: {
            backgroundColor: 'white',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
          }
        }
      }}
    >
      <StyledBody>
        <FlexGrid flexGridColumnCount={5} gridColumnGap="scale400">
          <FlexGridItem>
            <OfferColumn
              label={
                <ColumnHeader>
                  <HeadIcon size={24} />
                  <Label2
                    color="mono700"
                    font="font350"
                    overrides={{
                      Block: {
                        style: {
                          paddingLeft: '15px'
                        }
                      }
                    }}
                  >
                    Borrower Scenario
                  </Label2>
                </ColumnHeader>
              }
              data={scenario}
            />
          </FlexGridItem>
          <FlexGridItem overrides={{ Block: { style: { flexGrow: 0, width: '20px' } } }}>
            <VerticalLine />
          </FlexGridItem>
          <FlexGridItem>
            <OfferColumn
              label={
                <ColumnHeader>
                  <DocumentIcon size={24} />
                  <Label2
                    color="mono700"
                    font="font350"
                    overrides={{
                      Block: {
                        style: {
                          paddingLeft: '15px'
                        }
                      }
                    }}
                  >
                    Deal Summary
                  </Label2>
                </ColumnHeader>
              }
              data={summary}
            />
          </FlexGridItem>
          <FlexGridItem overrides={{ Block: { style: { flexGrow: 0, width: '20px' } } }}>
            <VerticalLine />
          </FlexGridItem>
          <FlexGridItem>
            <OfferColumn
              label={
                <ColumnHeader>
                  <HouseIcon size={24} />
                  <Label2
                    color="mono700"
                    font="font350"
                    overrides={{
                      Block: {
                        style: {
                          paddingLeft: '15px'
                        }
                      }
                    }}
                  >
                    Property Assumptions
                  </Label2>
                </ColumnHeader>
              }
              data={assumptions}
            />
          </FlexGridItem>
        </FlexGrid>
      </StyledBody>
    </Card>
  </div>
);

interface OfferColumnProps {
  label: JSX.Element | string;
  data: string[][];
}

const OfferColumn = ({ data, label }: OfferColumnProps) => (
  <div style={{ paddingLeft: '30px', paddingRight: '10px', whiteSpace: 'nowrap' }}>
    <H3>{label}</H3>
    {data.map(([key, value], i) => (
      <div key={i} style={{ display: 'table-row' }}>
        <DetailLabel>{key}</DetailLabel>
        <DetailContent>{value}</DetailContent>
      </div>
    ))}
  </div>
);
