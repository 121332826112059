import { styled } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { History } from 'history';
import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StyleObject } from 'styletron-react';
import { useLastSaved } from '../../hooks/use-last-saved';
import { AutoQuoteStatus } from '../../modules/rate-quote/types';
import { OfferStatus } from '../../modules/types';
import { Borrowers } from '../../modules/value-types/borrower';
import { fetchLockedOfferBySource } from '../../redux/rate-quote/actions';
import {
  lockedRateQuoteBySourceSelector,
  rateQuoteHasOutsideLockedOfferSelector
} from '../../selectors/rate-quote/rate-quote';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { ExternalLinkIcon } from '../icons';
import { Modal } from '../modal';
import { BoldTextNotice } from '../notice';
import { AutoQuoteStatusComponent } from './errors';

const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});

const CardHeader = styled('div', {
  marginTop: '10px',
  marginBottom: '10px'
});

const SavedLabel = styled('div', ({ $theme }) => ({
  marginRight: '20px',
  color: $theme.colors!.primary200,
  ...($theme.typography!.font300 as Partial<StyleObject>)
}));

const NonUSCitizenNotice = () => (
  <BoldTextNotice
    boldText="This borrower is not a US Citizen."
    body=" Please confirm which lenders work with this borrower’s visa before publishing offers."
    type="notice"
  />
);

const OutsideLockedOffer = ({
  sourceId,
  fetchLockBySource
}: {
  sourceId: string;
  fetchLockBySource: (payload: { sourceId: string; history: History<any> }) => void;
}) => {
  const history = useHistory();
  const { value: lockedRateQuote } = useSelector(lockedRateQuoteBySourceSelector);
  return (
    <Fragment>
      {lockedRateQuote && lockedRateQuote.status === 'pending' ? (
        <Spinner size={60} />
      ) : (
        <Fragment>
          {lockedRateQuote && lockedRateQuote.status === 'failed' && (
            <BoldTextNotice
              boldText="There was an error retrieving the locked Rate Quote"
              body={` Error: ${lockedRateQuote.code || 'NoCode'}, ${lockedRateQuote.error}`}
              type="error"
            />
          )}
          <BoldTextNotice
            boldText="A lock already exists on this deal"
            body={
              <div style={{ display: 'inline' }}>
                {' '}
                To lock in a different rate, unlock the{' '}
                <a // eslint-disable-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    fetchLockBySource({ sourceId, history });
                  }}
                  role="link"
                >
                  original rate <ExternalLinkIcon size={12} stroke={'#dd9010'} />
                </a>
                and refresh a request in Advisor Hub.
              </div>
            }
            type="notice"
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export const OffersPanelHeader = ({
  offerStatuses,
  lastModifiedTime,
  publishOffers,
  previewOffers,
  submitOffers,
  rateQuoteId,
  rateQuoteRequestId,
  autoQuoteStatus,
  requester,
  borrowers,
  numOffers,
  sourceId
}: {
  offerStatuses: OfferStatus[];
  lastModifiedTime: number;
  publishOffers: (payload: string) => void;
  previewOffers: (payload: string) => void;
  submitOffers: (payload: { rateQuoteId: string; rateQuoteRequestId: string }) => void;
  rateQuoteId: string;
  rateQuoteRequestId: string;
  autoQuoteStatus: AutoQuoteStatus[];
  requester: string;
  borrowers?: Borrowers;
  numOffers: number;
  sourceId?: string;
}) => {
  const lastSavedText = useLastSaved(lastModifiedTime);

  const verifyComplete = (statuses: OfferStatus[]) =>
    statuses.length > 0 && statuses.every((status) => status === 'Complete') ? false : true;
  const [isPublishInvalidOpen, setPublishInvalidOpen] = useState(false);

  const rateQuoteHasOutsideLockedOffer = useSelector(rateQuoteHasOutsideLockedOfferSelector);

  const dispatch = useDispatch();
  const fetchLockBySource = useCallback(
    (payload: { sourceId: string; history: History<any> }) =>
      dispatch(fetchLockedOfferBySource(payload)),
    [dispatch]
  );

  return (
    <CardHeader>
      <PublishRateQuoteInvalidModal
        isOpen={isPublishInvalidOpen}
        onClose={() => setPublishInvalidOpen(false)}
      />
      <ButtonsContainer>
        <SavedLabel>{`Refreshed ${lastSavedText}`}</SavedLabel>
        <SecondaryButton
          width="Long"
          disabled={verifyComplete(offerStatuses)}
          onClick={() => submitOffers({ rateQuoteId, rateQuoteRequestId })}
        >
          Submit
        </SecondaryButton>
        <SecondaryButton
          width="Long"
          disabled={verifyComplete(offerStatuses)}
          onClick={() => previewOffers(rateQuoteId)}
        >
          Preview
        </SecondaryButton>
        <PrimaryButton
          width="Long"
          disabled={verifyComplete(offerStatuses)}
          onClick={() => {
            requester ? publishOffers(rateQuoteId) : setPublishInvalidOpen(true);
          }}
        >
          Publish
        </PrimaryButton>
      </ButtonsContainer>
      {sourceId && rateQuoteHasOutsideLockedOffer.value && (
        <OutsideLockedOffer sourceId={sourceId} fetchLockBySource={fetchLockBySource} />
      )}
      {autoQuoteStatus.map((s, index) => (
        <AutoQuoteStatusComponent key={index} status={s} />
      ))}
      {borrowers?.some(
        ({ citizenship }) =>
          citizenship === 'PermanentResidentAlien' || citizenship === 'NonPermanentResidentAlien'
      ) && numOffers > 0 ? (
        <NonUSCitizenNotice />
      ) : null}
    </CardHeader>
  );
};

interface PublishRateQuoteInvalidModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ValidationText = styled('div', () => ({
  fontSize: '18px',
  paddingBottom: '10px'
}));

const PublishRateQuoteInvalidModal = ({ isOpen, onClose }: PublishRateQuoteInvalidModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} title="No Advisor Selected" layout="Centered">
    <ValidationText>Please select an advisor in order to publish an offer</ValidationText>
  </Modal>
);
