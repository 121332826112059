import { styled, withStyle } from 'baseui';
import { useStyletron } from 'baseui';
import { SortableHeadCell } from 'baseui/table';
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid';
import { Theme } from 'baseui/theme';
import React, { useMemo, useState } from 'react';
import { DownArrowIcon, UpArrowIcon } from './arrow-icons';

const Table = withStyle(StyledTable, {
  backgroundColor: 'white',
  borderWidth: 0
});

const HeaderCell = withStyle(StyledHeadCell, ({ $theme }) => ({
  backgroundColor: $theme.colors.mono100,
  borderColor: `transparent transparent ${$theme.colors.primary50} transparent`,
  boxShadow: 'none',
  paddingBottom: '20px',
  paddingLeft: 0,
  paddingRight: '20px',
  paddingTop: '20px',
  ':first-child': {
    paddingLeft: '30px'
  },
  ':last-child': {
    paddingRight: '30px'
  }
}));

const TableCell = withStyle(
  StyledBodyCell,
  ({ columnindex, hover, $theme }: { columnindex: number; hover: string; $theme: Theme }) => ({
    borderColor: `transparent transparent ${$theme.colors.primary50} transparent`,
    borderWidth: `0 0 1px 0`,
    borderStyle: `none none solid none`,
    color: $theme.colors.primary400,
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
    height: '60px',
    paddingLeft: columnindex === 0 ? '30px' : 0,
    backgroundColor: hover
  })
);

const Cell = styled(
  'div',
  ({ opacity, colstyle }: { opacity: number; colstyle?: Record<string, string> }) => ({
    opacity,
    ...colstyle
  })
);

const StyledLabel = styled('div', ({ opacity, theme }: { opacity: number; theme: Theme }) => ({
  color: theme.colors.primary400,
  textAlign: 'left',
  fontSize: '14px',
  padding: 0,
  letterSpacing: '1px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  opacity
}));

const LabelComponent = ({
  index,
  sortable,
  name,
  topSortKey,
  topSortDirection,
  setSortHistory,
  sortHistory,
  theme,
  sortArrows
}: {
  index: number;
  sortable: boolean;
  name: string;
  topSortKey?: string;
  topSortDirection?: 'ASC' | 'DESC';
  setSortHistory: (history: [string, 'ASC' | 'DESC'][]) => void;
  sortHistory: [string, 'ASC' | 'DESC'][];
  theme: Theme;
  sortArrows: Record<string, JSX.Element>;
}) => (
  <StyledLabel
    key={index}
    onClick={() => {
      if (!sortable) {
        const nextDirection: 'ASC' | 'DESC' =
          name === topSortKey && topSortDirection === 'ASC' ? 'DESC' : 'ASC';
        setSortHistory([
          [name, nextDirection],
          ...sortHistory.filter(([sortKey]) => sortKey !== name)
        ]);
      }
    }}
    opacity={name === topSortKey ? 1 : 0.7}
    theme={theme}
  >
    {name}
    {name === topSortKey && topSortDirection ? sortArrows[topSortDirection] : null}
  </StyledLabel>
);

type ValueOf<T extends object> = T extends Array<infer V> ? V : T[keyof T];

export const SortableTable = <T extends any[]>({
  data,
  columns,
  style,
  defaultSort = [],
  includeList
}: {
  data: T;
  columns: Array<{
    name: string;
    format: (rowValue: ValueOf<T>, hover?: boolean) => React.ReactNode;
    selector?: (rowValue: ValueOf<T>) => string | number;
    sort?: (data1: ValueOf<T>, data2: ValueOf<T>) => 1 | -1 | 0;
    exclude?: boolean;
    sortable?: boolean;
    columnStyle?: Record<string, string>;
  }>;
  style?: Record<string, string>;
  defaultSort?: Array<[string, 'ASC' | 'DESC']>;
  includeList?: string[];
}) => {
  const [, theme] = useStyletron();
  const sortArrows: Record<string, JSX.Element> = {
    DESC: DownArrowIcon(theme.colors.primary400),
    ASC: UpArrowIcon(theme.colors.primary400)
  };
  const [showHover, setShowHover] = useState(null);
  const [sortHistory, setSortHistory] = useState(defaultSort); // if there is default sort start with that
  const [[topSortKey, topSortDirection]] = sortHistory.length ? sortHistory : [[null, null]];
  const sortedData = useMemo(() => {
    const toSort = [...data] as Array<ValueOf<T>>;
    // Apply historical sorts in order to mimick local state stable sort
    return sortHistory.reduceRight((acc, [sortKey, direction]) => {
      const sortColumn = columns.find(({ name }) => name === sortKey)!;
      if (sortColumn && sortColumn.sort) {
        return acc.sort((a, b) => sortColumn.sort!(a, b) * (direction === 'ASC' ? 1 : -1));
      }

      // By default handle string and number sorting
      return acc.sort((a, b) => {
        const aVal = 'selector' in sortColumn ? sortColumn.selector!(a) : sortColumn.format(a);
        const bVal = 'selector' in sortColumn ? sortColumn.selector!(b) : sortColumn.format(b);
        const sortValue =
          typeof aVal === 'string' && typeof bVal === 'string'
            ? aVal.localeCompare(bVal)
            : (aVal as number) - (bVal as number);
        return sortValue * (direction === 'ASC' ? 1 : -1);
      });
    }, toSort);
  }, [data, sortHistory, columns]);
  const columnsToShow = columns.filter((column) => !column.exclude);
  const columnCount = columnsToShow.length;
  const height = sortedData.length ? '100%' : '0px';

  return (
    <div style={style ? style : { backgroundColor: 'white', height, width: '100%' }}>
      <Table $gridTemplateColumns={`repeat(${columnCount}, minmax(max-content, 1fr))`}>
        {columnsToShow.map(({ name, sortable }, index) => (
          <HeaderCell key={index}>
            <SortableHeadCell
              key={index}
              title={name}
              direction={name === topSortKey ? topSortDirection : null}
              overrides={{
                HeadCell: {
                  style: {
                    borderWidth: 0,
                    borderBottomStyle: 'none',
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                    paddingTop: 0
                  }
                },
                SortableLabel: {
                  component: LabelComponent,
                  props: {
                    index,
                    sortable,
                    name,
                    topSortKey,
                    topSortDirection,
                    setSortHistory,
                    sortHistory,
                    theme,
                    sortArrows
                  }
                }
              }}
            />
          </HeaderCell>
        ))}
        {sortedData.flatMap((row, index) =>
          columnsToShow.map(({ format, columnStyle }, columnIndex) => (
            <TableCell
              onMouseEnter={() => {
                setShowHover(index as any);
              }}
              onMouseLeave={() => {
                setShowHover(null);
              }}
              key={`${index}-${columnIndex}`}
              columnindex={columnIndex}
              hover={showHover === index ? theme.colors.mono100 : 'white'}
            >
              <Cell
                opacity={!includeList || includeList.includes(row.offerId) ? 1 : 0.5}
                colstyle={columnStyle}
              >
                {showHover === index ? format(row, true) : format(row)}
              </Cell>
            </TableCell>
          ))
        )}
      </Table>
    </div>
  );
};
