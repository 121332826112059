import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchAllQuotesFilterOptions,
  FetchAllQuotesFilterOptionsResult
} from '../../modules/rate-quote';
import {
  getAllQuotesFilterOptionsBegin,
  getAllQuotesFilterOptionsFailed,
  getAllQuotesFilterOptionsSucceeded
} from './slice';

export function* handleFetchAllQuotesFilterOptions({
  payload: { rateQuoteRequestId }
}: ReturnType<typeof getAllQuotesFilterOptionsBegin>) {
  try {
    const result: FetchAllQuotesFilterOptionsResult = yield call(fetchAllQuotesFilterOptions, {
      rateQuoteRequestId
    });
    if (result.success) {
      yield put(getAllQuotesFilterOptionsSucceeded(result.json));
    } else {
      yield put(getAllQuotesFilterOptionsFailed({ error: result.error }));
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(getAllQuotesFilterOptionsFailed({ error }));
  }
}

export const allQuotesFilterOptionsSaga = function* () {
  yield all([takeEvery(getAllQuotesFilterOptionsBegin, handleFetchAllQuotesFilterOptions)]);
};
