import { Icon } from 'baseui/icon';
import React from 'react';
import { Alert } from './alert';
import { Arrow } from './arrow';
import { Copy } from './copy';
import { DragDrop } from './drag-drop';
import { ExternalLink } from './externalLink';
import { FullStar } from './full-star';
import { Head } from './icon_borrowerscenario';
import { Document } from './icon_dealsummary';
import { Error } from './icon_error';
import { House } from './icon_propertyassumptions';
import { Recalculate } from './icon_recalculate';
import { Lock } from './lock';
import { Pencil } from './pencil';
import { Star } from './star';
import { TrashCan } from './trashcan';
import { Unlock } from './unlock';

interface IconProps {
  size?: number;
  stroke?: string;
}

export const DocumentIcon = ({ size }: IconProps) => (
  <Icon
    title="Document"
    size={size}
    overrides={{
      Svg: {
        component: Document
      }
    }}
  />
);

export const FullStarIcon = ({ size }: IconProps) => (
  <Icon
    title="FullStar"
    size={size}
    overrides={{
      Svg: {
        component: FullStar
      }
    }}
  />
);

export const HeadIcon = ({ size }: IconProps) => (
  <Icon
    title="Borrower"
    size={size}
    overrides={{
      Svg: {
        component: Head
      }
    }}
  />
);

export const HouseIcon = ({ size }: IconProps) => (
  <Icon
    title="House"
    size={size}
    overrides={{
      Svg: {
        component: House
      }
    }}
  />
);

export const PencilIcon = ({ size }: IconProps) => (
  <Icon
    title="Pencil"
    size={size}
    overrides={{
      Svg: {
        component: Pencil
      }
    }}
  />
);

export const RecalculateIcon = ({ size }: IconProps) => (
  <Icon
    title="Recalculate"
    size={size}
    overrides={{
      Svg: {
        component: Recalculate
      }
    }}
  ></Icon>
);

export const StarIcon = ({ size }: IconProps) => (
  <Icon
    title="Star"
    size={size}
    overrides={{
      Svg: {
        component: Star
      }
    }}
  />
);

export const TrashIcon = ({ size }: IconProps) => (
  <Icon
    title="Trash"
    size={size}
    overrides={{
      Svg: {
        component: TrashCan
      }
    }}
  />
);

export const ErrorIcon = ({ size }: IconProps) => (
  <Icon
    title="ErrorMessage"
    size={size}
    overrides={{
      Svg: {
        component: Error
      }
    }}
  />
);

export const DragDropIcon = ({ size }: IconProps) => (
  <Icon
    title="DragDrop"
    size={size}
    overrides={{
      Svg: {
        component: DragDrop
      }
    }}
  />
);

export const ArrowIcon = ({ size }: IconProps) => (
  <Icon
    title="Arrow"
    size={size}
    overrides={{
      Svg: {
        component: Arrow
      }
    }}
  />
);

export const LockIcon = ({ size }: IconProps) => (
  <Icon
    title="Lock"
    size={size}
    overrides={{
      Svg: {
        component: Lock
      }
    }}
  />
);

export const UnlockIcon = ({ size }: IconProps) => (
  <Icon
    title="Unlock"
    size={size}
    overrides={{
      Svg: {
        component: Unlock
      }
    }}
  />
);

export const AlertIcon = ({ size }: IconProps) => (
  <Icon
    title="Alert"
    size={size}
    overrides={{
      Svg: {
        component: Alert
      }
    }}
  />
);

export const ExternalLinkIcon = ({ size, stroke = '#005450' }: IconProps) => (
  <Icon
    title="ExternalLink"
    size={size}
    color={stroke}
    overrides={{
      Svg: {
        component: ExternalLink
      }
    }}
  />
);

export const CopyIcon = ({ size }: IconProps) => (
  <Icon
    title="Copy"
    size={size}
    overrides={{
      Svg: {
        component: Copy
      }
    }}
  />
);
