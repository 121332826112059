import { currency } from '@rategravity/frontend/modules/numbers';
import { AccordionField } from '../../components/accordion';
import { toExclude } from '../../hooks/use-conditional-columns';
import { ExistingLoan } from '../../modules/rate-quote/types';
import { ProductSet } from '../../modules/rate-quote/types';

interface LoanAmounts {
  heloc: number;
  secondary: number;
  other: number;
}

/**
 * converts an object of the existing loans to a string
 * @param loans
 */
const loansToString = (loans: LoanAmounts) => {
  const loanType: Record<string, string> = {
    heloc: 'HELOC',
    secondary: '2nd mortgage',
    other: 'Other mortgage'
  };
  return (Object.keys(loans) as Array<keyof typeof loans>)
    .map((key) => (loans[key] > 0 ? currency(loans[key], 0) + ' ' + loanType[key] : ''))
    .filter((v) => v)
    .join(', ');
};

/**
 * goes through all existing loans and adds up the total heloc, secondary, and open loans that are rolled or open.
 * @param existingLoans
 */
export const formatExistingLoans = (
  existingLoans: ExistingLoan[],
  helocLine: number
): { rolled: string; open: string } => {
  if (!existingLoans) {
    return { rolled: '', open: loansToString({ heloc: helocLine, secondary: 0, other: 0 }) };
  }
  const loans = existingLoans
    .filter((loan) => loan.existingLoanType !== 'Primary')
    .reduce(
      (acc, loan) => {
        switch (loan.existingLoanType) {
          case 'HELOC':
            acc.rolled.heloc += loan.rolledIntoRefi ? loan.drawnAmount : 0;
            acc.open.heloc += loan.closed ? 0 : loan.lineAmount;
            break;
          case 'Secondary':
            loan.rolledIntoRefi
              ? (acc.rolled.secondary += loan.amount)
              : (acc.open.secondary += loan.amount);
            break;
          case 'Other':
            loan.rolledIntoRefi
              ? (acc.rolled.other += loan.amount)
              : (acc.open.other += loan.amount);
            break;
        }
        return acc;
      },
      {
        rolled: { heloc: 0, secondary: 0, other: 0 },
        open: { heloc: 0, secondary: 0, other: 0 }
      }
    );
  loans.open.heloc += helocLine;
  return {
    open: loansToString(loans.open),
    rolled: loansToString(loans.rolled)
  };
};

function formatWord(enumValue: string): string {
  let result = '';
  for (const c of enumValue) {
    const capitalC = c.toUpperCase();
    // first letter should be capitalized
    if (result === '') {
      result += capitalC;
      continue;
    }
    // capitals split words
    if (capitalC === c) {
      result += ' ';
    }
    result += c;
  }
  return result;
}

/**
 * turns attributes into an array of {displayField: string, displayValue: string}
 * sorted in the correct order
 * @param attributes offer permutation attributes
 */
export const formatAttributes = (attributes: any, productSet: ProductSet[]): AccordionField[] => {
  const toField = (field: string, value: string, exclude: boolean): AccordionField[] =>
    exclude
      ? []
      : [
          {
            displayField: field,
            displayValue: value
          }
        ];
  const fieldsToCheck: any = {
    irrrlEligible: false,
    cashOut: 0,
    downPayment: 0,
    closingCostOption: 'fullCost',
    escrowOptions: 'include',
    interestOptions: 'principalAndInterest',
    helocLine: 0,
    existingLoans: (loans: ExistingLoan[]) =>
      loans.every((loan) => {
        if (loan.existingLoanType !== 'Primary') {
          return !loan.rolledIntoRefi;
        } else {
          return true;
        }
      })
  };
  const excluded = toExclude(fieldsToCheck, productSet);

  const excludeOpenLine = !attributes.existingLoans
    ? true
    : productSet.every(({ existingLoans }: any) =>
        existingLoans.every((loan: ExistingLoan) => {
          if (loan.existingLoanType === 'HELOC') {
            return loan.closed;
          } else {
            return loan.rolledIntoRefi;
          }
        })
      );

  const { rolled, open } = formatExistingLoans(attributes.existingLoans, attributes.helocLine);

  return [
    ...toField(
      'IRRRL',
      attributes.irrrlEligible ? 'Yes' : 'No',
      attributes.loanType !== 'VA' || excluded.irrrlEligible
    ),
    ...toField('ROLLED IN', rolled || 'None', excluded.existingLoans),
    ...toField('OPEN LINE', open || 'None', excludeOpenLine && excluded.helocLine),
    ...toField('CASH OUT', currency(attributes.cashOut, 0), excluded.cashOut),
    ...toField('DOWN', currency(attributes.downPayment, 0), excluded.downPayment),
    ...toField('CLOSING', formatWord(attributes.closingCostOption), excluded.closingCostOption),
    ...toField('ESCROW', formatWord(attributes.escrowOptions) + 'd', excluded.escrowOptions),
    ...toField('PAYMENT', formatWord(attributes.interestOptions), excluded.interestOptions)
  ];
};
