import { styled, withStyle } from 'baseui';
import { ModalBody } from 'baseui/modal';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import {
  EditableFees,
  EditableOffer,
  Errorable,
  OfferDetailsState
} from '../../../redux/offer-details/state';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import { Modal } from '../../modal';
import { Notice } from '../../notice';
import { FeeCard } from '../card/fee-card';
import { MonthlyPaymentCard } from '../card/monthly-payment-card';
import {
  NotesAdvisoriesAdjustmentsCard,
  NotesAdvisoriesAdjustmentsCardProps
} from '../card/notes-advisories-adjustments';
import { OfferDetailsRateInfo, RateInfoCard } from '../card/rate-info-card';
import { AttributesPanel, FullAttributes } from './attributes-panel';
import { CopyPMINotice } from './copy-pmi-notice';

const Flex = withStyle(ModalBody, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '10px',
  marginRight: '10px'
});

const FeesContainer = styled('div', {
  width: '100%',
  overflow: 'hidden'
});

export interface DetailsModalProps
  extends Omit<OfferDetailsState, 'modalOffer' | 'productDetailsStatus'> {
  lenderFee: Errorable<EditableFees>;
  loanServices: Errorable<EditableFees>;
  title: Errorable<EditableFees>;
  government: Errorable<EditableFees>;
  prepaids: Errorable<EditableFees>;
  validationError: boolean;
  fullAttributes: FullAttributes;
  updateFee: (payload: { id: string; value: number | null | undefined }) => void;
  addOffer: () => void;
  productDetails: OfferDetailsRateInfo;
  updateRateInfo: (payload: Partial<EditableOffer['productDetails']>) => void;
  monthlyPaymentData: {
    principalAndInterest: number;
    monthlyInsurance: number;
    monthlyTaxes: number;
    mi?: { label: string; readonly: boolean };
  };
  updatePMI: (pmi?: number) => void;
  onClose: () => void;
  canEdit: boolean;
  sellerConcessions?: number | null;
  updatePriceField: (payload: { price: number | undefined }) => void;
  setCopyPMIValue: () => void;
  copyPMIValue: boolean;
  numManualPMIOffers: number;
  notesAndAdvisories: NotesAdvisoriesAdjustmentsCardProps;
}

export const DetailsModal = ({
  lenderFee,
  loanServices,
  title,
  government,
  prepaids,
  fullAttributes,
  validationError,
  networkError,
  isOpen,
  loading,
  updateFee,
  addOffer,
  productDetails,
  updateRateInfo,
  monthlyPaymentData,
  updatePMI,
  onClose,
  canEdit,
  sellerConcessions,
  updatePriceField,
  setCopyPMIValue,
  copyPMIValue,
  numManualPMIOffers,
  notesAndAdvisories
}: DetailsModalProps) => {
  const [modalRef, setModalRef] = useState(null);
  const [scrollToTop, setScroll] = useState(true);
  const setModalRefCallback = useCallback((ref) => {
    setModalRef(ref);
  }, []);

  useLayoutEffect(() => {
    // Scroll modal to top if errors were found or modal is opened
    if (isOpen && modalRef) {
      let parentModal: any = modalRef;
      while (!parentModal.dataset.baseweb) {
        parentModal = parentModal.parentNode;
      }
      parentModal.scrollTop = 0;
      setScroll(false);
    }
  }, [isOpen, validationError, modalRef, scrollToTop]);

  return (
    <Modal
      isOpen={isOpen}
      title="Offer details"
      loading={loading}
      onClose={() => onClose()}
      buttons={[
        <SecondaryButton key={1} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>,
        <PrimaryButton
          key={2}
          disabled={!canEdit}
          onClick={() => {
            // TODO: typing from mergeProps is wrong
            if (!(addOffer() as any)) {
              setScroll(true);
            }
          }}
        >
          Done
        </PrimaryButton>
      ]}
      layout={'Regular'}
    >
      <div ref={setModalRefCallback} />
      <Flex>
        <AttributesPanel fullAttributes={fullAttributes} />
        <FeesContainer>
          {validationError && <Notice message={'Please complete the highlighted fields below.'} />}
          {networkError !== null && (
            <Notice message={'Network Error. Please try refreshing the page'} />
          )}
          <RateInfoCard
            isFixed={fullAttributes.product.rate === 'fixed'}
            data={productDetails}
            onChange={updateRateInfo}
            updatePriceField={updatePriceField}
          />
          <MonthlyPaymentCard
            data={monthlyPaymentData}
            onChange={updatePMI}
            setCopyPMI={setCopyPMIValue}
          />
          <FeeCard
            title="Lender fees"
            fees={lenderFee}
            updateFee={updateFee}
            sellerConcessions={sellerConcessions}
          />
          <FeeCard title="Loan Services" fees={loanServices} updateFee={updateFee} />
          <FeeCard title="Title" fees={title} updateFee={updateFee} />
          <FeeCard title="Taxes & Government Fees" fees={government} updateFee={updateFee} />
          <FeeCard title="Prepaids & Escrows" fees={prepaids} updateFee={updateFee} />
          <NotesAdvisoriesAdjustmentsCard {...notesAndAdvisories} />
          <CopyPMINotice numOfOffers={numManualPMIOffers} copyPMIValue={copyPMIValue} />
        </FeesContainer>
      </Flex>
    </Modal>
  );
};
