import React from 'react';

export const Arrow = () => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Arrow</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Publish-modal-Copy-2"
        transform="translate(-783.000000, -1099.000000)"
        stroke="#005450"
        strokeWidth="1.5"
      >
        <g id="Group-31" transform="translate(303.000000, 891.000000)">
          <g id="Group-6">
            <g id="Group" transform="translate(0.000000, 7.000000)">
              <g id="Group-4" transform="translate(325.000000, 184.000000)">
                <g
                  id="Arrow"
                  transform="translate(162.368023, 24.160361) rotate(-45.000000) translate(-162.368023, -24.160361) translate(152.868023, 18.160361)"
                >
                  <path d="M0.5,5.995 L17.2501244,5.995" id="Line-4" strokeLinecap="square"></path>
                  <polyline
                    id="Path"
                    transform="translate(12.585786, 6.000000) rotate(-135.000000) translate(-12.585786, -6.000000) "
                    points="16.5857864 10 8.58578644 10 8.58578644 2 8.58578644 2"
                  ></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
