import { composeReducers, ofType, withDefault } from 'redux-compose';
import { arrayMove } from '../../../modules/array-move';
import { RateQuote } from '../../../modules/rate-quote/types';
import {
  PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  PublishRateQuoteSuccessAction
} from '../../link-modal/actions';
import {
  CREATE_OFFER_SUCCESS_ACTION_TYPE,
  CreateOfferSuccessAction,
  FETCH_LOCKED_OFFER_BY_SOURCE_ACTION_TYPE,
  FETCH_LOCKED_OFFER_BY_SOURCE_FAILURE_ACTION_TYPE,
  FETCH_LOCKED_OFFER_BY_SOURCE_SUCCESS_ACTION_TYPE,
  FETCH_RATE_QUOTE_ACTION_TYPE,
  FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE,
  FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  FetchLockedOfferBySourceFailureAction,
  FetchLockedOfferBySourceSuccessAction,
  FetchRateQuoteFailureAction,
  FetchRateQuoteRequestAction,
  FetchRateQuoteSuccessAction,
  MERCHANDISE_OFFER_ACTION_TYPE,
  MerchandiseOfferAction,
  REORDER_OFFERS_ACTION_TYPE,
  ReorderOffersAction,
  SAVE_MERCHANDISING_ACTION_TYPE,
  SAVE_MERCHANDISING_SUCCESS_ACTION_TYPE,
  SaveMerchandisingAction,
  SaveMerchandisingSuccessAction,
  SET_LOCKED_OFFER_ACTION_TYPE,
  SetLockedOfferAction,
  STORE_OFFER_ACTION_TYPE,
  StoreOfferAction,
  UNMERCHANDISE_OFFER_ACTION_TYPE,
  UnmerchandiseOfferAction
} from '../actions';
import { defaultRateQuoteState, RateQuoteState } from '../state';

const handleFetchRateQuoteRequestAction = (
  state: RateQuoteState,
  _: FetchRateQuoteRequestAction
): RateQuoteState => ({ ...state, loading: true, error: null });

const handleFetchRateQuoteSuccessAction = (
  state: RateQuoteState,
  { payload }: FetchRateQuoteSuccessAction
): RateQuoteState => ({ ...state, loading: false, rateQuote: payload, error: null });

const handleFetchRateQuoteFailureAction = (
  state: RateQuoteState,
  { payload: { message } }: FetchRateQuoteFailureAction
): RateQuoteState => ({
  ...state,
  loading: false,
  error: `Error fetching rateQuote: ${message}`
});

const handleCreateOfferSuccessAction = (
  state: RateQuoteState,
  { payload: { offer, offerPermutations } }: CreateOfferSuccessAction
): RateQuoteState => ({
  ...state,
  rateQuote: {
    ...state.rateQuote,
    offers: {
      ...(state.rateQuote || {}).offers,
      [offer.offerId]: offer
    },
    offerPermutations
  } as RateQuote
});

const handleMerchandiseOfferAction = (
  state: RateQuoteState,
  { payload }: MerchandiseOfferAction
): RateQuoteState => {
  // only update state if there would be changes.
  if (state.rateQuote && state.rateQuote.merchandising.every((id) => id !== payload)) {
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        merchandising: [...state.rateQuote.merchandising, payload]
      } as RateQuote
    };
  }
  return state;
};

export const handleStoreOfferAction = (
  state: RateQuoteState,
  { payload: { offerId, offerData } }: StoreOfferAction
): RateQuoteState => {
  // Get rate quote.
  const rateQuote = state.rateQuote;
  if (rateQuote) {
    const id = offerData.offerId || offerId;
    if (id) {
      // Get offer we're updating
      const updatedOffer = rateQuote.offers[id];
      if (updatedOffer) {
        return {
          ...state,
          rateQuote: {
            ...state.rateQuote,
            offers: {
              ...rateQuote.offers,
              // Set the new offer state.
              [id]: { ...updatedOffer, ...offerData }
            }
          }
        } as RateQuoteState;
      }
    }
  }
  return state;
};

export const handleSetLockedOfferAction = (
  state: RateQuoteState,
  { payload: { offerId, locked } }: SetLockedOfferAction
): RateQuoteState => {
  if (state.rateQuote) {
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        lockedOfferId: locked ? offerId : null,
        offers: {
          ...state.rateQuote.offers,
          [offerId]: {
            ...state.rateQuote.offers[offerId],
            locked
          }
        }
      }
    } as RateQuoteState;
  }
  return state;
};

const handleReorderOffersAction = (
  state: RateQuoteState,
  { payload: { oldIndex, newIndex } }: ReorderOffersAction
): RateQuoteState => {
  // Only proceed if the index has changed and the old index is valid
  if (
    oldIndex !== newIndex &&
    state.rateQuote &&
    oldIndex >= 0 &&
    oldIndex < state.rateQuote.merchandising.length
  ) {
    // Shift the merchandising around.
    const merchandising = arrayMove([...state.rateQuote.merchandising], oldIndex, newIndex);
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        merchandising
      }
    };
  }
  return state;
};

const handleUnmerchandiseOfferAction = (
  state: RateQuoteState,
  { payload: offerId }: UnmerchandiseOfferAction
): RateQuoteState => {
  if (state.rateQuote) {
    const newMerchandising = state.rateQuote.merchandising.filter((id) => id !== offerId);
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        merchandising: newMerchandising
      }
    };
  }
  return state;
};

const handleSaveMerchandisingAction = (
  state: RateQuoteState,
  { payload }: SaveMerchandisingAction
): RateQuoteState => ({
  ...state,
  saveQueued: !payload
});

const handleSaveMerchandisingSuccessAction = (
  state: RateQuoteState,
  { payload }: SaveMerchandisingSuccessAction
): RateQuoteState => {
  if (state.rateQuote) {
    // Just synchronize the modified dates.
    //   The returned rate quote is not fully-formed.
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        lastModifiedTime: payload.lastModifiedTime
      }
    };
  }
  return state;
};

const handlePublishRateQuoteSuccessAction = (
  state: RateQuoteState,
  { payload }: PublishRateQuoteSuccessAction
): RateQuoteState => {
  if (state.rateQuote) {
    return {
      ...state,
      rateQuote: {
        ...state.rateQuote,
        status: payload.rateQuote.status
      }
    };
  }
  return state;
};

const handleFetchLockedOfferBySource = (state: RateQuoteState): RateQuoteState => {
  return {
    ...state,
    lockedRateQuoteBySource: { status: 'pending' }
  };
};

const handleFetchLockedOfferBySourceCompletion = (
  state: RateQuoteState,
  { payload }: FetchLockedOfferBySourceSuccessAction | FetchLockedOfferBySourceFailureAction
): RateQuoteState => ({
  ...state,
  lockedRateQuoteBySource: payload
});

export const rateQuoteReducer = composeReducers(
  withDefault(defaultRateQuoteState),
  ofType(FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE, handleFetchRateQuoteSuccessAction),
  ofType(FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE, handleFetchRateQuoteFailureAction),
  ofType(FETCH_RATE_QUOTE_ACTION_TYPE, handleFetchRateQuoteRequestAction),
  ofType(CREATE_OFFER_SUCCESS_ACTION_TYPE, handleCreateOfferSuccessAction),
  ofType(STORE_OFFER_ACTION_TYPE, handleStoreOfferAction),
  ofType(SET_LOCKED_OFFER_ACTION_TYPE, handleSetLockedOfferAction),
  ofType(REORDER_OFFERS_ACTION_TYPE, handleReorderOffersAction),
  ofType(MERCHANDISE_OFFER_ACTION_TYPE, handleMerchandiseOfferAction),
  ofType(UNMERCHANDISE_OFFER_ACTION_TYPE, handleUnmerchandiseOfferAction),
  ofType(SAVE_MERCHANDISING_ACTION_TYPE, handleSaveMerchandisingAction),
  ofType(SAVE_MERCHANDISING_SUCCESS_ACTION_TYPE, handleSaveMerchandisingSuccessAction),
  ofType(PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE, handlePublishRateQuoteSuccessAction),
  ofType(FETCH_LOCKED_OFFER_BY_SOURCE_ACTION_TYPE, handleFetchLockedOfferBySource),
  ofType(
    FETCH_LOCKED_OFFER_BY_SOURCE_SUCCESS_ACTION_TYPE,
    handleFetchLockedOfferBySourceCompletion
  ),
  ofType(FETCH_LOCKED_OFFER_BY_SOURCE_FAILURE_ACTION_TYPE, handleFetchLockedOfferBySourceCompletion)
);
