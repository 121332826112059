import { of } from '@rategravity/core-lib/optional';
import { createSelector, createStructuredSelector } from 'reselect';
import { ApplicationState } from '../../redux';
import { attorneyRequiredSelector } from './attorney-required-selector';
import { loanIdErrorSelector, loanIdSelector } from './loan-id-selector';

export const lockDataSelector = createSelector(
  ({ lockModal: { modalLock } }: ApplicationState) => modalLock,
  attorneyRequiredSelector,
  createStructuredSelector({
    value: loanIdSelector,
    error: loanIdErrorSelector
  }),
  (modalLock, attorneyRequired, loanId) =>
    of(modalLock)
      .map(({ rgTitle, attorney, provider, notes, lockId, float }) => ({
        loanId,
        attorney: {
          value: attorney,
          error: attorneyRequired ? !attorney : false
        },
        rgTitle: {
          value: rgTitle,
          error: rgTitle === undefined
        },
        provider,
        notes,
        lockId,
        float
      }))
      .orElse(() => ({
        loanId: { value: '', error: true },
        attorney: { value: undefined, error: true },
        rgTitle: { value: undefined, error: true },
        provider: undefined,
        notes: '',
        offerId: '',
        lockId: '',
        float: false
      }))
);
