import { composeReducers, ofType, withDefault } from 'redux-compose';
import { v4 as uuid } from 'uuid';
import { FeeValue } from '../../modules/default-fees/types';
import { Offer } from '../../modules/rate-quote/types';
import {
  CREATE_OFFER_ACTION_TYPE,
  CREATE_OFFER_FAILURE_ACTION_TYPE,
  CREATE_OFFER_SUCCESS_ACTION_TYPE,
  CreateOfferFailureAction,
  UPDATE_OFFER_ACTION_TYPE,
  UPDATE_OFFER_FAILURE_ACTION_TYPE,
  UPDATE_OFFER_SUCCESS_ACTION_TYPE,
  UpdateOfferFailureAction
} from '../rate-quote/actions';
import {
  CLOSE_OFFER_DETAILS_ACTION_TYPE,
  CloseOfferDetailsAction,
  GET_PRODUCT_DETAILS_ACTION_TYPE,
  GET_PRODUCT_DETAILS_FAILURE_ACTION_TYPE,
  GET_PRODUCT_DETAILS_SUCCESS_ACTION_TYPE,
  GetProductDetailsSuccessAction,
  RESTORE_MODAL_STATE_CACHE_ACTION_TYPE,
  RestoreModalStateAction,
  SEED_INITIAL_OFFER_ACTION_TYPE,
  SEED_OFFER_ACTION_TYPE,
  SeedInitialOfferAction,
  SeedOfferAction,
  UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE,
  UPDATE_OFFER_DETAILS_PMI_ACTION_TYPE,
  UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE,
  UPDATE_PRICE_ACTION_TYPE,
  UpdateOfferDetailsFeeAction,
  UpdateOfferDetailsPMIAction,
  UpdateOfferDetailsRateInfoAction,
  UpdatePriceAction,
  VALIDATE_OFFER_DETAILS_FORM_ACTION_TYPE
} from './actions';
import { defaultOfferDetailsState, EditableOffer, OfferDetailsState } from './state';

const handleSeedOfferAction = (
  state: OfferDetailsState,
  { payload: { offer, allQuotes } }: SeedOfferAction
): OfferDetailsState => ({
  ...state,
  modalOffer: {
    ...(offer as any),
    attributes: {
      ...offer.attributes,
      loanSize: offer.attributes.loanSize - offer.attributes.amtToFinance
    }
  },
  ...(allQuotes ? { isOpenAllQuotes: true } : { isOpen: true })
});

const priceCalculation = (
  value: FeeValue | undefined,
  id: 'lenderCredit' | 'points',
  loanSize: number
) => {
  if (typeof value !== 'number') {
    return 100;
  }
  return id === 'lenderCredit' ? (value / loanSize) * 100 + 100 : (-value / loanSize) * 100 + 100;
};

const handleUpdateDetailsFeeAction = (
  { modalOffer, ...rest }: OfferDetailsState,
  { payload: { id, value } }: UpdateOfferDetailsFeeAction
): OfferDetailsState => {
  let price = modalOffer!.attributes.price;
  if (id === 'lenderCredit' || id === 'points') {
    // calculate price, lc, and points based off of changed fee
    const defaultValue = modalOffer!.fees.find(({ id: feeId }) => feeId === id)?.computedValue;
    price =
      value == null
        ? priceCalculation(defaultValue, id, modalOffer!.attributes.loanSize)
        : priceCalculation(value, id, modalOffer!.attributes.loanSize);
  }
  return {
    ...rest,
    modalOffer: {
      ...modalOffer!,
      attributes: {
        ...modalOffer!.attributes,
        price
      },
      fees: modalOffer!.fees.map((fee) => (fee.id === id ? { ...fee, manualValue: value } : fee))
    },
    hasChanged: true
  };
};

const lcPointsCalculation = (price: number, loanSize: number) =>
  Math.ceil(((price - 100) / 100) * loanSize);

const handleUpdateDetailsRateInfoAction = (
  { modalOffer, ...rest }: OfferDetailsState,
  { payload }: UpdateOfferDetailsRateInfoAction
): OfferDetailsState => ({
  ...rest,
  modalOffer: {
    ...modalOffer!,
    productDetails: { ...modalOffer!.productDetails, ...payload } as Offer['productDetails']
  },
  hasChanged: true
});

const handleUpdatePriceAction = (
  { modalOffer, ...rest }: OfferDetailsState,
  { payload }: UpdatePriceAction
): OfferDetailsState => {
  let fees = modalOffer!.fees;
  if (!!payload.price) {
    const lcPoints = lcPointsCalculation(payload.price, modalOffer!.attributes.loanSize);
    fees = fees.map((fee) => {
      switch (fee.id) {
        case 'lenderCredit':
          return { ...fee, manualValue: lcPoints > 0 ? lcPoints : 0 };
        case 'points':
          return { ...fee, manualValue: lcPoints > 0 ? 0 : Math.abs(lcPoints) };
        default:
          return fee;
      }
    });
  }
  return {
    ...rest,
    modalOffer: {
      ...modalOffer!,
      attributes: {
        ...modalOffer!.attributes,
        price: payload.price
      },
      fees
    },
    hasChanged: true
  };
};

const handleUpdateDetailsMonthlyPaymentAction = (
  { modalOffer, ...rest }: OfferDetailsState,
  { payload }: UpdateOfferDetailsPMIAction
): OfferDetailsState => ({
  ...rest,
  modalOffer: {
    ...modalOffer!,
    monthlyPayment: {
      ...modalOffer!.monthlyPayment,
      mi: {
        ...modalOffer!.monthlyPayment.mi!,
        value: undefined
      },
      pmi: payload
    }
  },
  hasChanged: true
});

const handleCreateOfferAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  validateForm: false,
  networkError: null,
  loading: true
});

const handleCreateOfferFailureAction = (
  state: OfferDetailsState,
  { payload }: CreateOfferFailureAction
): OfferDetailsState => ({
  ...state,
  loading: false,
  networkError: payload,
  validateForm: true
});

const handleCreateOfferSuccessAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  modalOffer: null,
  loading: false,
  isOpen: false
});

const handleUpdateOfferAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  validateForm: false,
  networkError: null,
  loading: true
});

const handleUpdateOfferFailureAction = (
  state: OfferDetailsState,
  { payload }: UpdateOfferFailureAction
): OfferDetailsState => ({
  ...state,
  loading: false,
  networkError: payload,
  validateForm: true
});

const handleUpdateOfferSuccessAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  modalOffer: null,
  loading: false,
  isOpen: false
});

const handleValidateLenderFeesFormAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  validateForm: true
});

const handleInitialOfferSeed = (
  state: OfferDetailsState,
  {
    payload: {
      lenderId,
      pmi,
      productSet: {
        loanType,
        helocLine,
        closingCostOption,
        closingToCover,
        interestOptions,
        escrowOptions,
        product: { dti, ...restProduct },
        ltv,
        conforming,
        lenders: {
          [lenderId]: {
            fees,
            id,
            name,
            url,
            nmls,
            rateLockDays,
            armCaps,
            indexRate,
            mi,
            amtToFinance,
            upFrontInsuranceFee
          }
        },
        ...rest
      },
      sellerConcessions
    }
  }: SeedInitialOfferAction
): OfferDetailsState => ({
  ...state,
  modalOffer: {
    starred: true,
    offerId: uuid(),
    type: 'downPayment' in rest ? 'purchase' : 'refinance',
    attributes: {
      lender: lenderId,
      fullLender: {
        id,
        name,
        url,
        nmls
      },
      product: restProduct,
      loanType,
      helocLine,
      closingCostOption,
      closingToCover,
      interestOptions,
      escrowOptions,
      amtToFinance,
      ltv,
      price: 100,
      conforming,
      ...('downPayment' in rest && { downPayment: rest.downPayment }),
      ...('cashOut' in rest && { cashOut: rest.cashOut }),
      ...('existingLoans' in rest && { existingLoans: rest.existingLoans }),
      ...('totalCashOut' in rest && { totalCashOut: rest.totalCashOut }),
      ...('loanAmount' in rest && { loanSize: rest.loanAmount }),
      ...('irrrlEligible' in rest && { irrrlEligible: rest.irrrlEligible }),
      ...(upFrontInsuranceFee !== undefined && { upFrontInsuranceFee })
    } as EditableOffer['attributes'],
    monthlyPayment: {
      mi,
      ...(pmi ? { pmi } : {})
    },
    productDetails: {
      label: '',
      rate: null,
      manualRateLockDays: null,
      ...('adjustmentPeriod' in restProduct && {
        armCaps: null,
        margin: null,
        indexType: null
      })
    },
    lender: {
      id,
      name,
      url,
      nmls
    },
    fees: fees
      .filter(({ value }) => value !== 'NotIncluded')
      .map(({ value, ...r }) => ({
        ...r,
        computedValue: value,
        manualValue: null
      })),
    computedProductDetails: {
      rateLockDays,
      armCaps,
      indexRate,
      prepaidInterest: 0,
      apr: 0,
      dti: 0
    },
    manualFees: [],
    lastModifiedTime: Date.now(),
    method: state.modalOffer?.method ? state.modalOffer?.method : 'Manual',
    expired: false,
    locked: false,
    sellerConcessions,
    adjustments: [],
    notesAndAdvisories: []
  },
  hasChanged: false,
  loading: false,
  isOpen: true
});

const handleCloseOfferDetailsAction = (
  state: OfferDetailsState,
  _: CloseOfferDetailsAction
): OfferDetailsState => ({
  ...state,
  modalOffer: null,
  isOpen: false,
  isOpenAllQuotes: false,
  networkError: null,
  validateForm: false
});

const handleRestoreModalStateCacheAction = (
  state: OfferDetailsState,
  { payload: { offer, allQuotes } }: RestoreModalStateAction
): OfferDetailsState => ({
  ...state,
  ...(allQuotes ? { isOpenAllQuotes: true } : { isOpen: true }),
  modalOffer: offer
});

const handleGetProductDetailsAction = (state: OfferDetailsState): OfferDetailsState => ({
  ...state,
  productDetailsStatus: 'loading'
});

const handleGetProductDetailsSuccessAction = (
  state: OfferDetailsState,
  { payload }: GetProductDetailsSuccessAction
): OfferDetailsState => {
  if (state.modalOffer) {
    return {
      ...state,
      modalOffer: {
        ...state.modalOffer,
        ...payload
      },
      productDetailsStatus: 'loaded'
    };
  }
  return { ...state, isOpenAllQuotes: true };
};

const handleGetProductDetailsFailureAction = (state: OfferDetailsState): OfferDetailsState => {
  if (state.modalOffer) {
    return {
      ...state,
      modalOffer: {
        ...state.modalOffer,
        notesAndAdvisories: [],
        adjustments: []
      },
      productDetailsStatus: 'error'
    };
  }
  return state;
};

export const offerDetailsReducer = composeReducers(
  withDefault(defaultOfferDetailsState),
  ofType(SEED_OFFER_ACTION_TYPE, handleSeedOfferAction),
  ofType(UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE, handleUpdateDetailsFeeAction),
  ofType(SEED_INITIAL_OFFER_ACTION_TYPE, handleInitialOfferSeed),
  // ofType(FetchDefaultLenderFeesActionType, handleLenderFeesSeedAction),
  ofType(UPDATE_PRICE_ACTION_TYPE, handleUpdatePriceAction),
  ofType(UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE, handleUpdateDetailsRateInfoAction),
  ofType(UPDATE_OFFER_DETAILS_PMI_ACTION_TYPE, handleUpdateDetailsMonthlyPaymentAction),
  ofType(CREATE_OFFER_ACTION_TYPE, handleCreateOfferAction),
  ofType(CREATE_OFFER_SUCCESS_ACTION_TYPE, handleCreateOfferSuccessAction),
  ofType(CREATE_OFFER_FAILURE_ACTION_TYPE, handleCreateOfferFailureAction),
  ofType(UPDATE_OFFER_ACTION_TYPE, handleUpdateOfferAction),
  ofType(UPDATE_OFFER_SUCCESS_ACTION_TYPE, handleUpdateOfferSuccessAction),
  ofType(UPDATE_OFFER_FAILURE_ACTION_TYPE, handleUpdateOfferFailureAction),
  ofType(VALIDATE_OFFER_DETAILS_FORM_ACTION_TYPE, handleValidateLenderFeesFormAction),
  ofType(CLOSE_OFFER_DETAILS_ACTION_TYPE, handleCloseOfferDetailsAction),
  ofType(RESTORE_MODAL_STATE_CACHE_ACTION_TYPE, handleRestoreModalStateCacheAction),
  ofType(GET_PRODUCT_DETAILS_ACTION_TYPE, handleGetProductDetailsAction),
  ofType(GET_PRODUCT_DETAILS_SUCCESS_ACTION_TYPE, handleGetProductDetailsSuccessAction),
  ofType(GET_PRODUCT_DETAILS_FAILURE_ACTION_TYPE, handleGetProductDetailsFailureAction)
);
