import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mergeOfferPermutations } from '../../modules/all-quotes/merge-offer-permutations';
import { FilterOptions } from '../../modules/rate-quote';
import { AllQuotesOffer, OfferPermutation } from '../../modules/rate-quote/types';
import { AllQuotesState } from './state';

const defaultAllQuotesState: AllQuotesState = {
  loading: false,
  error: null,
  rates: {},
  includeOfferLoading: null,
  isSameOfferModalOpen: false,
  offerPermutations: []
};

export const allQuotesSlice = createSlice({
  name: 'allQuotes',
  initialState: defaultAllQuotesState,
  reducers: {
    getAllQuotes: (
      state,
      _: PayloadAction<{
        rateQuoteRequestId: string;
        filterOptions: FilterOptions;
      }>
    ) => ({
      ...state,
      loading: true,
      error: null,
      rates: {}
    }),
    getAllQuotesSuccess: (
      state,
      {
        payload: { offers, offerPermutations: incomingPermutations }
      }: PayloadAction<{ offers: AllQuotesOffer[]; offerPermutations: OfferPermutation[] }>
    ) => ({
      ...state,
      rates: {
        ...state.rates,
        ...offers.reduce(
          (acc, { offerId, ...restOffer }) => ({ ...acc, [offerId]: { offerId, ...restOffer } }),
          {}
        )
      },
      offerPermutations: mergeOfferPermutations(state.offerPermutations, incomingPermutations),
      error: null
    }),
    getAllQuotesError: (state, { payload: { error } }: PayloadAction<{ error: string }>) => ({
      ...state,
      error
    }),
    getAllQuotesComplete: (state) => ({
      ...state,
      loading: false
    }),
    addOfferToRQ: (state, { payload: { offerId } }: PayloadAction<{ offerId: string }>) => ({
      ...state,
      includeOfferLoading: offerId
    }),
    addOfferToRQComplete: (state) => ({
      ...state,
      includeOfferLoading: null
    }),
    toggleSameOfferModal: (state, { payload: { isOpen } }: PayloadAction<{ isOpen: boolean }>) => ({
      ...state,
      isSameOfferModalOpen: isOpen
    })
  }
});

export const {
  getAllQuotes,
  getAllQuotesSuccess,
  getAllQuotesError,
  getAllQuotesComplete,
  addOfferToRQ,
  addOfferToRQComplete,
  toggleSameOfferModal
} = allQuotesSlice.actions;

export const reducer = allQuotesSlice.reducer;
