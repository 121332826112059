import fecha from 'fecha';

export const formatDate = (time: number) => {
  const ONE_DAY_MS = 86400000;
  const diffDays = (Date.now() - time) / ONE_DAY_MS;
  const day =
    new Date(time).getHours() - new Date(Date.now()).getHours() > 0
      ? Math.ceil(diffDays)
      : Math.floor(diffDays);
  switch (day) {
    case 0:
      return `Today, ${fecha.format(time, 'h:mm A')}`;
    case 1:
      return `Yesterday, ${fecha.format(time, 'h:mm A')}`;
    default:
      return fecha.format(time, 'mediumDate');
  }
};
