import { styled } from 'baseui';
import * as React from 'react';
import { useEffect } from 'react';
import { Spinner } from '../spinner';

export interface SavePromptParams {
  saveQueued?: boolean;
  size?: number;
}

export interface SavePromptDispatch {
  forceSave: () => void;
}

type SavePromptProperties = SavePromptParams & SavePromptDispatch;

const SaveSpinnerContainer = styled('div', {
  position: 'fixed',
  left: '0',
  bottom: '0',
  margin: '10px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'pointer-events': 'none'
});

/**
 * Injects an event listener to prevent the user from dropping off the page before
 *   an auto-save is triggered. Also displays a little spinner in the corner of the
 *   screen indicating that there's a save pending.
 */
export const SavePrompt = ({ saveQueued, forceSave, size = 16 }: SavePromptProperties) => {
  useEffect(() => {
    // If `forceSave` is defined and a save is queued...
    if (forceSave && saveQueued) {
      // Subscribe a window 'beforeunload' event that will warn the user if a save
      //   has not yet occurred and trigger the save if the user choses not to leave.
      //
      // Note that most browsers do not allow actual functionality in these events, so
      //   giving a warning _then_ triggering the save when they choose to stay is the
      //   best option we have.
      const unloadListener = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue =
          'Your data has not yet been saved. Are you sure you want to leave the page?';
        forceSave();
        return e.returnValue;
      };
      window.addEventListener('beforeunload', unloadListener);

      // Unsubscribe the event listener when the effect is unsubscribed.
      return () => {
        window.removeEventListener('beforeunload', unloadListener);
      };
    }
  }, [forceSave, saveQueued]);

  return (
    <SaveSpinnerContainer>
      {saveQueued ? <Spinner size={size} centerVertically={false} /> : null}
    </SaveSpinnerContainer>
  );
};
