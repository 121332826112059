import { styled } from 'baseui';
import React from 'react';

const StyledLink = styled('a', ({ $theme }) => ({
  color: $theme.colors.primary300
}));

export const Link = ({ children, href }: React.PropsWithChildren<{ href: string }>) => (
  <StyledLink href={href}>{children}</StyledLink>
);
