/** The UTC hour that must have passed for an offer to be marked as stale. */
const STALE_HOUR_UTC = 1; // 1 AM UTC

/**
 * Determines the date in which an offer would go stale based
 *   on the input `lastModified` time.
 *
 * @param lastModified Timestamp the offer was last modified.
 */
export const getStaleDate = (lastModified: Date | number): Date => {
  // Get the current date at the STALE_HOUR_UTC
  const staleDate = new Date(lastModified);

  // Determines if it should be tomorrow.
  if (staleDate.getUTCHours() >= STALE_HOUR_UTC) {
    staleDate.setDate(staleDate.getDate() + 1);
  }
  staleDate.setUTCHours(STALE_HOUR_UTC);
  staleDate.setMinutes(0);
  staleDate.setSeconds(0);
  staleDate.setMilliseconds(0);

  return staleDate;
};

/**
 * Determines if an offer has gone stale by comparing the `now` timestamp
 *   (defaults to the current system time) to the `then` timestamp to
 *   see if the hour it would have gone stale has passed.
 * Or if an offer is Automated and the offer was last updated before the rate quote was last refreshed
 *
 * @param then Timestamp the offer was last modified.
 * @param now current timestamp - leave undefined to get the system
 *              time at function call
 */
export const isStale = (
  method: 'Automated' | 'Manual' | 'AllQuotes',
  then: Date | number,
  rqRefreshed: Date | number,
  now: Date | number = new Date()
): boolean => {
  return getStaleDate(then) <= now || (method === 'Automated' && then < rqRefreshed);
};
