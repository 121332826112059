import { useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, time: number | null) => {
  const savedCallback = useRef(callback);
  savedCallback.current = callback;

  // Set up the timeout
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (time !== null) {
      const id = setTimeout(tick, time);
      return () => clearTimeout(id);
    }
  }, [time]);
};
