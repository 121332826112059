import { currency } from '@rategravity/frontend/modules/numbers';
import { Card } from 'baseui/card';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React from 'react';
import { computeFeeValue } from '../../../modules/rate-quote/fee-value';
import { EditableFees, Errorable } from '../../../redux/offer-details/state';
import { NumberInput } from '../../forms/input';
import { Label4 } from '../../labels';
import { LabelWithSublabel } from '../../labels';
import { cardOverrides, DetailsContainer, flexGridItemOverrides } from './shared';

const creditCardOverrides = {
  Contents: {
    style: {
      marginLeft: '0px',
      marginRight: '0px',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '24px'
    }
  },
  Root: {
    style: {
      boxShadow: 'none',
      borderWidth: '0px',
      paddingBottom: '20px',
      marginBottom: '-40px'
    }
  }
};

const FeeCardBody = ({
  fees,
  updateFee,
  sellerConcessions
}: {
  fees: Errorable<EditableFees>;
  updateFee: (payload: { id: string; value: number | null | undefined }) => void;
  sellerConcessions?: number | null;
}) => {
  const credits = fees.filter(({ calculationAttributes: { credit } }) => credit);
  fees = fees.filter(({ calculationAttributes: { credit } }) => !credit);
  const makeFlexCells = (arr: Errorable<EditableFees>) =>
    arr.map(
      ({ id, computedValue, manualValue, name, error, calculationAttributes: { modifiers } }) => (
        <FlexGridItem key={name} overrides={flexGridItemOverrides}>
          <NumberInput
            value={manualValue}
            defaultValue={typeof computedValue === 'number' ? computedValue : undefined}
            error={error}
            label={modifiers.find((m) => m === 'optional') ? `${name} (optional)` : name}
            inputType="money"
            onChange={({ value, recalculated }) =>
              updateFee({ id, value: recalculated ? null : value })
            }
          />
        </FlexGridItem>
      )
    );

  return (
    <div style={{ marginBottom: 0 }} className="feesAndCredits">
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale1000" flexGridRowGap="scale1200">
        {makeFlexCells(fees)}
      </FlexGrid>
      {credits.length > 0 || !!sellerConcessions ? (
        <div style={{ paddingTop: '60px' }}>
          <Card title={<Label4>CREDITS</Label4>} overrides={creditCardOverrides}>
            <FlexGrid
              flexGridColumnCount={3}
              flexGridColumnGap="scale1000"
              flexGridRowGap="scale1200"
            >
              {makeFlexCells(credits)}
              {!!sellerConcessions && (
                <FlexGridItem key="Seller Concessions" overrides={flexGridItemOverrides}>
                  <NumberInput
                    value={sellerConcessions}
                    label="Seller Concessions"
                    inputType="money"
                    disabled={true}
                  />
                </FlexGridItem>
              )}
            </FlexGrid>
          </Card>
        </div>
      ) : null}
    </div>
  );
};

const CardTitle = ({ title, total }: { title: string; total: number }) => (
  <LabelWithSublabel title={title} subtitle={currency(total, 0)} />
);

export const FeeCard = ({
  fees,
  title,
  updateFee,
  sellerConcessions
}: {
  fees: Errorable<EditableFees>;
  title: string;
  updateFee: (payload: { id: string; value: number | null | undefined }) => void;
  sellerConcessions?: number | null;
}) => {
  if (fees.length === 0) {
    return null;
  }
  const total = fees
    .map(computeFeeValue)
    .reduce((acc, value) => acc + value, (sellerConcessions || 0) * -1);
  return (
    <Card
      overrides={{
        Title: {
          component: CardTitle,
          props: { title, total }
        },
        ...cardOverrides
      }}
      title
    >
      <DetailsContainer>
        <FeeCardBody fees={fees} updateFee={updateFee} sellerConcessions={sellerConcessions} />
      </DetailsContainer>
    </Card>
  );
};
