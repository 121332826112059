import { Product } from '../value-types/quote';

export const getQuoteRate = (offerQuote: Product) => {
  switch (offerQuote.rate) {
    case 'fixed':
      return `${offerQuote.term} Yr Fixed`;
    case 'adjustable':
      return `${offerQuote.fixedPeriod}/${
        offerQuote.adjustmentPeriod < 1
          ? `${12 * offerQuote.adjustmentPeriod}m`
          : offerQuote.adjustmentPeriod
      } ARM`;
  }
};
