import { styled } from 'baseui';
import React from 'react';
import { OfferTableHoverButton } from '../buttons';
import { Spinner } from '../spinner';

const CtaWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});

const SpinnerWrapper = styled('div', {
  padding: '0 16px'
});

export interface CTAProps {
  viewDetails: (props: { productId?: number; searchId?: string; offerId: string }) => void;
  includeOffer: (offerId: string) => void;
  includeOfferLoading: string | null;
  offerId: string;
  productId: number;
  searchId: string;
}

export const AllQuotesCTA = ({
  includeOfferLoading,
  includeOffer,
  viewDetails,
  offerId,
  productId,
  searchId
}: CTAProps) => (
  <CtaWrapper>
    <OfferTableHoverButton onClick={() => includeOffer(offerId)}>
      {includeOfferLoading === offerId ? (
        <SpinnerWrapper>
          <Spinner size={16} centerVertically={false} />
        </SpinnerWrapper>
      ) : (
        'Include'
      )}
    </OfferTableHoverButton>
    <OfferTableHoverButton onClick={() => viewDetails({ productId, searchId, offerId })}>
      Details
    </OfferTableHoverButton>
  </CtaWrapper>
);
