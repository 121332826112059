import * as t from 'type-shift';
import { store } from '../../redux';
import { saveMerchandising as saveMerchandisingAction } from '../../redux/rate-quote/actions';
import { loggedInFetch } from '../authentication';
import { standardizeResponse } from '../standardize';

/**
 * Amount of time to wait for no new updates to trigger a background save.
 */
const SAVE_DEBOUNCE_TIME_MS = 5 * 1000; // 5 seconds

/**
 * Handle for merchandising save time.
 */
export let merchandisingSaveTimer: NodeJS.Timeout | undefined;

/**
 * Used for storing and saving merchandising data.
 */
export interface MerchandisingPayload {
  merchandising: string[];
  starred: string[];
}

/**
 * Type definition of payload returned by `saveMerchandising` query.
 */
export const rateQuoteJustMerchandising = t.strict({
  rateQuoteId: t.string,
  merchandising: t.array(t.string),
  lastModifiedTime: t.number,
  offers: t.record(t.strict({ starred: t.boolean }))
});

/**
 * Callback function for when the merchandising save ends.
 */
export const merchandisingSaveCallback = () => {
  store.dispatch(saveMerchandisingAction(true));
};

/**
 * Sets a timer to trigger a merchandising save. If a timer is already running it will
 *   reset the timer, meaning that a save will only occur after a certain period of
 *   merchandising inactivity.
 */
export const queueMerchandisingSave = (callback: any = merchandisingSaveCallback) => {
  if (merchandisingSaveTimer) {
    clearTimeout(merchandisingSaveTimer);
  }
  merchandisingSaveTimer = setTimeout(callback, SAVE_DEBOUNCE_TIME_MS);
};

/**
 * Attempts to save the given `MerchandisingPayload` to the server.
 *
 * @param rateQuoteId Id of RateQuote to save.
 * @param payload Merchandising and Starred dataset to save.
 */
export const saveMerchandising = async (rateQuoteId: string, payload: MerchandisingPayload) => {
  if (merchandisingSaveTimer) {
    clearTimeout(merchandisingSaveTimer);
    merchandisingSaveTimer = undefined;
  }
  return await standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}/merchandising`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    rateQuoteJustMerchandising
  );
};
