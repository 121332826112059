import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';

export interface UserInfo {
  email: string;
  name: string;
  group: string[];
}

export const useGetUserInfo = (): UserInfo | null => {
  const { userGroups, tokenInfo } = useInternalAuth();

  if (tokenInfo !== undefined) {
    return {
      email: tokenInfo.email,
      name: tokenInfo.name as string,
      group: userGroups
    };
  }

  return null;
};
