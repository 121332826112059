import { styled } from 'baseui';
import React from 'react';
import { BaseButton } from '../../buttons';
import { CopyIcon } from '../../icons';

const StyledCopyPMIButton = styled(BaseButton, {
  width: '375px',
  borderStyle: 'none',
  fontSize: '14px',
  marginTop: '29px !important'
});

export const CopyPMIButton = ({ setCopyPMI }: { setCopyPMI: () => void }) => (
  <StyledCopyPMIButton
    onClick={setCopyPMI}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          backgroundColor: $theme.colors!.mono100,
          color: $theme.colors!.mono700,
          ':active': {
            backgroundColor: $theme.colors!.mono300
          },
          ':focus': {
            backgroundColor: $theme.colors!.mono100
          },
          ':hover': {
            backgroundColor: $theme.colors!.mono300
          }
        })
      }
    }}
  >
    <CopyIcon size={24} />
    <span>Copy PMI value to all offers with matching LTV</span>
  </StyledCopyPMIButton>
);
