import querystring from 'querystring';
import * as t from 'type-shift';
import { loggedInFetch } from './authentication';
import { standardizeResponse } from './standardize';

export const lockOffer = async ({
  rateQuoteId,
  borrowerScenarioId,
  sourceId,
  offerId,
  rgTitle,
  loanId,
  attorney,
  notes,
  userInfo,
  float
}: {
  rateQuoteId: string;
  borrowerScenarioId: string;
  sourceId: string;
  offerId: string;
  rgTitle: boolean | undefined;
  loanId: string;
  attorney?: string;
  notes: string;
  userInfo: { email: string; name: string } | null;
  float: boolean;
}) =>
  standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}/lock`, {
      method: 'POST',
      body: JSON.stringify({
        offerId,
        borrowerScenarioId,
        sourceId,
        rgTitle,
        loanId,
        attorney,
        notes: notes.trim(),
        userInfo,
        float
      }),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    t.shape({})
  );

export const editLock = async ({
  rateQuoteId,
  borrowerScenarioId,
  lockId,
  sourceId,
  offerId,
  rgTitle,
  loanId,
  attorney,
  notes,
  userInfo,
  link,
  float
}: {
  rateQuoteId: string;
  borrowerScenarioId: string;
  lockId: string;
  sourceId: string;
  offerId: string;
  rgTitle: boolean | undefined;
  loanId: string;
  attorney?: string;
  notes: string;
  userInfo: { email: string; name: string } | null;
  link: string;
  float: boolean;
}) =>
  standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}/lock`, {
      method: 'POST',
      body: JSON.stringify({
        offerId,
        borrowerScenarioId,
        sourceId,
        rgTitle,
        loanId,
        attorney,
        notes,
        userInfo,
        link,
        lockId,
        float
      }),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    t.shape({})
  );

export const deleteLock = async ({
  rateQuoteId,
  offerId,
  lockId,
  sourceId
}: {
  rateQuoteId: string;
  offerId: string;
  lockId: string;
  sourceId: string;
}) =>
  standardizeResponse(
    await loggedInFetch(
      `/api/rate-quote/${rateQuoteId}/lock?${querystring.stringify({ offerId, lockId, sourceId })}`,
      {
        method: 'DELETE',
        headers: {
          ['Content-Type']: 'application/json'
        }
      }
    ),
    t.shape({}).or(() => t.null)
  );

export const getLockData = async ({
  rateQuoteId,
  offerId
}: {
  rateQuoteId: string;
  offerId: string;
}) =>
  standardizeResponse(
    await loggedInFetch(
      `/api/rate-quote/${rateQuoteId}/lock?${querystring.stringify({ offerId })}`,
      {
        method: 'GET',
        headers: {
          ['Content-Type']: 'application/json'
        }
      }
    ),
    t.shape({})
  );
