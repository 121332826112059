import { all, of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../redux';

export const rateQuoteLoadingSelector = createSelector(
  ({ rateQuote, advisors }: ApplicationState) =>
    all([of(rateQuote), of(advisors)])
      .map(
        ([{ loading: isRQLoading }, { loading: isAdvisorsLoading }]) =>
          isRQLoading || isAdvisorsLoading
      )
      .orElse(() => false),
  (loading) => loading
);
