import React from 'react';

export const House = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon_propertyassumptions</title>
    <desc>Created with Sketch.</desc>
    <g id="MVP-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="RQR-MVP-1"
        transform="translate(-964.000000, -379.000000)"
        fill="#143F3D"
        fillRule="nonzero"
        stroke="#143F3D"
      >
        <g id="Group-8" transform="translate(80.000000, 336.000000)">
          <g id="icon_propertyassumptions" transform="translate(885.000000, 44.000000)">
            <path
              d="M19.8899813,9.1260173 L9.99985241,0 L0.110068311,9.1260173 C-0.0292438495,9.25486165 -0.0375198195,9.47177247 0.0921370432,9.61055427 C0.221104242,9.7489934 0.440072614,9.75687484 0.579384775,9.62837317 L1.72388245,8.57226077 L1.72388245,20 L7.24119576,20 L12.7585091,20 L18.2758224,20 L18.2758224,8.5719181 L19.4203201,9.6280305 C19.4865278,9.68936863 19.5710117,9.71952369 19.6551507,9.71952369 C19.7479105,9.71952369 19.8399807,9.68285788 19.9075678,9.6102116 C20.0375695,9.47177247 20.0292935,9.25486165 19.8899813,9.1260173 Z M7.93085992,19.3146578 L7.93085992,13.8319198 C7.93085992,12.6980211 8.85880306,11.7758931 9.99985241,11.7758931 C11.1409018,11.7758931 12.0688449,12.6980211 12.0688449,13.8319198 L12.0688449,19.3146578 L7.93085992,19.3146578 Z M17.5861582,19.3146578 L12.7585091,19.3146578 L12.7585091,13.8319198 C12.7585091,12.3203975 11.5209067,11.0905508 9.99985241,11.0905508 C8.4787981,11.0905508 7.24119576,12.3203975 7.24119576,13.8319198 L7.24119576,19.3146578 L2.41354662,19.3146578 L2.41354662,7.93557783 L9.99985241,0.93514949 L17.5861582,7.93557783 L17.5861582,19.3146578 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
