import { Deal } from '../../modules/deals/types';

export const FETCH_DEALS_ACTION_TYPE = 'DEALS__FETCH' as const;
export const fetchDeals = (accountId: string) => ({
  type: FETCH_DEALS_ACTION_TYPE,
  payload: accountId
});
export type FetchDealsAction = ReturnType<typeof fetchDeals>;

export const FETCH_DEALS_SUCCESS_ACTION_TYPE = 'DEALS__FETCH_SUCCESS' as const;
export const fetchDealsSuccess = (payload: Record<string, Deal>) => ({
  type: FETCH_DEALS_SUCCESS_ACTION_TYPE,
  payload
});
export type FetchDealsSuccessAction = ReturnType<typeof fetchDealsSuccess>;

export const FETCH_DEALS_FAILURE_ACTION_TYPE = 'DEALS__FETCH_FAILURE' as const;
export const fetchDealsError = (payload: string) => ({
  type: FETCH_DEALS_FAILURE_ACTION_TYPE,
  payload
});
export type FetchDealsFailureAction = ReturnType<typeof fetchDealsError>;
