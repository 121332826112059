import {
  H1 as BaseH1,
  H2 as BaseH2,
  H3 as BaseH3,
  H4 as BaseH4,
  H5 as BaseH5
} from 'baseui/typography';
import React from 'react';
import { headerFontFace } from '../theme-provider';

export const H1 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH1
    color="primary400"
    font="font700"
    overrides={{
      Block: {
        style: {
          fontFamily: headerFontFace
        }
      }
    }}
  >
    {children}
  </BaseH1>
);

export const H2 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH2
    color="primary400"
    font="font600"
    marginTop="scale200"
    marginBottom="scale200"
    overrides={{
      Block: {
        style: {
          fontFamily: 'headerFontFace'
        }
      }
    }}
  >
    {children}
  </BaseH2>
);

export const H3 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH3
    color="primary400"
    font="font500"
    overrides={{
      Block: {
        style: {
          fontFamily: 'headerFontFace'
        }
      }
    }}
  >
    {children}
  </BaseH3>
);

export const H4 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH4
    color="primary400"
    font="font250"
    marginTop="scale0"
    overrides={{
      Block: {
        style: {
          letterSpacing: '2px'
        }
      }
    }}
  >
    {children}
  </BaseH4>
);

export const H5 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH5 color="primary400" font="font450" marginTop="scale0">
    {children}
  </BaseH5>
);

export const Header = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseH1
    overrides={{
      Block: {
        style: ({ $theme }) => ({
          fontFamily: headerFontFace,
          fontSize: '24px',
          fontWeight: 500,
          color: $theme.colors.primary400,
          lineHeight: '24px',
          margin: '32px 0',
          paddingTop: 0,
          paddingBottom: 0
        })
      }
    }}
  >
    {children}
  </BaseH1>
);
