import { AllQuotesOffer, Offer, ProductSet } from '../../../modules/rate-quote/types';
import { Nullable } from '../state';

export const SEED_INITIAL_OFFER_ACTION_TYPE = 'OFFER_DETAILS__SEED_INITIAL_OFFER' as const;
export const seedInitialOffer = (payload: {
  lenderId: string;
  productSet: ProductSet;
  pmi: number | undefined;
  sellerConcessions?: number | null;
}) => ({
  type: SEED_INITIAL_OFFER_ACTION_TYPE,
  payload
});
export type SeedInitialOfferAction = ReturnType<typeof seedInitialOffer>;

export const SEED_OFFER_ACTION_TYPE = 'OFFER_DETAILS__SEED_OFFER' as const;
export const seedOffer = (offer: Offer | AllQuotesOffer, allQuotes?: boolean) => ({
  type: SEED_OFFER_ACTION_TYPE,
  payload: { offer, allQuotes }
});
export type SeedOfferAction = ReturnType<typeof seedOffer>;

export const UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE = 'OFFER_DETAILS__UPDATE_DETAILS_FEE' as const;
export const updateOfferDetailsFee = (payload: {
  id: string;
  value: number | null | undefined;
}) => ({
  type: UPDATE_OFFER_DETAILS_FEE_ACTION_TYPE,
  payload
});
export type UpdateOfferDetailsFeeAction = ReturnType<typeof updateOfferDetailsFee>;

export const UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE =
  'OFFER_DETAILS__UPDATE_RATE_INFO' as const;
export const updateOfferDetailsRateInfo = (
  payload: Partial<Nullable<Offer['productDetails']>>
) => ({
  type: UPDATE_OFFER_DETAILS_REATE_INFO_ACTION_TYPE,
  payload
});
export type UpdateOfferDetailsRateInfoAction = ReturnType<typeof updateOfferDetailsRateInfo>;

export const UPDATE_OFFER_DETAILS_PMI_ACTION_TYPE = 'OFFER_DETAILS__UPDATE_PMI' as const;
export const updateOfferDetailsPMI = (pmi?: number) => ({
  type: UPDATE_OFFER_DETAILS_PMI_ACTION_TYPE,
  payload: pmi
});
export type UpdateOfferDetailsPMIAction = ReturnType<typeof updateOfferDetailsPMI>;

export const UPDATE_PRICE_ACTION_TYPE = 'OFFER_DETAILS__UPDATE_PRICE' as const;
export const updatePrice = (payload: { price: number | undefined }) => ({
  type: UPDATE_PRICE_ACTION_TYPE,
  payload
});
export type UpdatePriceAction = ReturnType<typeof updatePrice>;
