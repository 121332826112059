import { OfferPermutation } from '../rate-quote/types';

/**
 *
 * @param permutationsA
 * @param permutationsB
 * @returns OfferPermutation[]
 *
 * This function takes in two arrays of offer permutations and merges them into one using the permutations' attributes as their unique indentifier.
 * If the arrays have matching attributes, add the offerId's from both into the resulting permutation.
 */
export const mergeOfferPermutations = (
  permutationsA: OfferPermutation[],
  permutationsB: OfferPermutation[]
) => {
  if (permutationsA.length === 0) {
    return permutationsB;
  }
  if (permutationsB.length === 0) {
    return permutationsA;
  }
  const resultPerms = [...permutationsA];
  const resultPermsAttributes = resultPerms.map((perm) => JSON.stringify(perm.attributes));
  permutationsB.forEach((permutationB) => {
    const permutationsAttributesB = JSON.stringify(permutationB.attributes);
    const idx = resultPermsAttributes.findIndex(
      (attributes) => attributes === permutationsAttributesB
    );
    if (idx >= 0) {
      resultPerms[idx] = {
        ...resultPerms[idx],
        offerIds: [...resultPerms[idx].offerIds, ...permutationB.offerIds]
      };
    } else {
      resultPerms.push(permutationB);
    }
  });

  return resultPerms;
};
