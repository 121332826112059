import { AllQuotesOffer, Offer, OfferPermutation } from '../../../modules/rate-quote/types';

export const CREATE_OFFER_ACTION_TYPE = 'RATE_QUOTE__CREATE_OFFER_REQUEST_ACTION' as const;
export const createOfferRequest = (payload: {
  rateQuoteId: string;
  offer: Offer | AllQuotesOffer;
  copyPMIValue: boolean;
}) => ({
  type: CREATE_OFFER_ACTION_TYPE,
  payload
});
export type CreateOfferRequestAction = ReturnType<typeof createOfferRequest>;

export const CREATE_OFFER_SUCCESS_ACTION_TYPE = 'RATE_QUOTE__CREATE_OFFER_SUCCESS_ACTION' as const;
export const createOfferSuccess = (offer: Offer, offerPermutations: OfferPermutation[]) => ({
  type: CREATE_OFFER_SUCCESS_ACTION_TYPE,
  payload: {
    offer,
    offerPermutations
  }
});
export type CreateOfferSuccessAction = ReturnType<typeof createOfferSuccess>;

export const CREATE_OFFER_FAILURE_ACTION_TYPE = 'RATE_QUOTE__CREATE_OFFER_FAILURE_ACTION' as const;
export const createOfferError = (payload: string) => ({
  type: CREATE_OFFER_FAILURE_ACTION_TYPE,
  payload
});
export type CreateOfferFailureAction = ReturnType<typeof createOfferError>;

export const REORDER_OFFERS_ACTION_TYPE = 'RATE_QUOTE__REORDER_OFFER_ACTION' as const;
export const reorderOffers = (oldIndex: number, newIndex: number) => ({
  type: REORDER_OFFERS_ACTION_TYPE,
  payload: {
    oldIndex,
    newIndex
  }
});
export type ReorderOffersAction = ReturnType<typeof reorderOffers>;

export const MERCHANDISE_OFFER_ACTION_TYPE = 'RATE_QUOTE__MERCHANDISE_OFFER_ACTION' as const;
export const merchandiseOffer = (payload: string) => ({
  type: MERCHANDISE_OFFER_ACTION_TYPE,
  payload
});
export type MerchandiseOfferAction = ReturnType<typeof merchandiseOffer>;

export const UNMERCHANDISE_OFFER_ACTION_TYPE = 'RATE_QUOTE__UNMERCHANDISE_OFFER_ACTION' as const;
export const unmerchandiseOffer = (offerId: string) => ({
  type: UNMERCHANDISE_OFFER_ACTION_TYPE,
  payload: offerId
});
export type UnmerchandiseOfferAction = ReturnType<typeof unmerchandiseOffer>;

export const UPDATE_MERCHANDISING_ACTION_TYPE = 'RATE_QUOTE__UPDATE_MERCHANDISING_ACTION' as const;
export const updateMerchandising = (payload: string[]) => ({
  type: UPDATE_MERCHANDISING_ACTION_TYPE,
  payload
});
export type UpdateMerchandisingAction = ReturnType<typeof updateMerchandising>;

export const SYNCHRONIZE_MERCHANDISING_CAHE_ACTION_TYPE =
  'RATE_QUOTE__SYNCHRONIZE_MERCHANDISING_CACHE_ACTION' as const;
export const synchronizeMerchandisingCache = () => ({
  type: SYNCHRONIZE_MERCHANDISING_CAHE_ACTION_TYPE
});
export type SynchronizeMerchandisingCacheAction = ReturnType<typeof synchronizeMerchandisingCache>;

export const CLEAR_MERCHANDISING_FROM_CACHE_ACTION_TYPE =
  'RATE_QUOTE__CLEAR_MERCHANDISING_FROM_CACHE_ACTION' as const;
export const clearMerchandisingFromCache = (rateQuoteId: string) => ({
  type: SYNCHRONIZED_MERCHANDISING_FROM_CACHE_ACTION_TYPE,
  payload: rateQuoteId
});
export type ClearMerchandisingFromCacheAction = ReturnType<typeof clearMerchandisingFromCache>;

export const SYNCHRONIZED_MERCHANDISING_FROM_CACHE_ACTION_TYPE =
  'RATE_QUOTE__SYNCHRONIZED_MERCHANDISING_FROM_CACHE_ACTION' as const;
export const synchedMerchandisingFromCache = () => ({
  type: SYNCHRONIZED_MERCHANDISING_FROM_CACHE_ACTION_TYPE
});
export type SynchronizedMerchandisingFromCacheAction = ReturnType<
  typeof synchedMerchandisingFromCache
>;

export const STORE_OFFER_ACTION_TYPE = 'RATE_QUOTE__STORE_OFFER_ACTION' as const;

export const storeOffer = (offerId: string, offerData: Partial<Offer>) => ({
  type: STORE_OFFER_ACTION_TYPE,
  payload: {
    offerId,
    offerData
  }
});
export type StoreOfferAction = ReturnType<typeof storeOffer>;

export const SET_LOCKED_OFFER_ACTION_TYPE = 'RATE_QUOTE__SET_LOCKED_OFFER_ACTION' as const;
export const setLockedOffer = (offerId: string, locked: boolean) => ({
  type: SET_LOCKED_OFFER_ACTION_TYPE,
  payload: {
    offerId,
    locked
  }
});
export type SetLockedOfferAction = ReturnType<typeof setLockedOffer>;

export const UPDATE_OFFER_ACTION_TYPE = 'RATE_QUOTE__UPDATE_OFFER_ACTION' as const;

export const updateOffer = (
  rateQuoteId: string,
  offerData: Partial<Offer>,
  copyPMIValue: boolean
) => ({
  type: UPDATE_OFFER_ACTION_TYPE,
  payload: {
    rateQuoteId,
    offerData,
    copyPMIValue
  }
});
export type UpdateOfferAction = ReturnType<typeof updateOffer>;

export const UPDATE_OFFER_SUCCESS_ACTION_TYPE = 'RATE_QUOTE__UPDATE_OFFER_SUCCESS_ACTION' as const;
export const updateOfferSuccess = () => ({
  type: UPDATE_OFFER_SUCCESS_ACTION_TYPE
});
export type UpdateOfferSuccessAction = ReturnType<typeof updateOfferSuccess>;

export const UPDATE_OFFER_FAILURE_ACTION_TYPE = 'RATE_QUOTE__UPDATE_OFFER_FAILURE_ACTION' as const;
export const updateOfferError = (payload: string) => ({
  type: UPDATE_OFFER_FAILURE_ACTION_TYPE,
  payload
});
export type UpdateOfferFailureAction = ReturnType<typeof updateOfferError>;
