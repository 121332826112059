import { styled } from 'baseui';
import { Button, ButtonProps } from 'baseui/button';
import { ThemePrimitives } from 'baseui/theme';
import React from 'react';

type ButtonWidth = 'Short' | 'Long' | 'Full';
interface AdditionalButtonProps {
  width?: ButtonWidth;
  borderColor?: keyof Omit<ThemePrimitives, 'primaryFontFamily'>;
  backgroundColor?: keyof Omit<ThemePrimitives, 'primaryFontFamily'> | 'white';
}

export const BaseButton = ({
  width = 'Regular' as ButtonWidth,
  borderColor,
  backgroundColor,
  ...props
}: ButtonProps & AdditionalButtonProps) => {
  const buttonWidths = {
    Short: '60px',
    Long: '200px',
    Full: '100%',
    Regular: '120px'
  };

  const StyledButton = styled(Button, ({ $theme }) => ({
    width: buttonWidths[width],
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
    marginRight: '5px',
    // Color interface is not exported
    ...(borderColor && { border: `1px solid ${$theme.colors![borderColor]} ` }),
    borderTopLeftRadius: '5px !important',
    borderTopRightRadius: '5px !important',
    borderBottomLeftRadius: '5px !important',
    borderBottomRightRadius: '5px !important',
    ...(backgroundColor && { backgroundColor })
  }));

  return <StyledButton {...props} />;
};

export const PrimaryButton: React.FunctionComponent<ButtonProps & AdditionalButtonProps> = ({
  ...props
}) => (
  <BaseButton
    overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          ':disabled': {
            backgroundColor: $theme.colors!.mono300,
            color: $theme.colors!.mono100,
            border: `1px solid ${$theme.colors!.mono300}`
          },
          backgroundColor: $theme.colors!.mono700
        })
      }
    }}
    {...props}
  />
);

export const SecondaryButton: React.FunctionComponent<ButtonProps & AdditionalButtonProps> = ({
  ...props
}) => (
  <BaseButton
    borderColor={'primary200'}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          ':hover': {
            backgroundColor: $theme.colors!.mono100
          },
          ':focus': {
            backgroundColor: $theme.colors!.mono100
          },
          ':active': {
            backgroundColor: $theme.colors!.mono100
          },
          ':disabled': {
            backgroundColor: $theme.colors!.mono100,
            color: $theme.colors!.mono300,
            border: `1px solid ${$theme.colors!.mono300}`
          },
          backgroundColor: $theme.colors!.mono100,
          color: $theme.colors!.mono700,
          borderStyle: 'solid'
        })
      }
    }}
    {...props}
  />
);

export const OfferTableHoverButton = styled(SecondaryButton, {
  height: '36px',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  fontSize: '14px',
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingRight: '20px',
  paddingLeft: '20px'
});
