import * as t from 'type-shift';
import { loggedInFetch } from '../authentication';
import { fetchItem, LocalStoreType, storeItem } from '../local-storage';
import { queryStringCreator } from '../query-string-creator';
import { removeObjectUndefineds } from '../remove-object-undefineds';
import { StandardizedResponse, standardizeResponse } from '../standardize';
import { MerchandisingPayload } from './merchandising';
import {
  allQuotesConverter,
  AllQuotesOffer,
  Offer,
  OfferPermutation,
  offerPermutation,
  RateQuote,
  rateQuote,
  rateQuoteNoRqr,
  RateQuoteNoRqr
} from './types';

export type FetchRateQuoteResult = StandardizedResponse<RateQuote>;

export type CreateOfferResult = StandardizedResponse<{ rateQuote: RateQuote; offerId: string }>;

export type UpdateOfferResult = StandardizedResponse<RateQuote>;

export type PreviewRqResult = StandardizedResponse<{ link: string }>;

export type PublishRqResult = StandardizedResponse<{ rateQuote: RateQuote; link: string }>;

export type SubmitRqResult = StandardizedResponse<null>;

export const fetchRateQuote = async (id: string) =>
  await standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${id}`),
    rateQuote.pipe((v) => removeObjectUndefineds<RateQuote>(v))
  );

export const createOffer = async ({
  offer,
  rateQuoteId
}: {
  rateQuoteId: string;
  offer: Offer | AllQuotesOffer;
}) => {
  return await standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}`, {
      method: 'POST',
      body: JSON.stringify({ offer }),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    t.strict({
      rateQuote: rateQuoteNoRqr.pipe((v) => removeObjectUndefineds<RateQuoteNoRqr>(v)),
      offerId: t.string
    })
  );
};

export const updateOffer = async ({
  offer,
  rateQuoteId
}: {
  offer: Partial<Offer>;
  rateQuoteId: string;
}) =>
  standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}`, {
      method: 'PUT',
      body: JSON.stringify({ offer }),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    rateQuoteNoRqr.pipe((v) => removeObjectUndefineds<RateQuoteNoRqr>(v))
  );

export const publishRateQuote = async (
  rateQuoteId: string,
  merchandisingSet: MerchandisingPayload
) =>
  await standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}/publish`, {
      method: 'POST',
      body: JSON.stringify(merchandisingSet),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    t.strict({ link: t.string, rateQuote: t.strict({ status: t.string }) })
  );

export const previewRateQuote = async (
  rateQuoteId: string,
  merchandisingSet: MerchandisingPayload
) =>
  await standardizeResponse(
    await loggedInFetch(`/api/rate-quote/${rateQuoteId}/preview`, {
      method: 'POST',
      body: JSON.stringify(merchandisingSet),
      headers: {
        ['Content-Type']: 'application/json'
      }
    }),
    t.strict({ link: t.string })
  );

export const submitRateQuote = async ({
  rateQuoteId,
  rateQuoteRequestId
}: {
  rateQuoteId: string;
  rateQuoteRequestId: string;
}) =>
  await standardizeResponse(
    await loggedInFetch(
      `/api/rate-quote/${rateQuoteId}/submit${queryStringCreator({ rateQuoteRequestId })}`,
      {
        method: 'POST',
        headers: {
          ['Content-Type']: 'application/json'
        }
      }
    ),
    t.null
  );

export type GetAllQuotesResult = StandardizedResponse<{
  errors: string[];
  token: unknown;
  offers: AllQuotesOffer[];
  offerPermutations: OfferPermutation[];
}>;

interface Range {
  low: number | null;
  high: number | null;
}
export interface FilterOptions {
  lender: string[];
  investor: string[]; // include these investors
  price: Range; // include rates with price between two numbers (inclusive)
  rate: Range; // include rates less than or equal to rate
  lockDays: Range; // include rates greater than or equal to lockDays
}

interface GetAllQuotesInput {
  rateQuoteRequestId: string;
  token?: unknown;
  limit?: number;
  filterOptions: FilterOptions;
}

export const getAllQuotes = async ({
  rateQuoteRequestId,
  token,
  limit = 500,
  filterOptions
}: GetAllQuotesInput) => {
  const { price, rate, lockDays, investor, lender } = filterOptions;
  return await standardizeResponse(
    await loggedInFetch(
      `/api/rate-quote-request/${rateQuoteRequestId}/get-all-quotes${queryStringCreator({
        limit,
        token,
        filterOptions: {
          price,
          rate,
          lockDays,
          ...(investor.length > 0
            ? {
                investor: investor.join(',')
              }
            : {}),
          ...(lender.length > 0
            ? {
                lender: lender.join(',')
              }
            : {})
        }
      })}`
    ),
    t.shape({
      errors: t.array(t.string),
      token: t.unknown,
      offers: allQuotesConverter,
      offerPermutations: t.array(offerPermutation)
    })
  );
};

export type GetProductDetailsResult = {
  notesAndAdvisories: string[];
  adjustments: { reason: string; adjustor: number; type: string }[];
};
export type GetProductDetailsStandardizedResponse = StandardizedResponse<GetProductDetailsResult>;
export const getProductDetails = async ({
  productId,
  searchId
}: {
  productId: number;
  searchId: string;
}) => {
  const key = `${productId}${searchId}`;
  const cachedProductDetails = (
    await fetchItem<GetProductDetailsResult | undefined>(key, LocalStoreType.ProductDetails)
  )
    .map((d) => d.item)
    .orElse(() => undefined);
  if (cachedProductDetails) {
    return {
      success: true as const,
      json: cachedProductDetails,
      error: null
    };
  } else {
    return await standardizeResponse(
      await loggedInFetch(
        `/api/optimal-blue/get-product-details${queryStringCreator({
          productId,
          searchId
        })}`
      ),
      t.shape({
        notesAndAdvisories: t.array(t.string),
        adjustments: t.array(
          t.strict({
            reason: t.string,
            adjustor: t.number.or(() => 0),
            type: t.string
          })
        )
      })
    );
  }
};

export const cacheProductDetails = async ({
  key,
  details
}: {
  key: string;
  details: GetProductDetailsResult;
}) => {
  const cachedProductDetails = (
    await fetchItem<GetProductDetailsResult | undefined>(key, LocalStoreType.ProductDetails)
  )
    .map((d) => d.item)
    .orElse(() => undefined);
  if (!cachedProductDetails) {
    await storeItem(key, details, LocalStoreType.ProductDetails);
  }
};

export type FetchAllQuotesFilterOptionsResult = StandardizedResponse<{
  lenders: string[];
  investors: string[];
}>;

export const fetchAllQuotesFilterOptions = async ({
  rateQuoteRequestId
}: {
  rateQuoteRequestId: string;
}): Promise<FetchAllQuotesFilterOptionsResult> =>
  await standardizeResponse(
    await loggedInFetch(`/api/rate-quote-request/${rateQuoteRequestId}/get-investors`),
    t.shape({
      lenders: t.array(t.string),
      investors: t.array(t.string)
    })
  );
