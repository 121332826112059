import { styled } from 'baseui';
import { Checkbox } from 'baseui/checkbox';
import React from 'react';
import { Notice } from '../../notice';

const StyledMessage = styled('div', {
  marginTop: '5px'
});

const StyledContainer = styled('div', {
  marginTop: '20px',
  marginBottom: '-20px'
});

export const CopyPMINotice = ({
  numOfOffers,
  copyPMIValue
}: {
  numOfOffers: number;
  copyPMIValue: boolean;
}) =>
  copyPMIValue ? (
    <StyledContainer>
      <Notice
        message={
          <StyledMessage>{`${numOfOffers} offer(s) with matching LTV, Product Type will be updated after you click Done. To discard these changes, click Cancel`}</StyledMessage>
        }
        type="success"
        icon={
          <Checkbox
            checked={true}
            overrides={{
              Checkmark: {
                style: ({ $theme }) => ({
                  backgroundColor: `${$theme.colors!['mono700']}`
                })
              }
            }}
          />
        }
      />
    </StyledContainer>
  ) : null;
