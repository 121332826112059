import { LockedRateQuote, RateQuote } from '../../modules/rate-quote/types';

export type LockedRateQuotePendingStatus = {
  status: 'pending';
};

export type LockedRateQuoteFailedStatus = {
  status: 'failed';
  error?: string | null;
  code?: number;
};

export type LockedRateQuoteSuccessStatus = {
  status: 'completed';
} & LockedRateQuote;

export type LockedRateQuoteBySource =
  | LockedRateQuotePendingStatus
  | LockedRateQuoteFailedStatus
  | LockedRateQuoteSuccessStatus;

export interface RateQuoteState {
  rateQuote: RateQuote | null;
  loading: boolean;
  saveQueued: boolean;
  error: string | null;
  lockedRateQuoteBySource?: LockedRateQuoteBySource;
}

export const defaultRateQuoteState: RateQuoteState = {
  rateQuote: null,
  loading: false,
  saveQueued: false,
  error: null
};
