export const computeFeeValue = ({
  manualValue,
  computedValue,
  calculationAttributes: { credit }
}: {
  manualValue: number | null | undefined;
  computedValue: number | string;
  calculationAttributes: { credit: boolean };
}): number => {
  let value = 0;
  if (manualValue != null) {
    value = manualValue;
  } else if (typeof computedValue === 'number') {
    value = computedValue;
  }
  return value * (credit ? -1 : 1);
};
