import fetchFn from '@rategravity/frontend/modules/advisors';
import { call, CallEffect, put, PutEffect, take } from 'redux-saga/effects';
import { fetchAdvisorsFailure, fetchAdvisorsSuccess } from './actions';
import { Advisors, AdvisorState } from './state';

type FetchAdvisorsRequest = {} | AdvisorState;

async function fetchAdvisors(): Promise<FetchAdvisorsRequest> {
  return await fetchFn();
}

function* handleFetchAdvisors(): Generator<CallEffect | PutEffect> {
  try {
    const advisors = yield call(fetchAdvisors);
    if (
      Object.keys(advisors as Advisors).length === 0 &&
      (advisors as Advisors).constructor === Object
    ) {
      throw new Error('Unable to load advisor data');
    }
    yield put(fetchAdvisorsSuccess(advisors as Advisors));
  } catch (err) {
    console.error(err);
    yield put(fetchAdvisorsFailure(JSON.stringify(err)));
  }
}

export const advisorsSaga = function* () {
  yield take('*');
  yield* handleFetchAdvisors();
};
