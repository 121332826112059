import React from 'react';

export const ExternalLink = ({ $color }: { $color?: string }) => (
  <svg width="25" height="16" viewBox="3 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.62845 3.50574L2.26173 3.50574C1.9271 3.50574 1.60617 3.63868 1.36955 3.8753C1.13293 4.11192 1 4.43284 1 4.76748V13.5996C1 13.9342 1.13293 14.2552 1.36955 14.4918C1.60617 14.7284 1.9271 14.8613 2.26173 14.8613H11.0938C11.4285 14.8613 11.7494 14.7284 11.986 14.4918C12.2226 14.2552 12.3556 13.9342 12.3556 13.5996V10.2639"
      stroke={$color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4917 6.83002V2.36913H9.03078"
      stroke={$color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4917 2.36911L6.6782 9.18353"
      stroke={$color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
