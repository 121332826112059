import fecha from 'fecha';
import { useState } from 'react';
import { useTimeout } from './use-timeout';

const ONE_MINUTE = 60 * 1000;

const isSameDay = (lastSaved: Date, currentTime: Date) =>
  lastSaved.getDate() === currentTime.getDate() &&
  lastSaved.getMonth() === currentTime.getMonth() &&
  lastSaved.getFullYear() === currentTime.getFullYear();

export const formatLastSaved = (
  lastSaved: number,
  currentTime: number
): [string, number | null] => {
  const diff = currentTime - lastSaved;
  if (diff < ONE_MINUTE) {
    return ['seconds ago', ONE_MINUTE - diff];
  }
  if (isSameDay(new Date(lastSaved), new Date(currentTime))) {
    return [`today at ${fecha.format(new Date(lastSaved), 'h:mm a')}`, null];
  }
  return [`on ${fecha.format(new Date(lastSaved), 'M/D/YY [at] h:mm a')}`, null];
};

export const useLastSaved = (lastSaved: number) => {
  const [text, time] = formatLastSaved(lastSaved, Date.now());
  const [message, setMessage] = useState(text);
  useTimeout(() => {
    const [newText] = formatLastSaved(lastSaved, Date.now());
    setMessage(newText);
  }, time);
  return time === null ? text : message;
};
