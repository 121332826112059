import * as t from 'type-shift';

const occupancy = t.oneOf(['PrimaryResidence', 'SecondHome', 'InvestmentProperty']);

const propertyType = t.oneOf([
  'Condo',
  'Townhome',
  'SingleFamily',
  'MultiFamily',
  'Condotel',
  'Coop',
  'PUD'
]);

export const property = t.strict({
  street: t.string,
  unit: t.string.or(t.null),
  city: t.string,
  county: t.string,
  state: t.string,
  zip: t.string,
  propertyType,
  occupancy,
  condoFee: t.number,
  propertyTax: t.number,
  insurance: t.number,
  warrantable: t.boolean.or(t.null),
  numberOfFloors: t.number.or(t.null),
  numberOfUnits: t.number.or(t.null)
});

export type Property = t.TypeOf<typeof property>;
