import { styled } from 'baseui';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ChevronDown, ChevronRight } from 'baseui/icon';
import { Label2 } from 'baseui/typography';
import React, { PropsWithChildren, useEffect, useState } from 'react';

export interface AccordionField {
  displayField: string;
  displayValue: string;
}

type AccordionProps = PropsWithChildren<{
  name: string;
  fields?: AccordionField[];
  isOpen?: boolean; // when accordion is controlled from outside
  initialOpenState?: boolean;
  arrowDisplay?: string;
}>;

const AccordionHeader = styled('div', {
  padding: '20px',
  cursor: 'pointer' as const,
  backgroundColor: 'white'
});

const nameProps = {
  display: 'flex' as const,
  alignItems: 'center' as const,
  flex: '1',
  paddingLeft: '15px'
};

const fieldsProps = {
  display: 'flex' as const,
  alignItems: 'center' as const,
  flex: '2'
};

const fieldProps = {
  display: 'flex' as const,
  height: '10px',
  style: {
    paddingRight: '40px'
  }
};

const DisplayField = styled('div', ({ $theme }) => ({
  textTransform: 'uppercase',
  color: $theme.colors!.primary100,
  fontFamily: $theme.typography.font200.fontFamily,
  fontWeight: 'bolder',
  fontSize: '12px',
  letterSpacing: '1.5px',
  lineHeight: '13px',
  paddingBottom: '4px',
  whiteSpace: 'nowrap' as const
}));

const DisplayValue = styled('div', ({ $theme }) => ({
  color: $theme.colors!.primary400,
  fontFamily: $theme.typography.font200.fontFamily,
  fontStyle: 'normal',
  fontWeight: 'bolder',
  fontSize: '14px',
  whiteSpace: 'nowrap' as const,
  lineHeight: '15px'
}));

const arrowDisplayProps = {
  display: 'flex' as const,
  alignItems: 'center' as const,
  justifyContent: 'flex-end' as const,
  fontSize: '14px',
  font: 'font200',
  fontWeight: 'bolder'
};

const AccordionBody = styled('div', ({ $theme }) => ({
  borderTop: `4px solid ${$theme.colors!.primary100}`
}));

const FullAccordion = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.primary50}`,
  borderBottom: 'none',
  marginBottom: '30px'
}));

export const Accordion = ({
  name,
  children,
  fields,
  isOpen: externalIsOpen,
  initialOpenState = true,
  arrowDisplay
}: AccordionProps) => {
  const [internalIsOpen, setOpen] = useState(initialOpenState);
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
  useEffect(() => {
    setOpen(initialOpenState);
  }, [initialOpenState]);

  return (
    <FullAccordion>
      <AccordionHeader onClick={() => setOpen(!isOpen)}>
        <FlexGrid flexGridColumnCount={2 + (fields ? 1 : 0)}>
          <FlexGridItem {...nameProps}>
            <Label2 color="mono700" font="font350">
              {name}
            </Label2>
          </FlexGridItem>
          {!!fields ? (
            <FlexGridItem {...fieldsProps}>
              {fields?.map(({ displayField, displayValue }, index) => (
                <div key={index} {...fieldProps}>
                  <DisplayField>{displayField}</DisplayField>
                  <DisplayValue>{displayValue}</DisplayValue>
                </div>
              ))}
            </FlexGridItem>
          ) : null}

          <FlexGridItem {...arrowDisplayProps}>
            <div style={isOpen ? { opacity: 0.7 } : {}}>{arrowDisplay}</div>
            {isOpen ? (
              <ChevronDown size={27} color="primary400" />
            ) : (
              <ChevronRight size={27} color="primary400" />
            )}
          </FlexGridItem>
        </FlexGrid>
      </AccordionHeader>
      {isOpen && <AccordionBody>{children}</AccordionBody>}
    </FullAccordion>
  );
};
