import { Advisors } from './state';

export const FETCH_ADVISORS_SUCCESS_ACTION_TYPE = 'FETCH_ADVISORS_SUCCESS_ACTION' as const;
export const fetchAdvisorsSuccess = (payload: Advisors) => ({
  type: FETCH_ADVISORS_SUCCESS_ACTION_TYPE,
  payload
});
export type FetchAdvisorsSuccessAction = ReturnType<typeof fetchAdvisorsSuccess>;

export const FETCH_ADVISORS_FAILURE_ACTION_TYPE = 'FETCH_ADIVSORS_FAILURE_ACTION' as const;
export const fetchAdvisorsFailure = (error: string) => ({
  type: FETCH_ADVISORS_FAILURE_ACTION_TYPE,
  error
});
export type FetchAdvisorsFailureAction = ReturnType<typeof fetchAdvisorsFailure>;
