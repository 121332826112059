import { all, Effect, takeEvery } from 'redux-saga/effects';
import {
  FETCH_RATE_QUOTE_ACTION_TYPE,
  FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE
} from '../rate-quote/actions';
import { setRateQuoteContext } from './add-rate-quote-context';
import { fetchFailed } from './capture-fetch-failed';

export function* captureErrors(): Iterable<Effect> {
  yield all([
    takeEvery(FETCH_RATE_QUOTE_ACTION_TYPE, setRateQuoteContext),
    takeEvery(FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE, fetchFailed)
  ]);
}
