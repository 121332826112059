import {
  all,
  AllEffect,
  call,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery
} from 'redux-saga/effects';
import {
  clearMerchandisingCacheState,
  storeMerchandisingCacheState
} from '../../../modules/offer-details/local-storage';
import { createOffer, CreateOfferResult } from '../../../modules/rate-quote';
import { updateOffer, UpdateOfferResult } from '../../../modules/rate-quote';
import { MerchandisingPayload } from '../../../modules/rate-quote/merchandising';
import { Offer } from '../../../modules/rate-quote/types';
import { merchandisingPayloadSelector } from '../../../selectors/offer';
import { rateQuoteIdSelector } from '../../../selectors/rate-quote/rate-quote';
import {
  CLEAR_MERCHANDISING_FROM_CACHE_ACTION_TYPE,
  ClearMerchandisingFromCacheAction,
  CREATE_OFFER_ACTION_TYPE,
  CREATE_OFFER_SUCCESS_ACTION_TYPE,
  createOfferError,
  CreateOfferRequestAction,
  createOfferSuccess,
  CreateOfferSuccessAction,
  MERCHANDISE_OFFER_ACTION_TYPE,
  merchandiseOffer,
  REORDER_OFFERS_ACTION_TYPE,
  saveMerchandising,
  STORE_OFFER_ACTION_TYPE,
  storeOffer,
  SYNCHRONIZE_MERCHANDISING_CAHE_ACTION_TYPE,
  synchronizeMerchandisingCache,
  SynchronizeMerchandisingCacheAction,
  UNMERCHANDISE_OFFER_ACTION_TYPE,
  UPDATE_OFFER_ACTION_TYPE,
  UpdateOfferAction,
  updateOfferError,
  updateOfferSuccess
} from '../actions';
import { manualPmiHelpers } from './helper/manual-pmi-helpers';

function* handleCreateOfferRequest({ payload }: CreateOfferRequestAction) {
  try {
    const { success, error, json }: CreateOfferResult = yield call(createOffer, payload);
    if (success) {
      const updatedOffer = json!.rateQuote.offers[json!.offerId];
      const offerPermutations = json!.rateQuote.offerPermutations;
      try {
        if (payload.copyPMIValue) {
          yield* handleUpdatePMIOffersManually(payload.rateQuoteId, updatedOffer);
        }
        yield put(createOfferSuccess(updatedOffer, offerPermutations));
      } catch (err) {
        const e = err instanceof Error ? err.message : JSON.stringify(err);
        yield put(createOfferError(e));
      }
    } else {
      yield put(createOfferError(error!));
    }
  } catch (err) {
    const e = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(createOfferError(e));
  }
}

function* handleCreateOfferSuccess({
  payload: {
    offer: { offerId }
  }
}: CreateOfferSuccessAction) {
  yield put(merchandiseOffer(offerId));
}

function* updateMerchandisingStateCache() {
  yield put(synchronizeMerchandisingCache());
}

function* handleSynchronizeMerchandisingCache(_: SynchronizeMerchandisingCacheAction) {
  const rateQuoteId: string = yield select(rateQuoteIdSelector);
  const merchandising: MerchandisingPayload = yield select(merchandisingPayloadSelector);
  yield call(storeMerchandisingCacheState, rateQuoteId, merchandising);
}

function* handleClearMerchCache({ payload }: ClearMerchandisingFromCacheAction) {
  yield call(clearMerchandisingCacheState, payload);
}

export function* handleUpdateOfferAction({
  payload: { rateQuoteId, offerData, copyPMIValue }
}: UpdateOfferAction) {
  if (offerData.offerId) {
    try {
      const { success, error, json }: UpdateOfferResult = yield call(updateOffer, {
        offer: offerData,
        rateQuoteId
      });

      if (success) {
        try {
          if (copyPMIValue) {
            yield* handleUpdatePMIOffersManually(rateQuoteId, offerData);
          }
          yield put(storeOffer(offerData.offerId, json!.offers[offerData.offerId]));
          yield put(updateOfferSuccess());
        } catch (err) {
          const e = err instanceof Error ? err.message : JSON.stringify(err);
          yield put(updateOfferError(e));
        }
      } else {
        yield put(updateOfferError(error!));
      }
    } catch (err) {
      const e = err instanceof Error ? err.message : JSON.stringify(err);
      yield put(updateOfferError(e));
    }
  } else {
    yield put(saveMerchandising(true));
    yield put(updateOfferSuccess());
  }
}

export function* handleUpdatePMIOffersManually(
  rateQuoteId: string,
  updatedOffer: Partial<Offer>
): Generator<SelectEffect | PutEffect | AllEffect<any>> {
  const offers = yield select((s) => s.rateQuote.rateQuote.offers);
  const offersToModify = manualPmiHelpers(updatedOffer, offers as Record<string, Offer>).filter(
    ({ monthlyPayment }) => monthlyPayment.pmi !== updatedOffer?.monthlyPayment?.pmi
  );

  yield all(
    offersToModify.map((offer) =>
      handleUpdatePMIOffer(offer, rateQuoteId, updatedOffer?.monthlyPayment?.pmi || 0)
    )
  );
}

function* handleUpdatePMIOffer(offer: Offer, rateQuoteId: string, pmi: number) {
  const { success, error, json }: UpdateOfferResult = yield call(updateOffer, {
    offer: { ...offer, monthlyPayment: { ...offer.monthlyPayment, pmi } },
    rateQuoteId
  });

  if (success) {
    yield put(storeOffer(offer.offerId, json!.offers[offer.offerId]));
  } else {
    throw new Error(error || '');
  }
}

function* queueSaveMerchandising() {
  yield put(saveMerchandising(false));
}

export const offersSaga = function* () {
  yield all([
    takeEvery(CREATE_OFFER_ACTION_TYPE, handleCreateOfferRequest),
    takeEvery(CREATE_OFFER_SUCCESS_ACTION_TYPE, handleCreateOfferSuccess),
    takeEvery(SYNCHRONIZE_MERCHANDISING_CAHE_ACTION_TYPE, handleSynchronizeMerchandisingCache),
    takeEvery(CLEAR_MERCHANDISING_FROM_CACHE_ACTION_TYPE, handleClearMerchCache),
    takeEvery(UPDATE_OFFER_ACTION_TYPE, handleUpdateOfferAction),
    // Actions that trigger updates to the merchandising array in the background.
    takeEvery(
      [
        STORE_OFFER_ACTION_TYPE,
        REORDER_OFFERS_ACTION_TYPE,
        MERCHANDISE_OFFER_ACTION_TYPE,
        UNMERCHANDISE_OFFER_ACTION_TYPE
      ],
      queueSaveMerchandising
    ),
    // Merchandising cache sync actions
    takeEvery(
      [
        MERCHANDISE_OFFER_ACTION_TYPE,
        UPDATE_OFFER_ACTION_TYPE,
        STORE_OFFER_ACTION_TYPE,
        UNMERCHANDISE_OFFER_ACTION_TYPE
      ],
      updateMerchandisingStateCache
    )
  ]);
};
