import { styled } from 'baseui';
import { ChevronDown } from 'baseui/icon';
import { OnChangeParams, Select, Value } from 'baseui/select';
import React from 'react';
import { ErrorLabel2, Label2 } from '../../labels';

interface SelectType {
  label: string;
  value?: Value;
  disabled?: boolean;
  error?: boolean;
  multi?: boolean;
  options: Value;
  clearable?: boolean;
  onChange: (params: OnChangeParams) => void;
  placeholder?: string;
  labelKey: string;
}

const SelectContainer = styled('div', () => ({
  width: '100%'
}));

const ChevronIcon = styled(ChevronDown, () => ({
  cursor: 'pointer'
}));

const Chevron = ({ disabled }: { disabled: boolean }) =>
  disabled ? null : <ChevronIcon size={24} />;

export const MultiSelect = ({
  label,
  options,
  disabled,
  error,
  value,
  placeholder,
  onChange,
  labelKey = 'value'
}: SelectType) => (
  <SelectContainer>
    {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
    <Select
      options={options}
      labelKey={labelKey}
      valueKey="value"
      placeholder={placeholder}
      multi
      filterOutSelected={false}
      onChange={onChange}
      value={value}
      disabled={disabled}
      overrides={{
        ControlContainer: {
          style: ({ $theme }) => ({
            opacity: disabled ? 0.6 : 1,
            backgroundColor: disabled ? $theme.colors!.mono200 : 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: error ? $theme.colors!.negative400 : $theme.colors!.mono300
          })
        },
        Dropdown: {
          style: {
            backgroundColor: 'white',
            width: '100%'
          }
        },
        SelectArrow: {
          component: Chevron,
          props: { disabled },
          style: ({ $theme }) => ({
            color: $theme.colors!.primary400
          })
        }
      }}
    />
  </SelectContainer>
);
