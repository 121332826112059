import { toaster } from 'baseui/toast';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { toasterPositive } from '../../components/toaster';
import { isSameOffer } from '../../modules/offer-table/is-same-offer';
import {
  createOffer,
  CreateOfferResult,
  getAllQuotes as fetchAllQuotes,
  GetAllQuotesResult
} from '../../modules/rate-quote';
import { RateQuote } from '../../modules/rate-quote/types';
import { allQuotesSelector } from '../../selectors/all-quotes';
import { offersSelector } from '../../selectors/offer';
import { rateQuoteIdSelector } from '../../selectors/rate-quote/rate-quote';
import { createOfferSuccess } from '../rate-quote/actions';
import {
  addOfferToRQ,
  addOfferToRQComplete,
  getAllQuotes,
  getAllQuotesComplete,
  getAllQuotesError,
  getAllQuotesSuccess,
  toggleSameOfferModal
} from './slice';
import { AllQuotesRates } from './state';

function* handleGetAllQuotes({
  payload: { rateQuoteRequestId, filterOptions }
}: ReturnType<typeof getAllQuotes>) {
  try {
    let done = false;
    let token;
    let retry = 0;
    while (!done) {
      const result: GetAllQuotesResult = yield call(fetchAllQuotes, {
        rateQuoteRequestId,
        filterOptions,
        token
      });
      if (result.success) {
        const { offers, offerPermutations } = result.json;
        yield put(getAllQuotesSuccess({ offers, offerPermutations }));
        // log errors from rate quote stack and reassign done and token
        console.log(`get all quotes errors: ${result.json.errors}`);
        done = !result.json.token;
        token = result.json.token;
      } else {
        // if we can't get rates back, retry up to 3 times and record the error
        yield put(getAllQuotesError({ error: result.error }));
        retry += 1;
        if (retry > 3) {
          break;
        }
      }
    }
    yield put(getAllQuotesComplete());
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(getAllQuotesError({ error }));
  }
}

function* handleAddOfferToRQ({ payload: { offerId } }: ReturnType<typeof addOfferToRQ>) {
  try {
    const rateQuoteId: string = yield select(rateQuoteIdSelector);
    const allQuotes: AllQuotesRates = yield select(allQuotesSelector);
    const offer = allQuotes[offerId];
    const currentOffers: RateQuote['offers'] = yield select(offersSelector);

    if (isSameOffer(currentOffers, offer)) {
      yield put(toggleSameOfferModal({ isOpen: true }));
      yield put(addOfferToRQComplete());
      return;
    }

    const { success, json, error }: CreateOfferResult = yield call(createOffer, {
      rateQuoteId,
      offer
    });
    yield put(addOfferToRQComplete());
    if (success) {
      const updatedOffer = json!.rateQuote.offers[json!.offerId];
      const offerPermutations = json!.rateQuote.offerPermutations;
      toasterPositive('Successfully added offer to the publishable Rate Quote');
      yield put(createOfferSuccess(updatedOffer, offerPermutations));
    } else {
      toaster.negative(`Error adding offer. Try reloading the page. ${error}`, {
        closeable: true
      });
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    console.error('Could not add offer to Rate Quote:', error);
  }
}

export const allQuotesSaga = function* () {
  yield all([
    takeEvery(getAllQuotes.type, handleGetAllQuotes),
    takeEvery(addOfferToRQ.type, handleAddOfferToRQ)
  ]);
};
