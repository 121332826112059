import fecha from 'fecha';
import { createSelector } from 'reselect';
import { Deal } from '../modules/deals/types';
import { ApplicationState } from '../redux';

export const WITHDRAWN_STAGE = '07ca10e8-725a-47ea-a780-8c1a58fd8abf';
export const FUNDED_STAGE = 'c4bb2fd4-3642-48cb-90b5-32032c36882b';
export const LEAD_STAGE = 'af9fc1f6-c847-47fa-bf74-c4c6957689a2';
export const SCHEDULED_CONSULT_STAGE = '353a02a4-e3d2-4d99-ab73-a9c0bc876cf8';
export const NOT_RESPONSIVE_STAGE = '24d52f35-8438-4ec3-9f7a-12db9d6b02aa';
export const LOCKED_STAGE = 'de738950-eb6e-4f67-96a5-2bb9e142386f';

const INVALID_STAGES = new Set([
  WITHDRAWN_STAGE,
  FUNDED_STAGE,
  LEAD_STAGE,
  SCHEDULED_CONSULT_STAGE,
  NOT_RESPONSIVE_STAGE,
  LOCKED_STAGE
]);

const validateDeal = (deal: Deal): boolean => {
  const stage = deal?.properties?.dealstage;
  return !!stage && !INVALID_STAGES.has(stage);
};

const getInvalidDealStage = (dealstage?: string) => {
  switch (dealstage) {
    case LOCKED_STAGE:
      return 'locked';
    case WITHDRAWN_STAGE:
    case FUNDED_STAGE:
      return 'invalid';
    case LEAD_STAGE:
    case SCHEDULED_CONSULT_STAGE:
    case NOT_RESPONSIVE_STAGE:
      return 'notReady';
    default:
      return 'unknown';
  }
};

export const dealsSelector = ({ deals }: ApplicationState) => deals;

export const dealIdsSelector = createSelector(dealsSelector, ({ deals }) =>
  deals
    ? Object.values(deals)
        .filter(validateDeal)
        .map((d) => ({
          value: d.id,
          label: `${d.id} ${
            d.properties
              ? `- ${d.properties.occupancy || 'N/A'}, consult completed ${
                  d.properties.date_consulted_completed
                    ? fecha.format(new Date(d.properties.date_consulted_completed), 'MM/YYYY')
                    : 'N/A'
                }`
              : ''
          }`
        }))
    : []
);

export const invalidDealsSelector = createSelector(dealsSelector, ({ deals }) =>
  deals
    ? Object.values(deals)
        .filter((deal) => !validateDeal(deal))
        .map(({ id, properties }) => ({
          dealId: id,
          stage: getInvalidDealStage(properties?.dealstage)
        }))
    : []
);
