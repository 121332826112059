import { Offer } from '../../modules/rate-quote/types';
import { LockData } from './state';

export const CLOSE_LOCK_MODAL_ACTION_TYPE = 'LOCK_MODAL__CLOSE_ACTION' as const;
export const closeLockModal = () => ({ type: CLOSE_LOCK_MODAL_ACTION_TYPE });
export type CloseLockModal = ReturnType<typeof closeLockModal>;

// Submit Lock

export const LOCK_OFFER_ACTION_TYPE = 'LOCK__SUBMIT_REQUEST_ACTION' as const;
export const lockOfferRequest = (payload: {
  rateQuoteId: string;
  borrowerScenarioId: string;
  sourceId: string;
  offer: Offer;
  offerId: string;
  rgTitle: boolean | undefined;
  loanId: string;
  attorney?: string;
  notes: string;
  userInfo: { email: string; name: string } | null;
  float: boolean;
}) => ({
  type: LOCK_OFFER_ACTION_TYPE,
  payload
});
export type LockOfferAction = ReturnType<typeof lockOfferRequest>;

export const LOCK_OFFER_SUCCESS_ACTION_TYPE = 'LOCK__SUBMIT_SUCCESS_ACTION' as const;
export const lockOfferSuccess = (payload: { success: boolean; pending: boolean }) => ({
  type: LOCK_OFFER_SUCCESS_ACTION_TYPE,
  payload
});
export type LockOfferSuccessAction = ReturnType<typeof lockOfferSuccess>;

export const LOCK_OFFER_FAILURE_ACTION_TYPE = 'LOCK__SUBMIT_FAILURE_ACTION' as const;
export const lockOfferFailure = (payload: string) => ({
  type: LOCK_OFFER_FAILURE_ACTION_TYPE,
  payload
});
export type LockOfferFailureAction = ReturnType<typeof lockOfferFailure>;

// Get Lock Data
export const GET_LOCK_ACTION_TYPE = 'LOCK__GET_REQUEST_ACTION' as const;
export const getLockRequest = (payload: { rateQuoteId: string; offer: Offer }) => ({
  type: GET_LOCK_ACTION_TYPE,
  payload
});
export type GetLockAction = ReturnType<typeof getLockRequest>;

export const CANCEL_GET_LOCK_ACTION_TYPE = 'LOCK__CANCEL_GET_REQUEST_ACTION' as const;
export const cancelGetLockRequest = () => ({
  type: CANCEL_GET_LOCK_ACTION_TYPE
});
export type CancelGetLockActionType = ReturnType<typeof cancelGetLockRequest>;

export const GET_LOCK_SUCCESS_ACTION_TYPE = 'LOCK__GET_SUCCESS_ACTION' as const;
export const getLockSuccess = (payload: {
  offerId: string;
  loanId: string;
  pending: boolean;
  rgTitle: boolean | undefined;
  attorney?: string;
  notes: string;
  link: string;
  lockId: string;
  updatedHubspot?: boolean;
  float: boolean;
}) => ({
  type: GET_LOCK_SUCCESS_ACTION_TYPE,
  payload
});
export type GetLockSuccessAction = ReturnType<typeof getLockSuccess>;

export const GET_LOCK_FAILURE_ACTION_TYPE = 'LOCK__GET_FAILURE_ACTION' as const;
export const getLockFailure = (payload: string) => ({
  type: GET_LOCK_FAILURE_ACTION_TYPE,
  payload
});
export type GetLockFailureAction = ReturnType<typeof getLockFailure>;

// Undo (delete) Lock
export const DELETE_LOCK_ACTION_TYPE = 'LOCK__DELETE_REQUEST_ACTION' as const;
export const deleteLockRequest = (payload: {
  rateQuoteId: string;
  offerId: string;
  lockId: string;
  sourceId: string;
}) => ({
  type: DELETE_LOCK_ACTION_TYPE,
  payload
});
export type DeleteLockAction = ReturnType<typeof deleteLockRequest>;

export const DELETE_LOCK_SUCCESS_ACTION_TYPE = 'LOCK__DELETE_SUCCESS_ACTION' as const;
export const deleteLockSuccess = () => ({
  type: DELETE_LOCK_SUCCESS_ACTION_TYPE
});
export type DeleteLockSuccessAction = ReturnType<typeof deleteLockSuccess>;

export const DELETE_LOCK_FAILURE_ACTION_TYPE = 'LOCK__DELETE_FAILURE_ACTION' as const;
export const deleteLockFailure = (payload: string) => ({
  type: DELETE_LOCK_FAILURE_ACTION_TYPE,
  payload
});
export type DeleteLockFailureAction = ReturnType<typeof deleteLockFailure>;

// Edit Lock
export const EDIT_LOCK_ACTION_TYPE = 'LOCK__EDIT_REQUEST_ACTION' as const;
export const editLockRequest = (payload: {
  rateQuoteId: string;
  borrowerScenarioId: string;
  sourceId: string;
  offerId: string;
  offer: Offer;
  rgTitle: boolean | undefined;
  loanId: string;
  attorney?: string;
  notes: string;
  lockId: string;
  userInfo: { email: string; name: string } | null;
  link: string;
  float: boolean;
}) => ({
  type: EDIT_LOCK_ACTION_TYPE,
  payload
});
export type EditLockAction = ReturnType<typeof editLockRequest>;

export const EDIT_LOCK_SUCCESS_ACTION_TYPE = 'LOCK__EDIT_SUCCESS_ACTION' as const;
export const editLockSuccess = () => ({
  type: EDIT_LOCK_SUCCESS_ACTION_TYPE
});

export type EditLockSuccessAction = ReturnType<typeof editLockSuccess>;

// Update Offer Lock Fields
export const UPDATE_LOCK_MODAL_FIELDS_ACTION_TYPE = 'LOCK_MODAL__UPDATE_FIELDS' as const;
export const updateLockModalFields = (payload: { id: string; value: string | boolean }) => ({
  type: UPDATE_LOCK_MODAL_FIELDS_ACTION_TYPE,
  payload
});
export type UpdateLockModalFieldsAction = ReturnType<typeof updateLockModalFields>;

// Seed Lock Modal
export const SEED_LOCK_MODAL_ACTION_TYPE = 'LOCK_MODAL__SEED_DATA' as const;
export const seedLockModal = (payload: { modalLock: LockData; offer: Offer }) => ({
  type: SEED_LOCK_MODAL_ACTION_TYPE,
  payload
});
export type SeedLockModalAction = ReturnType<typeof seedLockModal>;
