import * as t from 'type-shift';

interface FixedQuote {
  rate: 'fixed';
  term: number;
}

interface AdjustableQuote {
  rate: 'adjustable';
  term: number;
  fixedPeriod: number;
  adjustmentPeriod: number;
}

export type Product = FixedQuote | AdjustableQuote;

export const fixedQuote = t.strict({
  rate: t.literal('fixed'),
  term: t.number
});

export const adjustableQuote = t.strict({
  rate: t.literal('adjustable'),
  term: t.number,
  fixedPeriod: t.number,
  adjustmentPeriod: t.number
});

export const product = t.taggedUnion<Product>('rate', {
  fixed: fixedQuote,
  adjustable: adjustableQuote
});
