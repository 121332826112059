import * as t from 'type-shift';

export type ClosingCostOption = 'fullCost' | 'lenderCredit' | 'financeClosing';
export const closingCostOption = t.oneOf<ClosingCostOption>([
  'fullCost',
  'lenderCredit',
  'financeClosing'
]);

export type ClosingToCover = 'justClosing' | 'allFees' | null;
export const closingToCover = t.oneOf<ClosingToCover>(['justClosing', 'allFees']).or(t.null);

export type InterestOptions = 'principalAndInterest' | 'interestOnly';
export const interestOptions = t.oneOf<InterestOptions>(['principalAndInterest', 'interestOnly']);

export type EscrowOptions = 'include' | 'waive';
export const escrowOptions = t.oneOf<EscrowOptions>(['include', 'waive']);
