import { Deal } from '../../modules/deals/types';

export interface DealsState {
  deals: Record<string, Deal> | null;
  loading: boolean;
  error: string | null;
}

export const defaultDealsState: DealsState = {
  deals: null,
  loading: true,
  error: null
};
