import { all, of } from '@rategravity/core-lib/optional';
import { Loan, loanValue, payments } from '@rategravity/core-lib/rates';
import { createSelector } from 'reselect';
import { v4 as uuid } from 'uuid';
import { OfferDetailsRateInfo } from '../components/offer-details/card/rate-info-card';
import { FullAttributes } from '../components/offer-details/fee-modal/attributes-panel';
import { computeFeeValue } from '../modules/rate-quote/fee-value';
import { Offer, PurchaseRateQuote, Purpose, RefinanceRateQuote } from '../modules/rate-quote/types';
import { Product } from '../modules/value-types/quote';
import { ApplicationState } from '../redux';
import { EditableFees, Errorable } from '../redux/offer-details/state';
import { EditableOffer } from '../redux/offer-details/state';
import { purposeSelector } from './rate-quote/rate-quote';

const defaultOffer = (purpose: Purpose) => ({
  offerId: uuid(),
  type: purpose,
  starred: true,
  monthlyPayment: {},
  productDetails: {
    label: '',
    rate: null,
    manualRateLockDays: null,
    armCaps: null,
    apr: null
  },
  lender: {
    id: 'BigTex',
    url: '',
    name: 'BigTex',
    nmls: ''
  },
  computedProductDetails: {
    apr: 0,
    rateLockDays: 0,
    prepaidInterest: 0,
    dti: 0
  },
  manualFees: [],
  fees: [],
  attributes: defaultAttributes(purpose),
  lastModifiedTime: Date.now(),
  hasChanged: false,
  method: 'Manual' as const,
  expired: false,
  locked: false,
  adjustments: [],
  notesAndAdvisories: []
});

const defaultAttributes = (purpose: Purpose) => {
  const base = {
    product: {
      rate: 'fixed',
      term: 30
    },
    lender: 'BigTex',
    fullLender: {
      id: 'BigTex',
      url: '',
      name: 'BigTex',
      nmls: ''
    },
    loanType: 'Standard',
    helocLine: 0,
    closingCostOption: 'fullCost',
    closingToCover: null,
    interestOptions: 'principalAndInterest',
    escrowOptions: 'include',
    amtToFinance: 0,
    ltv: 0,
    price: 100,
    conforming: true,
    aboveNationalBaseline: false
  };
  return purpose === 'purchase'
    ? ({ ...base, downPayment: 0 } as EditableOffer['attributes'])
    : ({
        ...base,
        cashOut: 0,
        existingLoans: [],
        totalCashOut: 0,
        loanSize: 0,
        irrrlEligible: false
      } as EditableOffer['attributes']);
};

const validFee = ({
  calculationAttributes: { modifiers },
  manualValue,
  computedValue
}: EditableFees[0]) => {
  if (modifiers.find((m) => m === 'optional')) {
    return true;
  }
  if (manualValue === null && (typeof computedValue === 'number' || computedValue === 'Unknown')) {
    return true;
  }
  return typeof manualValue === 'number';
};

const validComputed = <T>(manualValue: null | undefined | T, computedValue: T | null) => {
  return manualValue != undefined || computedValue != undefined;
};

const validPositiveNumber = (value: null | undefined | '' | number): boolean => {
  return value != null && value !== '' && value >= 0;
};

const modalOfferSelector = ({ offerDetails: { modalOffer } }: ApplicationState) => modalOffer;

export const offerDetailsFeesSelector = createSelector(
  modalOfferSelector,
  ({ offerDetails: { validateForm } }: ApplicationState) => validateForm,
  (modalOffer, validateForm) =>
    of(modalOffer)
      .map(({ fees }) => fees.map((el) => ({ ...el, error: validateForm ? !validFee(el) : false })))
      .orElse(() => [] as Errorable<EditableFees>)
);

export const offerDetailsFeeGroupsSelector = createSelector(offerDetailsFeesSelector, (fees) => ({
  lenderFee: fees.filter(({ calculationAttributes: { group } }) => group === 'LenderFee'),
  loanServices: fees.filter(({ calculationAttributes: { group } }) => group === 'LoanServices'),
  title: fees.filter(({ calculationAttributes: { group } }) => group === 'Title'),
  government: fees.filter(({ calculationAttributes: { group } }) => group === 'TaxesGov'),
  prepaids: fees.filter(({ calculationAttributes: { group } }) => group === 'PrepaidsEscrows')
}));

const offerDetailsFeeCategorySelector = createSelector(offerDetailsFeesSelector, (fees) => ({
  closingCost: fees.filter(({ calculationAttributes: { category } }) => category === 'ClosingCost'),
  prepaid: fees.filter(({ calculationAttributes: { category } }) => category === 'Prepaid'),
  other: fees.filter(({ calculationAttributes: { category } }) => category === 'Other')
}));

export const offerDetailsToOfferSelector = createSelector(
  modalOfferSelector,
  purposeSelector,
  (modalOffer, purpose) =>
    of(modalOffer)
      .map(({ fees, ...rest }) => ({
        ...rest,
        manualFees: fees
          .map(({ id, manualValue }) => ({ id, value: manualValue }))
          .filter(({ value }) => typeof value === 'number') as Offer['manualFees'],
        fees: fees.map(({ manualValue, ...feeRest }) => ({
          ...feeRest,
          manualValue: manualValue === undefined ? null : manualValue
        }))
      }))
      .orElse(() => defaultOffer(purpose))
);

/**
 *   Calculates any additional costs attributed to closing or fees that want
 *   to be rolled in.
 */
export const amountToFinanceSelector = createSelector(
  modalOfferSelector,
  ({ offerDetails: { hasChanged } }: ApplicationState) => hasChanged,
  offerDetailsFeeCategorySelector,
  (modalOffer, hasChanged, allFees): number | string =>
    of(modalOffer)
      .map(({ attributes }) => {
        // When the modal has not changed or we're not financing closing costs, use the backend
        // amtToFinance value, otherwise calculate it based on the fees that have changed
        if (!hasChanged || attributes.closingCostOption !== 'financeClosing') {
          return attributes.amtToFinance;
        }

        let incompleteError: string = 'Incomplete';
        let fees: Errorable<EditableFees> = [];

        // Fetch fees and determine language for error message.
        switch (attributes.closingToCover) {
          case 'allFees':
            fees = [...allFees.closingCost, ...allFees.prepaid, ...allFees.other];
            incompleteError = 'Incomplete Closing Costs';
            break;
          case 'justClosing':
            fees = allFees.closingCost;
            incompleteError = 'Incomplete Fees';
            break;
        }

        let amountToFinance = 0;
        // Add values of included fees, or return error if any are incomplete.
        if (fees.length > 0) {
          for (const fee of fees) {
            if (fee.error) {
              return incompleteError;
            }
            // Credits are stored as positive numbers so we must deduct them.
            const creditValue = fee.calculationAttributes.credit ? -1 : 1;
            if (typeof fee.manualValue === 'number') {
              amountToFinance += fee.manualValue * creditValue;
            } else if (typeof fee.computedValue === 'number') {
              amountToFinance += fee.computedValue * creditValue;
            } else if (fee.computedValue === 'ManualEntry') {
              return incompleteError;
            }
          }
        }
        return amountToFinance;
      })
      .orElse(() => 0)
);

const loanSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) => rateQuote,
  modalOfferSelector,
  amountToFinanceSelector,
  (rateQuote, modalOffer, amountToFinance) =>
    all([of(rateQuote), of(modalOffer)]).map(
      ([
        { rateQuoteRequest },
        {
          fees,
          productDetails: { rate, ...restDetails },
          computedProductDetails,
          monthlyPayment,
          attributes: { product, loanType, ...restAttributes }
        }
      ]): Loan & { loanTerm: number; amtToFinance: number } => {
        const forceFeeValue = ({
          manualValue = null,
          computedValue,
          id,
          calculationAttributes
        }: EditableFees[0]) => {
          return {
            id,
            value: computeFeeValue({ manualValue, computedValue, calculationAttributes })
          };
        };

        const feesOnly = fees
          .filter(
            ({ calculationAttributes: { apr: includeInApr, category } }) =>
              category !== 'Prepaid' && includeInApr
          )
          .map(forceFeeValue);

        const amtToFinance = typeof amountToFinance === 'string' ? 0 : amountToFinance;
        let marginValue = 0;
        if (
          'margin' in restDetails &&
          'indexType' in restDetails &&
          'indexRate' in computedProductDetails
        ) {
          const indexType =
            restDetails.indexType ||
            (computedProductDetails.indexRate.defaultIndex !== 'ManualEntry'
              ? computedProductDetails.indexRate.defaultIndex
              : null);
          const margin =
            indexType !== null ? computedProductDetails.indexRate.margins[indexType] : 0;
          marginValue = restDetails.margin || (margin !== 'ManualEntry' ? margin : 0);
        }
        const loanProduct =
          product.rate === 'fixed'
            ? {
                loanTerm: product.term,
                fixedTerm: product.term
              }
            : {
                loanTerm: product.term,
                fixedTerm: product.fixedPeriod,
                adjustmentPeriod: product.adjustmentPeriod,
                caps:
                  'armCaps' in restDetails &&
                  'armCaps' in computedProductDetails &&
                  restDetails.armCaps !== null &&
                  computedProductDetails.armCaps !== null
                    ? {
                        inital:
                          restDetails.armCaps?.initial || computedProductDetails.armCaps?.initial,
                        periodic:
                          restDetails.armCaps?.periodic || computedProductDetails.armCaps?.periodic,
                        lifetime:
                          restDetails.armCaps?.lifetime || computedProductDetails.armCaps?.lifetime
                      }
                    : { inital: 5, periodic: 2, lifetime: 5 },
                margin: marginValue,
                indexValue: ('indexRate' in restDetails && restDetails.indexRate) || rate || 0
              };
        const baseLoan = {
          product: loanProduct,
          loanTerm: product.term,
          amtToFinance,
          rate: rate || 0,
          pAndI: 0,
          fees: feesOnly,
          prepaids: [],
          mortgageInsurance: 'pmi' in monthlyPayment ? monthlyPayment.pmi! : 0,
          upMip: !!restAttributes.upFrontInsuranceFee
            ? restAttributes.upFrontInsuranceFee!.value
            : undefined
        };

        if ('downPayment' in restAttributes) {
          const downPayment = restAttributes.downPayment;
          const purchasePrice = (rateQuoteRequest as PurchaseRateQuote['rateQuoteRequest'])
            .purchasePrice;
          return {
            ...baseLoan,
            downPayment,
            purchasePrice
          };
        }

        const cashOut = restAttributes.cashOut;
        const remainingLoanBalance = restAttributes.loanSize - cashOut;

        return {
          ...baseLoan,
          cashOut,
          remainingLoanBalance,
          propertyValue: (rateQuoteRequest as RefinanceRateQuote['rateQuoteRequest']).propertyValue
        };
      }
    )
);

const loanSizeSelector = createSelector(
  amountToFinanceSelector,
  loanSelector,
  (amountToFinance, loan): number | string => {
    const loanSize = loan.value ? loanValue(loan.value) : 0;
    if (typeof amountToFinance === 'string') {
      return amountToFinance;
    }
    return loanSize;
  }
);

export const offerDetailsAttributesSelector = createSelector(
  modalOfferSelector,
  purposeSelector,
  loanSelector,
  loanSizeSelector,
  (modalOffer, purpose, loan, loanSize): FullAttributes =>
    all([of(modalOffer), loan])
      .map(
        ([{ attributes, lender }]) =>
          ({
            ...attributes,
            fullLender: lender,
            loanSize
          }) as FullAttributes
      )
      .orElse(
        () =>
          ({
            ...defaultAttributes(purpose),
            fullLender: defaultOffer(purpose).lender,
            loanSize: 0,
            upFrontInsuranceFee: undefined
          }) as FullAttributes
      )
);

export const offerDetailsUISelector = createSelector(
  ({ offerDetails: { modalOffer, ...formUI } }: ApplicationState) => formUI,
  (formUI) => formUI
);

export const offerDetailsRateInfoSelector = createSelector(
  modalOfferSelector,
  ({ offerDetails: { validateForm } }: ApplicationState) => validateForm,
  (modalOffer, validateForm) => {
    const restDetailsRateInfo = of(modalOffer)
      .map(
        ({
          productDetails: { label, rate, manualRateLockDays, ...restProductDetails },
          computedProductDetails: { rateLockDays, ...restComputedProductDetails },
          attributes: { product, price },
          method,
          investor,
          productName
        }) => {
          const base = {
            label: { value: label, error: false },
            rate: { value: rate, error: rate == null && validateForm },
            rateLockDays: {
              computedValue: rateLockDays,
              manualValue: manualRateLockDays,
              error:
                validateForm &&
                (manualRateLockDays === undefined ||
                  !validComputed(manualRateLockDays, rateLockDays))
            },
            method,
            investor,
            productName,
            price: { value: price, error: validateForm && !price }
          };
          if (
            'adjustmentPeriod' in product &&
            'armCaps' in restProductDetails &&
            'armCaps' in restComputedProductDetails
          ) {
            const { margins, defaultIndex } = restComputedProductDetails.indexRate;
            const computedIndexType = defaultIndex !== 'ManualEntry' ? defaultIndex : null;
            const currentIndexType =
              'indexType' in restProductDetails && restProductDetails.indexType !== null
                ? restProductDetails.indexType
                : computedIndexType;
            const computedMargin =
              currentIndexType && margins[currentIndexType] !== 'ManualEntry'
                ? margins[currentIndexType]
                : null;
            const manualIndexType =
              'indexType' in restProductDetails ? restProductDetails.indexType : null;
            const manualMargin = 'margin' in restProductDetails ? restProductDetails.margin : null;
            return {
              ...base,
              armCaps: {
                computedValue: restComputedProductDetails.armCaps,
                manualValue: restProductDetails.armCaps,
                error:
                  validateForm &&
                  !validComputed(restProductDetails.armCaps, restComputedProductDetails.armCaps)
              },
              indexType: {
                computedValue: computedIndexType,
                manualValue: manualIndexType,
                error: validateForm && !validComputed(manualIndexType, computedIndexType)
              },
              margin: {
                computedValue: computedMargin,
                manualValue: manualMargin,
                error:
                  (validateForm && manualMargin === undefined) ||
                  !validComputed(manualMargin, computedMargin)
              }
            };
          }
          return {
            ...base,
            armCaps: {}
          };
        }
      )
      .orElse(() => ({
        label: { value: '', error: false },
        rate: { value: null, error: false },
        rateLockDays: { computedValue: null, manualValue: null, error: false },
        armCaps: {},
        method: 'Manual',
        investor: null,
        productName: null,
        price: { value: 0, error: false }
      }));
    return restDetailsRateInfo as OfferDetailsRateInfo;
  }
);

export const offerPAndI = createSelector(
  loanSelector,
  createSelector(
    offerDetailsAttributesSelector,
    ({ interestOptions }) => interestOptions === 'principalAndInterest'
  ),
  (maybeLoan, isPAndI) =>
    maybeLoan
      .map((loan) => (isPAndI ? payments(loan) : loanValue(loan) * (loan.rate / 1200)))
      .map((v) => v || 0)
      .orElse(() => 0)
);

const validFeesSelector = createSelector(modalOfferSelector, (modalOffer) =>
  of(modalOffer)
    .map(
      ({ fees, productDetails: { rate } }) =>
        fees.reduce((acc, fee) => (validFee(fee) ? acc : false), true) && typeof rate === 'number'
    )
    .orElse(() => false)
);

export const offerDetailsProductSelector = createSelector(modalOfferSelector, (modalOffer) =>
  of(modalOffer)
    .map(({ attributes: { product } }) => product)
    .orElse(() => ({}) as Product)
);

export const offerDetailsSellerConcessionsSelector = createSelector(
  modalOfferSelector,
  (modalOffer) =>
    of(modalOffer)
      .map((offer) => offer.sellerConcessions)
      .orElse(() => undefined)
);

export const showPMISelector = createSelector(
  modalOfferSelector,
  (modalOffer) => modalOffer?.monthlyPayment.mi?.required ?? false
);

export const monthlyPaymentSelector = createSelector(
  showPMISelector,
  offerPAndI,
  ({ offerDetails: { validateForm } }: ApplicationState) => validateForm,
  createSelector(
    ({ offerDetails: { modalOffer }, rateQuote: { rateQuote } }: ApplicationState) => ({
      modalOffer,
      rateQuote
    }),
    ({ modalOffer, rateQuote }) =>
      all([of(rateQuote), of(modalOffer)])
        .map(
          ([
            {
              rateQuoteRequest: {
                property: { insurance, propertyTax }
              }
            },
            {
              monthlyPayment: { pmi, mi }
            }
          ]) => ({ insurance, propertyTax, pmi, mi })
        )
        .orElse(() => ({
          insurance: 0,
          propertyTax: 0,
          pmi: undefined,
          mi: { label: 'PMI', readonly: false, required: false }
        }))
  ),
  (showMI, principalAndInterest, validateForm, { insurance, propertyTax, pmi, mi }) => ({
    monthlyInsurance: insurance,
    monthlyTaxes: propertyTax,
    mi: showMI
      ? {
          value: pmi !== undefined ? pmi : mi?.value,
          error: validateForm && pmi === undefined && mi && mi.value === undefined,
          label: mi?.label ?? 'PMI',
          readonly: mi?.readonly ?? false
        }
      : undefined,
    principalAndInterest
  })
);

export const offerDetailsValidFormSelector = createSelector(
  offerDetailsRateInfoSelector,
  validFeesSelector,
  offerDetailsProductSelector,
  monthlyPaymentSelector,
  showPMISelector,
  (
    { rate, rateLockDays, armCaps, margin, indexType, price },
    validFees,
    product,
    { mi },
    showMI
  ) => {
    const validRate = rate.value != null;
    const validRateLockDays =
      rateLockDays?.manualValue !== undefined &&
      validComputed(rateLockDays?.manualValue, rateLockDays?.computedValue);
    const validMI = !showMI || validPositiveNumber(mi?.value);
    const validPrice = !!price?.value;
    let validAdjustable = true;
    if ('adjustmentPeriod' in product) {
      const validMargin =
        margin?.manualValue !== undefined &&
        validComputed(margin?.manualValue, margin?.computedValue);
      const validIndexType = validComputed(indexType?.manualValue, indexType?.computedValue);
      const validArmCaps = validComputed(armCaps.manualValue, armCaps.computedValue);
      validAdjustable = validMargin && validIndexType && validArmCaps;
    }
    return validRate && validRateLockDays && validFees && validMI && validAdjustable && validPrice;
  }
);

// Engine Product Details selectors
export const engineProductDetailsSelector = createSelector(
  modalOfferSelector,
  ({ offerDetails: { productDetailsStatus } }: ApplicationState) => productDetailsStatus,
  (modalOffer, productDetailsStatus) => ({
    loading: productDetailsStatus === 'loading',
    error: productDetailsStatus === 'error',
    ...of(modalOffer)
      .map(({ notesAndAdvisories, adjustments }) => ({
        notesAndAdvisories: notesAndAdvisories || [],
        adjustments: adjustments || []
      }))
      .orElse(() => ({ notesAndAdvisories: [], adjustments: [] }))
  })
);
