import { styled } from 'baseui';
import { ChevronDown } from 'baseui/icon';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { Display } from 'baseui/typography';
import * as React from 'react';
import { ProductSet } from '../../../modules/rate-quote/types';
import { SecondaryButton } from '../../buttons';

const OfferButton = styled(SecondaryButton, () => ({
  display: 'table-cell',
  minWidth: '129px'
}));

interface OfferOption {
  value: string;
  label: string;
}

interface CreateNewOfferButtonProps {
  openOfferDetails: (payload: { lenderId: string; productSet: ProductSet }) => void;
  data: ProductSet;
}

export const CreateNewOfferButton = ({ openOfferDetails, data }: CreateNewOfferButtonProps) => (
  <StatefulPopover
    showArrow
    placement="bottom"
    accessibilityType={'tooltip'}
    content={({ close }) => (
      <StatefulMenu
        overrides={{
          List: {
            style: {
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              paddingLeft: '5px',
              paddingRight: '5px',
              paddingTop: '5px',
              paddingBottom: '5px',
              backgroundColor: 'white'
            }
          },
          Option: {
            style: ({ $theme, $disabled }) => ({
              ...($disabled && {
                borderBottom: `1px solid ${$theme.colors!.mono300}`,
                fontWeight: 'bold'
              }),
              cursor: 'pointer',
              color: $theme.colors.primary200
            })
          }
        }}
        onItemSelect={({ item }: { item: OfferOption }) => {
          openOfferDetails({ lenderId: item.value, productSet: data });
          close();
        }}
        items={[
          // Add title value to always appear first
          { value: null, label: 'Please select a lender to quote:', disabled: true },
          ...Object.values(data.lenders).reduce(
            (acc, { id, name }) => [...acc, { value: id, label: name }],
            [] as OfferOption[]
          )
        ]}
      />
    )}
  >
    <span>
      <OfferButton backgroundColor="white">
        <Display color="primary200" font="font250" display="flex">
          <span style={{ width: '80px', marginTop: '2px', fontSize: '12px' }}>Add an offer</span>
          <ChevronDown size={24} />
        </Display>
      </OfferButton>
    </span>
  </StatefulPopover>
);
