import { styled } from 'baseui';
import { Label1 } from 'baseui/typography';
import React from 'react';
import { ErrorIcon } from '../icons';

export type NoticeType = 'error' | 'notice' | 'info' | 'success';

const color = (type: NoticeType) => {
  switch (type) {
    case 'notice':
      return 'notificationWarningText';
    case 'info':
      return 'mono900';
    case 'success':
      return 'primary400';
    default:
      return 'notificationNegativeText';
  }
};

const NoticeBody = ({ type, children }: React.PropsWithChildren<{ type: NoticeType }>) => {
  let backgroundColor:
    | 'notificationNegativeBackground'
    | 'notificationWarningBackground'
    | 'mono300'
    | 'notificationPositiveBackground' = 'notificationNegativeBackground';
  switch (type) {
    case 'notice':
      backgroundColor = 'notificationWarningBackground';
      break;
    case 'info':
      backgroundColor = 'mono300';
      break;
    case 'success':
      backgroundColor = 'notificationPositiveBackground';
      break;
  }

  const StyledNotice = styled('div', ({ $theme }) => ({
    backgroundColor: $theme.colors![backgroundColor],
    width: '99%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    borderRadius: '2px',
    border: `1px solid ${$theme.colors![color(type)]}`
  }));

  return <StyledNotice>{children}</StyledNotice>;
};

const Label = ({ type, children }: React.PropsWithChildren<{ type: NoticeType }>) => {
  const LabelBody = styled(Label1, ({ $theme }) => ({
    color: `${$theme.colors![color(type)]} !important`,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px'
  }));

  return <LabelBody>{children}</LabelBody>;
};

const IconWrapper = styled('span', {
  marginTop: '5px',
  marginRight: '15px',
  backgroundColor: 'inherit!',
  color: 'inherit!'
});

interface NoticeProps {
  message: string | React.ReactElement;
  type?: NoticeType;
  icon?: React.ReactElement;
}

export const Notice = ({ message, type = 'error', icon = <ErrorIcon /> }: NoticeProps) => {
  return (
    <NoticeBody type={type}>
      <Label type={type}>
        <IconWrapper>{icon}</IconWrapper>
        {message}
      </Label>
    </NoticeBody>
  );
};

export const Bold = styled('span', ({ $theme }) => ({
  ...$theme.typography.font150,
  fontSize: '18px'
}));

export const BoldTextNotice = ({
  boldText,
  body,
  type
}: {
  boldText: string;
  body?: string | JSX.Element;
  type: NoticeType;
}) => (
  <Notice
    message={
      <div>
        <Bold>{boldText}</Bold>
        {body}
      </div>
    }
    type={type}
  />
);
