import * as t from 'type-shift';

const visa = t.oneOf([
  'B-1',
  'B-2',
  'DV-1',
  'DV-2',
  'F-1',
  'F-2',
  'F-3',
  'GB',
  'GT',
  'H-1A',
  'H-1B',
  'H-1B1',
  'H-1C',
  'H-2A',
  'H-2B',
  'H-3',
  'H-4',
  'IR-1',
  'IR-2',
  'IR-3',
  'IR-4',
  'IR-5',
  'J-1',
  'J-2',
  'L-1',
  'L-2',
  'M-1',
  'M-2',
  'O-1',
  'O-2',
  'O-3',
  'Q-1',
  'R-1',
  'R-2',
  'TD',
  'TN',
  'V-1',
  'V-2',
  'V-3',
  'WB',
  'WT',
  'other'
]);

const citizenship = t.oneOf([
  'USCitizen',
  'USCitizenAbroad',
  'PermanentResidentAlien',
  'NonPermanentResidentAlien'
]);

export const borrowers = t.array(
  t.strict({
    citizenship,
    visaType: visa.or(t.null),
    expirationDate: t.number.or(t.null),
    ownedHomePreviously: t.boolean,
    soldLastHome: t.number.or(t.null)
  })
);

export type Borrowers = t.TypeOf<typeof borrowers>;
