import { currency } from '@rategravity/frontend/modules/numbers';
import { RateQuote } from '../rate-quote/types';

const propertyTypeDisplay = (
  type: RateQuote['rateQuoteRequest']['property']['propertyType']
): string => {
  switch (type) {
    case 'SingleFamily':
      return 'Single Family';
    case 'MultiFamily':
      return 'Multi Family';
    default:
      return type;
  }
};

const occupancyDisplay = (
  occupancy: RateQuote['rateQuoteRequest']['property']['occupancy']
): string => {
  switch (occupancy) {
    case 'PrimaryResidence':
      return 'Primary Residence';
    case 'SecondHome':
      return 'Second Home';
    default:
      return 'Investment Property';
  }
};

export const computeAssumptions = (rqr: RateQuote['rateQuoteRequest']) => {
  const {
    property: {
      street,
      unit,
      city,
      state,
      zip,
      county,
      propertyType,
      warrantable,
      numberOfFloors,
      numberOfUnits,
      propertyTax,
      condoFee,
      insurance,
      occupancy
    }
  } = rqr;

  let address = state;
  if (street) {
    address = `${street}${unit ? ` ${unit}` : ''}, ${city}, ${state}${zip ? ` ${zip}` : ''}`;
  } else if (city) {
    address = `${city}, ${state}`;
  }

  const isCondo = (propType: string) =>
    propType === 'Condo' ||
    propType === 'Condotel' ||
    propType === 'Coop' ||
    propType === 'PUD' ||
    propType === 'Townhome';

  let propertyValue = 0;

  switch (rqr.purpose) {
    case 'purchase':
      propertyValue = rqr.purchasePrice;
      break;
    case 'refinance':
      propertyValue = rqr.propertyValue;
      break;
  }

  return [
    ['Address', address],
    ['County', county],
    ['Type', propertyTypeDisplay(propertyType)],
    ...(isCondo(propertyType) ? [['Warrantable', !!warrantable ? 'True' : 'False']] : []),
    ...(propertyType === 'MultiFamily'
      ? [
          ['# of Floors', Number(numberOfFloors).toString()],
          ['# of Units', Number(numberOfUnits).toString()]
        ]
      : []),
    ['Occupancy', occupancyDisplay(occupancy)],
    ['Value', currency(propertyValue, 0)],
    ['RE Tax', currency(propertyTax, 0)],
    ['Insurance', currency(insurance, 0)],
    ['Condo/HOA', currency(condoFee, 0)]
  ];
};
