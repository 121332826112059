import * as t from 'type-shift';

export interface Deal {
  id: string;
  properties?: Record<string, string>;
}

const dealConverter = t.strict<Deal>({
  id: t.string,
  properties: t.optional(t.record(t.string))
});

export const dealsConverter = t.record(dealConverter);
