import React from 'react';

export const Document = () => (
  <svg width="18px" height="22px" viewBox="0 0 18 22" version="1.1">
    <title>icon_dealsummary</title>
    <desc>Created with Sketch.</desc>
    <g id="MVP-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="RQR-MVP-1"
        transform="translate(-521.000000, -379.000000)"
        fill="#143F3D"
        fillRule="nonzero"
        stroke="#143F3D"
      >
        <g id="Group-8" transform="translate(80.000000, 336.000000)">
          <g id="icon_dealsummary" transform="translate(442.000000, 44.000000)">
            <path
              d="M3.83333333,6.33333333 L7.16666667,6.33333333 C7.35066667,6.33333333 7.5,6.18433333 7.5,6 C7.5,5.81566667 7.35066667,5.66666667 7.16666667,5.66666667 L3.83333333,5.66666667 C3.64933333,5.66666667 3.5,5.81566667 3.5,6 C3.5,6.18433333 3.64933333,6.33333333 3.83333333,6.33333333 Z"
              id="Path"
            ></path>
            <path
              d="M12.1666667,10 L3.83333333,10 C3.64933333,10 3.5,10.149 3.5,10.3333333 C3.5,10.5176667 3.64933333,10.6666667 3.83333333,10.6666667 L12.1666667,10.6666667 C12.3506667,10.6666667 12.5,10.5176667 12.5,10.3333333 C12.5,10.149 12.3506667,10 12.1666667,10 Z"
              id="Path"
            ></path>
            <path
              d="M12.1666667,14.3333333 L3.83333333,14.3333333 C3.64933333,14.3333333 3.5,14.4823333 3.5,14.6666667 C3.5,14.851 3.64933333,15 3.83333333,15 L12.1666667,15 C12.3506667,15 12.5,14.851 12.5,14.6666667 C12.5,14.4823333 12.3506667,14.3333333 12.1666667,14.3333333 Z"
              id="Path"
            ></path>
            <path
              d="M10.9713333,0 L0.166666667,0 L0.166666667,20 L15.8333333,20 L15.8333333,4.862 L10.9713333,0 Z M11.1666667,1.138 L14.6953333,4.66666667 L11.1666667,4.66666667 L11.1666667,1.138 Z M0.833333333,19.3333333 L0.833333333,0.666666667 L10.5,0.666666667 L10.5,5.33333333 L15.1666667,5.33333333 L15.1666667,19.3333333 L0.833333333,19.3333333 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
