import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { generateDTIStatus } from '../modules/offer-table/generate-dti-status';
import { AllQuotesOffer } from '../modules/rate-quote/types';
import { ApplicationState } from '../redux';

export const transformQuotes = (
  {
    productDetails: { rate, manualRateLockDays },
    computedProductDetails: { dti },
    monthlyPayment,
    engineDetails,
    attributes: { closingCost, loanSize, product, price, conforming, loanType },
    ...rest
  }: AllQuotesOffer,
  offerIds: string[]
) => ({
  lenderCreditOrPoints: ((100 - price) / 100) * loanSize, // will be negative for LC and positive for points
  rate,
  dti,
  rateLockPeriod: manualRateLockDays,
  closingCosts: closingCost,
  totalLoanAmount: loanSize,
  dtiStatus: generateDTIStatus({ dti, loanType, conforming }),
  product,
  price,
  published: offerIds.includes(rest.offerId),
  ...monthlyPayment,
  ...engineDetails,
  ...rest
});

const allQuotesStateSelector = createSelector(
  ({ allQuotes }: ApplicationState) => allQuotes,
  (allQuotes) => of(allQuotes)
);

export const allQuotesTableSelector = createSelector(
  allQuotesStateSelector,
  (
    allQuotes
  ): {
    loading: boolean;
    error: string | null;
    includeOfferLoading: string | null;
    isSameOfferModalOpen: boolean;
  } =>
    allQuotes
      .map(({ loading, error, includeOfferLoading, isSameOfferModalOpen }) => {
        const loadingOrError = !!loading || !!error;
        return {
          loading,
          error,
          includeOfferLoading: loadingOrError ? null : includeOfferLoading,
          isSameOfferModalOpen: loadingOrError ? false : isSameOfferModalOpen
        };
      })
      .orElse(() => ({
        loading: true,
        error: 'Error fetching from redux',
        includeOfferLoading: null,
        isSameOfferModalOpen: false
      }))
);

export const allQuotesSelector = createSelector(allQuotesStateSelector, (allQuotes) =>
  allQuotes.map(({ rates }) => rates).orElse(() => ({}))
);

export const allQuotesOfferPermutationsSelector = createSelector(
  allQuotesStateSelector,
  (allQuotes) => allQuotes.map(({ offerPermutations }) => offerPermutations).orElse(() => [])
);
