import { styled } from 'baseui';
import { Theme } from 'baseui/theme';
import { StatefulTooltip } from 'baseui/tooltip';
import React from 'react';

const NotificationBox = styled<
  {
    color: keyof Theme['colors'];
    pointer?: boolean;
  },
  'span'
>('span', ({ color, pointer = false, $theme }) => ({
  textAlign: 'center',
  fontSize: '12px',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: '1ex',
  paddingRight: '1ex',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: '1ex',
  borderColor: $theme.colors[color],
  color: $theme.colors[color],
  cursor: pointer ? 'pointer' : 'default'
}));

const TooltipContent = styled('div', {
  width: '400px',
  fontSize: '12px',
  lineHeight: '30px',
  fontFamily: 'ag'
});

const NotificationContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '5px'
});

export interface NotificationProps extends React.PropsWithChildren<{}> {
  expired: boolean;
  dtiStatus: string;
  method?: string;
  published?: boolean;
}

export const NotificationGroup = ({ method, expired, dtiStatus, published }: NotificationProps) => (
  <NotificationContainer>
    {expired && <NotificationBox color="notificationNegativeText">Expired</NotificationBox>}
    {!!method && <NotificationBox color="primary50">{method}</NotificationBox>}
    {!!dtiStatus && (
      <StatefulTooltip
        content={() => <TooltipContent>{dtiStatus}</TooltipContent>}
        showArrow={true}
      >
        <NotificationBox pointer={true} color="notificationWarningText">
          DTI
        </NotificationBox>
      </StatefulTooltip>
    )}
    {!!published && <NotificationBox color="notificationPositiveText">Published</NotificationBox>}
  </NotificationContainer>
);
