import { styled } from 'baseui';
import React from 'react';
import { PrimaryButton } from '../buttons';
import { Modal } from '../modal';

interface Props {
  isOpen: boolean;
  title?: string;
  message: string;
  onClose: () => void;
}

const ModalWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '80%'
});

const ButtonContainer = styled('div', {
  alignSelf: 'end'
});

const TextContainer = styled('div', {
  fontSize: '20px',
  alignSelf: 'center'
});

export const ErrorModal = ({ isOpen, title = 'Error', message, onClose }: Props) => (
  <Modal layout="Centered" isOpen={isOpen} title={title} onClose={onClose}>
    <ModalWrapper>
      <TextContainer style={{ fontSize: '20px' }}>{message}</TextContainer>
      <ButtonContainer>
        <PrimaryButton onClick={onClose}>Okay</PrimaryButton>
      </ButtonContainer>
    </ModalWrapper>
  </Modal>
);
