import { useStyletron } from 'baseui';
import { Tabs as MotionTabs, Tab } from 'baseui-v10/tabs-motion';
import React, { useState } from 'react';

export const Tabs = ({
  tabs,
  defaultValue
}: {
  tabs: { label: string; value: string; children: JSX.Element }[];
  defaultValue: string; // should be one of the tabs `value`
}) => {
  const [activeKey, setActiveKey] = useState(defaultValue);
  const [, theme] = useStyletron();
  return (
    <MotionTabs
      onChange={({ activeKey: newActiveKey }) => {
        setActiveKey(newActiveKey as string);
      }}
      activeKey={activeKey}
      overrides={{
        TabList: {
          style: {
            backgroundColor: 'transparent',
            paddingLeft: 0
          }
        },
        TabBorder: {
          style: {
            width: 'fit-content'
          }
        },
        TabHighlight: {
          style: {
            backgroundColor: theme.colors.primary400,
            opacity: 0.7,
            height: '3px'
          }
        }
      }}
    >
      {tabs.map(({ label, value, children }) => (
        <Tab
          key={value}
          title={label}
          overrides={{
            Tab: {
              style: {
                backgroundColor: 'transparent !important',
                color: theme.colors.primary400
              }
            }
          }}
        >
          {children}
        </Tab>
      ))}
    </MotionTabs>
  );
};
