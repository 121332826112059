import { isDate } from "util";

type Granularity = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks';

const units: Record<Granularity, number> = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  weeks: 1000 * 60 * 60 * 24 * 7
};

/**
 * Returns the difference between two dates in the given `granularity` unit
 *   inclusive of the current unit (i.e. 12/01 - 12/30 will be 30 days.)
 *
 * @param from First date
 * @param to Second date.
 * @param granularity Units to return. Defaults to days.
 */
export const dateDifference = (
  from: Date | number,
  to: Date | number,
  granularity: Granularity = 'days'
): number => {
  const startEpoch = isDate(from) ? from.getTime() : from;
  const endEpoch = isDate(to) ? to.getTime() : to;

  const diff = Math.ceil((endEpoch - startEpoch) / units[granularity]);

  return diff + (diff < 0 ? 0 : 1);
};

/**
 * Returns the difference between two dates in the given `granularity` unit
 *   inclusive of the current unit (i.e. 12/01 - 12/30 will be 30 days.)
 *
 * Will append the `granularity` unit to the end, and ' ago' if it's a
 *   negative number.
 *
 * @param from First date
 * @param to Second date.
 * @param granularity Units to return. Defaults to days.
 */
export const dateDifferenceString = (
  from: Date | number,
  to: Date | number,
  granularity: Granularity = 'days'
): string => {
  const difference = dateDifference(from, to, granularity);
  const absDifference = Math.abs(difference);
  return `${absDifference} ${
    absDifference === 1 ? granularity.substr(0, granularity.length - 1) : granularity
  }${difference < 0 ? ' ago' : ''}`;
};
