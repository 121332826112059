import querystring from 'querystring';
import * as t from 'type-shift';
import { loggedInFetch } from './authentication';
import { standardizeResponse } from './standardize';

export interface FindLockBySourceResponse {
  hasLock: boolean;
  rateQuoteId?: string;
}

export const findLockBySource = async (sourceId: string) =>
  standardizeResponse(
    await loggedInFetch(
      `/api/rate-quotes/find-lock-by-source/?${querystring.stringify({ sourceId })}`,
      {
        method: 'GET',
        headers: {
          ['Content-Type']: 'application/json'
        }
      }
    ),
    t.shape<FindLockBySourceResponse>({
      hasLock: t.boolean,
      rateQuoteId: t.optional(t.string)
    })
  );
