import { all, call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { deleteLock, editLock, getLockData, lockOffer } from '../../modules/lock-offer';
import { setLockedOffer } from '../rate-quote/actions';
import {
  CLOSE_LOCK_MODAL_ACTION_TYPE,
  closeLockModal,
  DELETE_LOCK_ACTION_TYPE,
  DeleteLockAction,
  deleteLockFailure,
  deleteLockSuccess,
  EDIT_LOCK_ACTION_TYPE,
  EditLockAction,
  GET_LOCK_ACTION_TYPE,
  GetLockAction,
  getLockFailure,
  getLockRequest,
  getLockSuccess,
  LOCK_OFFER_ACTION_TYPE,
  LockOfferAction,
  lockOfferFailure,
  lockOfferSuccess
} from './actions';

export function* handleLockOffer({ payload }: LockOfferAction) {
  try {
    const { success, json, error } = yield call(lockOffer, payload);
    if (success) {
      yield put(lockOfferSuccess(json!));
      yield put(setLockedOffer(payload.offerId, true));
      yield put(getLockRequest({ rateQuoteId: payload.rateQuoteId, offer: payload.offer }));
    } else {
      yield put(lockOfferFailure(error || 'error'));
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(lockOfferFailure(error));
  }
}

export function* handleGetLock({ payload: { rateQuoteId, offer } }: GetLockAction) {
  yield race([
    // If the modal is closed shutdown this whole saga, we don't need the data.
    take(CLOSE_LOCK_MODAL_ACTION_TYPE),
    // poll the getLockData endpoint till a non-pending
    // result is returned from the data.
    call(function* () {
      try {
        while (true) {
          const { success, json, error } = yield call(getLockData, {
            rateQuoteId,
            offerId: offer.offerId
          });
          if (success) {
            yield put(getLockSuccess(json));
            // poll
            if (!json.pending) {
              break;
            }
            // wait a second before re-polling;
            yield delay(1000);
          } else {
            yield put(getLockFailure(error || 'error'));
            break;
          }
        }
      } catch (err) {
        const error = err instanceof Error ? err.message : JSON.stringify(err);
        yield put(getLockFailure(error));
      }
    })
  ]);
}

export function* handleDeleteLock({ payload }: DeleteLockAction) {
  try {
    const { success, error } = yield call(deleteLock, payload);
    if (success) {
      yield put(deleteLockSuccess());
      yield put(setLockedOffer(payload.offerId, false));
      yield put(closeLockModal());
    } else {
      yield put(deleteLockFailure(error!));
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(deleteLockFailure(error));
  }
}

export function* handleEditLock({ payload }: EditLockAction) {
  try {
    const { success, json, error } = yield call(editLock, payload);
    if (success) {
      yield put(lockOfferSuccess(json!));
      yield put(getLockRequest({ rateQuoteId: payload.rateQuoteId, offer: payload.offer }));
    } else {
      yield put(lockOfferFailure(error || 'error'));
    }
  } catch (err) {
    const error = err instanceof Error ? err.message : JSON.stringify(err);
    yield put(lockOfferFailure(error));
  }
}

export const lockModalSaga = function* () {
  yield all([
    takeEvery(LOCK_OFFER_ACTION_TYPE, handleLockOffer),
    takeEvery(GET_LOCK_ACTION_TYPE, handleGetLock),
    takeEvery(DELETE_LOCK_ACTION_TYPE, handleDeleteLock),
    takeEvery(EDIT_LOCK_ACTION_TYPE, handleEditLock)
  ]);
};
