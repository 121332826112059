import {
  ToasterContainer as BaseToasterContainer,
  PLACEMENT,
  toaster,
  ToasterProps
} from 'baseui/toast';
import React from 'react';

export const ToasterContainer: React.FC<ToasterProps> = (props) => {
  return <BaseToasterContainer {...props} placement={PLACEMENT.bottom} autoHideDuration={3000} />;
};

export const toasterPositive = (children: React.ReactChildren | string) =>
  toaster.positive(children, {
    closeable: true,
    overrides: {
      Body: {
        style: ({ $theme }) => ({
          backgroundColor: $theme.colors.notificationPositiveBackground,
          color: $theme.colors.primary400
        })
      }
    }
  });
