import { currency } from '@rategravity/frontend/modules/numbers';
import { styled, withStyle } from 'baseui';
import { Card } from 'baseui/card';
import { ModalBody } from 'baseui/modal';
import React from 'react';
import { LabelWithSublabel } from '../../labels';
import { Modal } from '../../modal';
import { MonthlyPaymentCard } from '../card/monthly-payment-card';
import { NotesAdvisoriesAdjustmentsCard } from '../card/notes-advisories-adjustments';
import { OfferDetailsRateInfo, RateInfoCard } from '../card/rate-info-card';
import { cardOverrides, CardText } from '../card/shared';
import { AttributesPanel, FullAttributes } from './attributes-panel';

const Flex = withStyle(ModalBody, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '10px',
  marginRight: '10px'
});

const CardContainer = styled('div', {
  width: '100%',
  overflow: 'hidden'
});

const CardTitle = ({ title, feeTotal }: { title: string; feeTotal: number }) => (
  <LabelWithSublabel title={title} subtitle={currency(feeTotal, 0)} />
);

const ClosingCostsCard = ({ feeTotal, title }: { feeTotal: number; title: string }) => (
  <Card
    overrides={{
      Title: {
        component: CardTitle,
        props: { title, feeTotal }
      },
      ...cardOverrides
    }}
    title
  >
    <div style={{ paddingTop: '24px' }}>
      <CardText>Include this offer to see individual fees.</CardText>
    </div>
  </Card>
);

type MonthlyPaymentData = {
  principalAndInterest: number;
  monthlyInsurance: number;
  monthlyTaxes: number;
};

export const AllQuotesDetailsModal = ({
  isOpen,
  loading,
  onClose,
  fullAttributes,
  productDetails,
  notesAndAdvisories,
  feeTotal,
  monthlyPaymentData
}: {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  fullAttributes: FullAttributes;
  productDetails: OfferDetailsRateInfo;
  notesAndAdvisories: Parameters<typeof NotesAdvisoriesAdjustmentsCard>[0];
  feeTotal: number;
  monthlyPaymentData: MonthlyPaymentData;
}) => (
  <Modal
    isOpen={isOpen}
    title="Offer Details"
    loading={loading}
    onClose={onClose}
    layout={'Regular'}
  >
    <Flex>
      <AttributesPanel fullAttributes={fullAttributes} />
      <CardContainer>
        <RateInfoCard isFixed={fullAttributes.product.rate === 'fixed'} data={productDetails} />
        <NotesAdvisoriesAdjustmentsCard {...notesAndAdvisories} />
        <MonthlyPaymentCard data={monthlyPaymentData} />
        <ClosingCostsCard feeTotal={feeTotal} title="Closing costs" />
      </CardContainer>
    </Flex>
  </Modal>
);
