import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SavePrompt as SavePromptComponent,
  SavePromptDispatch,
  SavePromptParams
} from '../components/save-prompt';
import { ApplicationState } from '../redux';
import { saveMerchandising } from '../redux/rate-quote/actions';

export const SavePrompt = (params: Partial<SavePromptParams>) => {
  const fullParams = {
    ...params,
    saveQueued: useSelector(({ rateQuote: { saveQueued } }: ApplicationState) => saveQueued)
  } as SavePromptParams;

  const dispatch = useDispatch();

  const dispatchParams = {
    forceSave: () => dispatch(saveMerchandising(true))
  } as SavePromptDispatch;

  return <SavePromptComponent {...fullParams} {...dispatchParams} />;
};
