import { History } from 'history';
import { RateQuote } from '../../../modules/rate-quote/types';
import { LockedRateQuoteFailedStatus, LockedRateQuoteSuccessStatus } from '../state';

export const FETCH_RATE_QUOTE_ACTION_TYPE = 'RATE_QUOTE__FETCH_REQUEST_ACTION' as const;
export const fetchRateQuoteRequest = (rateQuoteId: string) => ({
  type: FETCH_RATE_QUOTE_ACTION_TYPE,
  payload: { rateQuoteId }
});
export type FetchRateQuoteRequestAction = ReturnType<typeof fetchRateQuoteRequest>;

export const FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE =
  'RATE_QUOTE__FETCH_RESPONSE_SUCCESS_ACTION' as const;
export const fetchRateQuoteSuccess = (payload: RateQuote) => ({
  type: FETCH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  payload
});
export type FetchRateQuoteSuccessAction = ReturnType<typeof fetchRateQuoteSuccess>;

export const FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE =
  'RATE_QUOTE__FETCH_RESPONSE_FAILURE_ACTION' as const;
export const fetchRateQuoteError = (message: string, code: number) => ({
  type: FETCH_RATE_QUOTE_FAILURE_ACTION_TYPE,
  payload: {
    message,
    code
  }
});
export type FetchRateQuoteFailureAction = ReturnType<typeof fetchRateQuoteError>;

export const SAVE_MERCHANDISING_ACTION_TYPE = 'RATE_QUOTE__SAVE_MERCHANDISING_ACTION' as const;
/**
 * Trigger a save either immediately or after a timeout of no save
 *  actions being called for a set period.
 *
 * @param saveImmediately set to false to trigger the timeout
 */
export const saveMerchandising = (saveImmediately: boolean) => ({
  type: SAVE_MERCHANDISING_ACTION_TYPE,
  payload: saveImmediately
});
export type SaveMerchandisingAction = ReturnType<typeof saveMerchandising>;

export const SAVE_MERCHANDISING_SUCCESS_ACTION_TYPE =
  'RATE_QUOTE__SAVE_MERCHANDISING_SUCCESS' as const;
export const saveMerchandisingSuccess = (payload: RateQuote) => ({
  type: SAVE_MERCHANDISING_SUCCESS_ACTION_TYPE,
  payload
});
export type SaveMerchandisingSuccessAction = ReturnType<typeof saveMerchandisingSuccess>;

export const SAVE_MERCHANDISING_ERROR_ACTION_TYPE = 'RATE_QUOTE__SAVE_MERCHANDISING_ERROR' as const;
export const saveMerchandisingError = (payload: string) => ({
  type: SAVE_MERCHANDISING_ERROR_ACTION_TYPE,
  payload
});
export type SaveMerchandisingErrorAction = ReturnType<typeof saveMerchandisingError>;

export const FETCH_LOCKED_OFFER_BY_SOURCE_ACTION_TYPE =
  'RATE_QUOTE_FETCH_LOCKED_OFFER_BY_SOURCE' as const;
export const fetchLockedOfferBySource = (payload: { sourceId: string; history: History<any> }) => ({
  type: FETCH_LOCKED_OFFER_BY_SOURCE_ACTION_TYPE,
  payload
});
export type FetchLockedOfferBySourceAction = ReturnType<typeof fetchLockedOfferBySource>;

export const FETCH_LOCKED_OFFER_BY_SOURCE_SUCCESS_ACTION_TYPE =
  'RATE_QUOTE_FETCH_LOCKED_OFFER_BY_SOURCE_SUCCESS' as const;
export const fetchLockedOfferBySourceSuccess = (payload: LockedRateQuoteSuccessStatus) => ({
  type: FETCH_LOCKED_OFFER_BY_SOURCE_SUCCESS_ACTION_TYPE,
  payload
});
export type FetchLockedOfferBySourceSuccessAction = ReturnType<
  typeof fetchLockedOfferBySourceSuccess
>;

export const FETCH_LOCKED_OFFER_BY_SOURCE_FAILURE_ACTION_TYPE =
  'RATE_QUOTE_FETCH_LOCKED_OFFER_BY_SOURCE_FAILURE' as const;
export const fetchLockedOfferBySourceFailure = (payload: LockedRateQuoteFailedStatus) => ({
  type: FETCH_LOCKED_OFFER_BY_SOURCE_FAILURE_ACTION_TYPE,
  payload
});
export type FetchLockedOfferBySourceFailureAction = ReturnType<
  typeof fetchLockedOfferBySourceFailure
>;
