import { none, Optional, some } from '@rategravity/core-lib/optional';
import { isDate } from 'util';
import { beginTransaction, LocalStoreType } from './stores';
export { LocalStoreType } from './stores';

interface CacheItem<T> {
  item: T;
  timestamp: number;
}

/**
 * Attempts to store the given `key`/`value` pair in the IndexedDB Store Area dictated
 *   by the `storeType` parameter. Will also append a `timestamp` to the item, defaulting
 *   to the current time if none is specified.
 *
 * @param key Key to store value at.
 * @param value Object to store in the database.
 * @param storeType `LocalStoreType` defining the IndexedDB Store to be used.
 * @param timestamp Time signature for this item.
 */
export const storeItem = async <T>(
  key: string,
  value: T,
  storeType: LocalStoreType,
  timestamp: Date | number = Date.now()
): Promise<void> => {
  const cacheItem = {
    item: value,
    timestamp: isDate(timestamp) ? timestamp.getTime() : timestamp
  } as CacheItem<T>;
  const transaction = await beginTransaction();
  await transaction.put(storeType, cacheItem, key);
};

/**
 * Attempts to fetch the value of the given `key` in the IndexedDB Store Area dictated
 *   by the `storeType` parameter. Will return the item and the timestamp assigned to
 *   this value when it was stored.
 *
 * @param key Key to fetch value for.
 * @param storeType `LocalStoreType` defining the IndexedDB Store to be used.
 */
export const fetchItem = async <T>(
  key: string,
  storeType: LocalStoreType
): Promise<Optional<CacheItem<T>>> => {
  const transaction = await beginTransaction();
  const fetched = await transaction.get(storeType, key);
  if (fetched !== undefined) {
    return some(fetched as CacheItem<T>);
  }
  return none();
};

/**
 * Attempts to delete the value of the given `key` in the IndexedDB Store Area dictated
 *   by the `storeType` parameter.
 *
 * @param key Key to delete value for.
 * @param storeType `LocalStoreType` defining the IndexedDB Store to be used.
 */
export const deleteItem = async (key: string, storeType: LocalStoreType): Promise<void> => {
  const transaction = await beginTransaction();
  await transaction.delete(storeType, key);
};

/**
 * Attempts to remove all `key`/`value` pairs from the IndexedDB Store Area dictated
 *   by the `storeType` parameter.
 *
 * @param storeType `LocalStoreType` defining the IndexedDB Store to be used.
 */
export const clearItems = async (storeType: LocalStoreType): Promise<void> => {
  const transaction = await beginTransaction();
  await transaction.clear(storeType);
};

/**
 * Attempts to remove all items from all `LocalStoreType` databases.
 */
export const clearDatabase = async () =>
  Promise.all(Object.values(LocalStoreType).map((type) => clearItems(type)));

/**
 * Compares an item to its cached equivalent, returning [`cached`, `true`] if
 *   the cached one wins and [`fetched`, `false`] if the fetched one wins.
 *
 * @param fetched Fetched item
 * @param timestamp Timestamp of the fetched item
 * @param key Key of the cached equivalent
 * @param storeType Store where this item would be located.
 */
export const compete = async <T>(
  fetched: T,
  timestamp: Date | number,
  key: string,
  storeType: LocalStoreType
): Promise<[T, boolean]> => {
  const cached = await fetchItem<T>(key, storeType);
  const currentTimestamp = typeof timestamp === 'number' ? timestamp : timestamp.getTime();
  const pickCached = cached.map((item) => item.timestamp > currentTimestamp).orElse(() => false);
  return [pickCached ? cached.value!.item : fetched, pickCached];
};
