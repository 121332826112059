/**
 * Move the item in `arr` at index `from` to index `to`.
 *
 * Does this by removing and re-inserting at the location. It
 *   does NOT swap the two objects.
 *
 * @param arr Array to perform move on.
 * @param from Index of item to move.
 * @param to Location to put item.
 */
export const arrayMove = <T>(arr: T[], from: number, to: number): T[] => {
  if (from !== to) {
    if (from < 0) {
      throw new Error(`Index ${from} out of range!`);
    } else if (from < arr.length) {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
    }
  }
  return arr;
};
