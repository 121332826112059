import React from 'react';
import { Header } from '../components/headers';
import { Tabs } from '../components/tabs';
import { AllQuotes } from './all-quotes';
import { OfferTable } from './offer-table';

export const RateQuotePageTabs = () => (
  <div>
    <Header>Offers</Header>
    <Tabs
      tabs={[
        { label: 'Publishable', value: 'Publishable', children: <OfferTable /> },
        { value: 'AllQuotes', label: 'All', children: <AllQuotes /> }
      ]}
      defaultValue="Publishable"
    />
  </div>
);
