import React from 'react';

export const Star = () => (
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon / star</title>
    <desc>Created with Sketch.</desc>
    <g
      id="MVP-2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g id="RQR-MVP-12" transform="translate(-1317.000000, -1522.000000)" stroke="#005450">
        <g id="Group-11" transform="translate(1224.000000, 1488.000000)">
          <g id="icon-/-star" transform="translate(93.000000, 33.000000)">
            <polygon
              id="Star"
              points="9.5 15.25 3.9160401 18.1856614 4.98248155 11.9678307 0.464963095 7.56433855 6.70802005 6.65716928 9.5 1 12.2919799 6.65716928 18.5350369 7.56433855 14.0175185 11.9678307 15.0839599 18.1856614"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
