import React from 'react';

export const Lock = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0333 6.06667H3.38333V3.44167C3.38333 2.45 4.2 1.575 5.25 1.575C6.3 1.575 7.11667 2.39167 7.11667 3.44167V3.96667C7.11667 4.12363 7.04635 5.36605 7.11667 6.27762C7.2554 8.07624 7.62363 10.0437 7.93333 10.0437C8.4 10.0437 8.75 6.86956 8.75 6.40289V3.44167C8.75 1.575 7.23333 0 5.30833 0C3.38333 0 1.86667 1.51667 1.86667 3.44167V6.06667H0.525C0.233333 6.06667 0 6.3 0 6.59167V13.475C0 13.7667 0.233333 14 0.525 14H10.0333C10.325 14 10.5583 13.7667 10.5583 13.475V6.59167C10.5583 6.3 10.325 6.06667 10.0333 6.06667Z"
      fill="#143F3D"
    />
  </svg>
);
