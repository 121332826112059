import { LoanType } from '../value-types/loan-type';

export const generateDTIStatus = ({
  dti,
  conforming,
  loanType
}: {
  dti: number;
  conforming: boolean;
  loanType: LoanType;
}) => {
  const formatedDTI = `${(dti * 100).toFixed(2)}%`;
  if (dti >= 0.43 && !conforming) {
    return `The DTI threshold for Jumbo loans is 43%. The current DTI is ${formatedDTI}.  \n
           Before publishing any rates, please work with your borrower to lower their DTI \n 
           or let them know that they\'re unqualified.`;
  } else if (dti >= 0.499 && conforming && (loanType === 'VA' || loanType === 'Standard')) {
    return `The DTI threshold for Conforming and VA loans is 49.9%. The current DTI is ${formatedDTI}. \n
            Before publishing any rates, please work with your borrower to lower their DTI or let them \n 
            know that they\'re unqualified.`;
  } else if (dti >= 0.55 && loanType === 'FHA') {
    return `The DTI threshold for FHA loans is 55%. The current DTI is ${formatedDTI}. \n 
            Before publishing any rates, please work with your borrower to lower their DTI \n 
            or let them know that they\'re unqualified.`;
  }
  return '';
};
