import { Spinner as BaseSpinner } from 'baseui/spinner';
import * as React from 'react';
import { styled } from 'styletron-react';

export interface SpinnerProps {
  size?: number;
  centerVertically?: boolean;
}

export const Spinner = ({ size = 192, centerVertically = true }: SpinnerProps) => {
  const SpinnerContainer = styled('div', () => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    width: '100%',
    height: centerVertically ? '100vh' : 'inherit',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'z-index': 9999
    /* eslint-enable @typescript-eslint/naming-convention */
  }));

  return (
    <SpinnerContainer>
      <BaseSpinner size={size} />
    </SpinnerContainer>
  );
};
