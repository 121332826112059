import { Block } from 'baseui/block';
import React from 'react';

export const Container = ({ children }: React.PropsWithChildren<{}>) => (
  <Block
    // Different widths for screen sizes
    //     xsmall   small     med     large
    width={['98%', '95%', '90%', `1280px`]}
    margin="auto"
    display={'flex'}
    justifyContent={'space-between'}
  >
    {children}
  </Block>
);
