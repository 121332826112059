import { Fees, Offer, OfferAttributes } from '../../modules/rate-quote/types';

export type Nullable<T> =
  T extends Array<infer N>
    ? Array<{ [K in keyof N]: N[K] | null }>
    : { [K in keyof T]: T[K] | null };

export type Errorable<T> =
  T extends Array<infer N>
    ? Array<N & { error: boolean }>
    : { [K in keyof T]: { value: T[K]; error: boolean } };

export type Computable<T> =
  T extends Array<infer N>
    ? Array<N & { manualValue: T | undefined; computedValue: T | undefined; error: boolean }>
    : {
        [K in keyof T]: {
          manualValue: T[K] | undefined;
          computedValue: T[K] | undefined;
          error: boolean;
        };
      };

export type EditableFees = Array<
  Omit<Fees[0], 'manualValue'> & {
    manualValue: number | null | undefined;
  }
>;
export type EditableOffer = Omit<Offer, 'productDetails' | 'fees' | 'attributes'> & {
  productDetails: Nullable<Offer['productDetails']>;
  fees: EditableFees;
  attributes: OfferAttributes & { fullLender: Offer['lender'] };
};

export interface OfferDetailsState {
  modalOffer: EditableOffer | null;
  loading: boolean;
  networkError: string | null;
  validateForm: boolean;
  isOpen: boolean;
  isOpenAllQuotes: boolean;
  hasChanged: boolean;
  productDetailsStatus: 'loaded' | 'loading' | 'error';
}

export const defaultOfferDetailsState: OfferDetailsState = {
  modalOffer: null,
  loading: false,
  networkError: null,
  validateForm: false,
  isOpen: false,
  isOpenAllQuotes: false,
  hasChanged: false,
  productDetailsStatus: 'loaded'
};
