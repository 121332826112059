import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { RateQuotePanel as RateQuotePanelComponent } from '../components/rate-quote-panel';
import {
  purposeSelector,
  rateQuoteRequestFieldSelector,
  rateQuoteRequestIdSelector
} from '../selectors/rate-quote/rate-quote';
import { rateQuoteErrorSelector } from '../selectors/rate-quote/rate-quote';
import { rateQuoteLoadingSelector } from '../selectors/rate-quote/rate-quote-loading';
import { rateQuoteTitleSelector } from '../selectors/rate-quote/rate-quote-title';

export const RateQuotePanel = connect(
  createSelector(
    rateQuoteLoadingSelector,
    rateQuoteTitleSelector,
    rateQuoteErrorSelector,
    rateQuoteRequestFieldSelector,
    purposeSelector,
    rateQuoteRequestIdSelector,
    (loading, title, error, rqrFields, purpose, rqrId) => ({
      loading,
      title,
      error,
      rqrFields,
      purpose,
      rqrId
    })
  )
)(RateQuotePanelComponent);
