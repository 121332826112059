import { styled } from 'baseui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/buttons';
import { CheckBox, NumberInput } from '../../components/forms/input';
import { MultiSelect } from '../../components/forms/select/multi-select';
import { Label2 } from '../../components/labels';
import { getAllQuotes } from '../../redux/all-quotes/slice';
import { allQuotesFilterOptionsSelector } from '../../redux/all-quotes-filter-options/selector';
import { allQuotesTableSelector } from '../../selectors/all-quotes';
import { rateQuoteRequestIdSelector } from '../../selectors/rate-quote/rate-quote';

const Col = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});

const RangeContainer = styled(Col, {
  maxWidth: '240px'
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row'
});

const Divider = styled('span', {
  margin: '16px 4px 0'
});

const Range = ({
  label,
  low,
  setLow,
  high,
  setHigh,
  inputType
}: {
  label: string;
  low: number | null;
  setLow: (_: number | null) => void;
  high: number | null;
  setHigh: (_: number | null) => void;
  inputType?: string;
}) => (
  <RangeContainer>
    <Label2>{label}</Label2>
    <Row>
      <NumberInput
        label=""
        onChange={({ value }) => setLow(value || null)}
        value={low}
        inputType={inputType}
      />
      <Divider>-</Divider>
      <NumberInput
        label=""
        inputType={inputType}
        onChange={({ value }) => setHigh(value || null)}
        value={high}
      />
    </Row>
  </RangeContainer>
);

const FilterContainer = styled(Row, {
  width: '100%',
  gap: '24px',
  marginBottom: '16px'
});

const FiltersButton = styled(PrimaryButton, {
  margin: '16px 0 24px !important'
});

export const Filters = ({
  showBest,
  setShowBest
}: {
  showBest: boolean;
  setShowBest: (_: boolean) => void;
}) => {
  const allQuoteFilterOptions = useSelector(allQuotesFilterOptionsSelector);
  const [selectedInvestors, setSelectedInvestors] = useState<{ value: string }[]>([]);
  const [selectedLenders, setSelectedLenders] = useState<{ value: string }[]>([]);

  const [lockDaysLow, setLockDaysLow] = useState<number | null>(45);
  const [lockDaysHigh, setLockDaysHigh] = useState<number | null>(null);
  const [rateLow, setRateLow] = useState<number | null>(null);
  const [rateHigh, setRateHigh] = useState<number | null>(null);
  const [priceLow, setPriceLow] = useState<number | null>(99);
  const [priceHigh, setPriceHigh] = useState<number | null>(101);
  const rateQuoteRequestId = useSelector(rateQuoteRequestIdSelector);
  const { loading } = useSelector(allQuotesTableSelector);

  const dispatch = useDispatch();

  return (
    <Col>
      <FilterContainer>
        <MultiSelect
          options={allQuoteFilterOptions.lenders}
          labelKey="value"
          multi
          onChange={({ value }) => setSelectedLenders(value as Array<{ value: string }>)}
          value={selectedLenders}
          label="Lender"
          disabled={allQuoteFilterOptions.loading}
        />
        <MultiSelect
          options={allQuoteFilterOptions.investors}
          labelKey="value"
          multi
          onChange={({ value }) => setSelectedInvestors(value as Array<{ value: string }>)}
          value={selectedInvestors}
          label="Investor"
          disabled={allQuoteFilterOptions.loading}
        />
      </FilterContainer>
      <FilterContainer>
        <Range
          label="Lock range (days)"
          low={lockDaysLow}
          setLow={setLockDaysLow}
          high={lockDaysHigh}
          setHigh={setLockDaysHigh}
        />
        <Range
          label="Rate range (%)"
          low={rateLow}
          setLow={setRateLow}
          high={rateHigh}
          setHigh={setRateHigh}
          inputType="percent"
        />
        <Range
          label="Price range"
          low={priceLow}
          setLow={setPriceLow}
          high={priceHigh}
          setHigh={setPriceHigh}
        />
      </FilterContainer>
      <FiltersButton
        onClick={() => {
          dispatch(
            getAllQuotes({
              rateQuoteRequestId,
              filterOptions: {
                lender: selectedLenders.map(({ value }) => value),
                investor: selectedInvestors.map(({ value }) => value),
                price: { low: priceLow, high: priceHigh },
                rate: { low: rateLow, high: rateHigh },
                lockDays: { low: lockDaysLow, high: lockDaysHigh }
              }
            })
          );
        }}
        disabled={loading}
        width="Long"
      >
        Apply Filters
      </FiltersButton>
      <FilterContainer>
        <CheckBox checked={showBest} onChange={() => setShowBest(!showBest)}>
          Show only best offers
        </CheckBox>
      </FilterContainer>
    </Col>
  );
};
