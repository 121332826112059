import { Label1 as BaseLabel1, Label2 as BaseLabel2 } from 'baseui/typography';
import React from 'react';

export const Label1 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseLabel1 color="mono400" font="font200">
    {children}
  </BaseLabel1>
);

export const Label2 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseLabel2
    color="mono700"
    font="font300"
    overrides={{ Block: { style: { marginBottom: '5px' } } }}
  >
    {children}
  </BaseLabel2>
);

export const Label3 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseLabel2
    color="primary400"
    overrides={{
      Block: {
        style: {
          fontSize: '20px',
          fontWeight: 'bold'
        }
      }
    }}
  >
    {children}
  </BaseLabel2>
);

export const Label4 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseLabel1 overrides={{ Block: { style: { letterSpacing: '2px' } } }}>{children}</BaseLabel1>
);

interface LabelWithSublabelProps {
  title: string;
  subtitle: string;
}

export const LabelWithSublabel = ({ title, subtitle }: LabelWithSublabelProps) => (
  <div style={{ display: 'flex' }}>
    <Label3>{title}</Label3>
    <BaseLabel2
      color="mono300"
      overrides={{
        Block: {
          style: {
            fontSize: '20px',
            fontWeight: 100,
            paddingLeft: '15px'
          }
        }
      }}
    >
      {subtitle}
    </BaseLabel2>
  </div>
);

export const ErrorLabel2 = ({ children }: React.PropsWithChildren<{}>) => (
  <BaseLabel2
    color="negative400"
    font="font300"
    overrides={{ Block: { style: { marginBottom: '5px' } } }}
  >
    {children}
  </BaseLabel2>
);
