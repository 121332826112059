import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllQuotesDetailsModal } from '../components/offer-details/fee-modal/all-quotes-modal';
import { closeOfferDetails } from '../redux/offer-details/actions';
import {
  engineProductDetailsSelector,
  monthlyPaymentSelector,
  offerDetailsAttributesSelector,
  offerDetailsRateInfoSelector,
  offerDetailsUISelector
} from '../selectors/offer-details';

export const AllQuotesOfferDetailsModal = () => {
  const dispatch = useDispatch();
  const { isOpenAllQuotes, loading } = useSelector(offerDetailsUISelector);
  const notesAndAdvisories = useSelector(engineProductDetailsSelector);
  const onClose = () => dispatch(closeOfferDetails());
  const fullAttributes = useSelector(offerDetailsAttributesSelector);
  const productDetails = useSelector(offerDetailsRateInfoSelector);
  const monthlyPaymentData = useSelector(monthlyPaymentSelector);
  return (
    <AllQuotesDetailsModal
      isOpen={isOpenAllQuotes}
      loading={loading}
      onClose={onClose}
      fullAttributes={fullAttributes}
      productDetails={productDetails}
      notesAndAdvisories={notesAndAdvisories}
      feeTotal={fullAttributes.closingCost || 0}
      monthlyPaymentData={monthlyPaymentData}
    />
  );
};
