import { KIND, Notification } from 'baseui/notification';
import React from 'react';
import { OfferTableProps } from '.';

export const StatusNotification = ({ status }: Pick<OfferTableProps, 'status'>) => {
  let kind: KIND[keyof KIND] = KIND.negative;
  if (status === 'Complete') {
    kind = KIND.positive;
  } else if (status === 'Stale') {
    kind = KIND.warning;
  }
  return (
    <Notification
      overrides={{
        Body: {
          style: {
            minWidth: '35px',
            maxWidth: '70px',
            height: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            borderRadius: '3px'
          }
        }
      }}
      kind={kind}
    >
      {status}
    </Notification>
  );
};
