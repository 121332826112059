import { createLoggedInFetch, CreateLoggedInFetchOptions } from '../../create-logged-in-fetch';
import { INTERNAL_AUTH_TOKEN_LOCAL_KEY } from '../constants';

export interface CreateInternalAuthLoggedInFetchOptions
  extends Pick<CreateLoggedInFetchOptions, 'fetchFunction'> {}

/**
 * Wrap a fetch function to automatically add the Authorization header
 *   based on the JWT token in localStorage at {@see INTERNAL_AUTH_TOKEN_LOCAL_KEY}.
 *
 * Wraps {@see createLoggedInFetch}.
 *
 * @param options - {@see CreateInternalLoggedInFetchOptions}
 */
export const createInternalAuthLoggedInFetch = ({
  fetchFunction
}: CreateInternalAuthLoggedInFetchOptions) =>
  createLoggedInFetch({
    fetchFunction,
    getJwtToken: () => localStorage[INTERNAL_AUTH_TOKEN_LOCAL_KEY]
  });
