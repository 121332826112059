import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllQuotesFilterOptionsState } from './state';

const initialState: AllQuotesFilterOptionsState = {
  lenders: [],
  investors: [],
  loading: false
};

export const allQuotesFilterOptionsSlice = createSlice({
  name: 'allQuotesFilterOptions',
  initialState,
  reducers: {
    getAllQuotesFilterOptionsBegin: (_, __: PayloadAction<{ rateQuoteRequestId: string }>) => ({
      lenders: [],
      investors: [],
      loading: true
    }),
    getAllQuotesFilterOptionsFailed: (
      _,
      { payload: { error } }: PayloadAction<{ error?: string }>
    ) => ({
      lenders: [],
      investors: [],
      loading: false,
      error
    }),
    getAllQuotesFilterOptionsSucceeded: (
      _,
      { payload: { lenders, investors } }: PayloadAction<{ lenders: string[]; investors: string[] }>
    ) => ({
      lenders,
      investors,
      loading: false
    })
  }
});

export const {
  getAllQuotesFilterOptionsBegin,
  getAllQuotesFilterOptionsFailed,
  getAllQuotesFilterOptionsSucceeded
} = allQuotesFilterOptionsSlice.actions;

export const reducer = allQuotesFilterOptionsSlice.reducer;
