import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import {
  computeAssumptions,
  computeScenario,
  computeSummary
} from '../../modules/compute-rate-quote-request-display';
import { Purpose, RateQuote } from '../../modules/rate-quote/types';
import { ApplicationState } from '../../redux';

export const rateQuoteSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) => rateQuote,
  (rateQuote) => of(rateQuote)
);

export const lockedRateQuoteBySourceSelector = createSelector(
  ({ rateQuote: { lockedRateQuoteBySource } }: ApplicationState) => lockedRateQuoteBySource,
  (lockedRateQuoteBySource) => of(lockedRateQuoteBySource)
);

export const hasOutsideLockedOffer = (rateQuote: RateQuote) =>
  rateQuote.lockedOfferId &&
  !Object.values(rateQuote.offers)
    .filter(({ locked }) => locked)
    .map(({ offerId }) => offerId)
    .includes(rateQuote.lockedOfferId);

export const rateQuoteHasOutsideLockedOfferSelector = createSelector(
  rateQuoteSelector,
  (rateQuote) => rateQuote.map(hasOutsideLockedOffer)
);

export const productSetSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote.map(({ rateQuoteRequest: { productSet } }) => productSet).orElse(() => [])
);

export const purposeSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote.map((rq) => rq.offerType).orElse(() => 'purchase' as Purpose)
);

export const lastModifiedTimeSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote
    .map(({ rateQuoteRequest: { lastModifiedTime } }) => ({ lastModifiedTime }))
    .orElse(() => ({ lastModifiedTime: Date.now() }))
);

export const purchaseDetailsSelector = createSelector(
  rateQuoteSelector,
  ({ advisors: { advisors } }: ApplicationState) => advisors,
  (rateQuote, advisors) =>
    rateQuote
      .map(({ rateQuoteRequest }) => ({
        scenario: computeScenario(rateQuoteRequest),
        summary: computeSummary(rateQuoteRequest, advisors),
        assumptions: computeAssumptions(rateQuoteRequest)
      }))
      .orElse(() => ({
        scenario: [],
        summary: [],
        assumptions: []
      }))
);

export const refiDetailsSelector = createSelector(
  rateQuoteSelector,
  ({ advisors: { advisors } }: ApplicationState) => advisors,
  (rateQuote, advisors) =>
    rateQuote
      .map(({ rateQuoteRequest }) => ({
        scenario: computeScenario(rateQuoteRequest),
        summary: computeSummary(rateQuoteRequest, advisors),
        assumptions: computeAssumptions(rateQuoteRequest)
      }))
      .orElse(() => ({
        scenario: [],
        summary: [],
        assumptions: []
      }))
);

export const rateQuoteIdSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote.map(({ rateQuoteId }) => rateQuoteId).orElse(() => '')
);

export const rateQuoteRequestIdSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote.map(({ rateQuoteRequestId }) => rateQuoteRequestId).orElse(() => '')
);

export const rateQuoteRequestFieldSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote
    .map(({ rateQuoteRequest: { accountId, dealId } }) => ({
      accountId,
      dealId
    }))
    .orElse(() => ({ accountId: '', dealId: '' }))
);

export const rateQuoteRequesterSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote
    .map(({ rateQuoteRequest: { requester } }) => ({
      requester: requester || ''
    }))
    .orElse(() => ({ requester: '' }))
);

export const rateQuoteErrorSelector = createSelector(
  ({ rateQuote: { error } }: ApplicationState) => error,
  (error) => error
);

export const lockedOfferIdSelector = createSelector(rateQuoteSelector, (rateQuote) =>
  rateQuote.map((rq) => rq.lockedOfferId).orElse(() => null)
);
