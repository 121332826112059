import React from 'react';

export const FullStar = () => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon / star / hover</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icon-/-star-/-hover"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <polygon
        id="Star"
        stroke="#005450"
        fill="#005450"
        points="9.5 15.25 3.9160401 18.1856614 4.98248155 11.9678307 0.464963095 7.56433855 6.70802005 6.65716928 9.5 1 12.2919799 6.65716928 18.5350369 7.56433855 14.0175185 11.9678307 15.0839599 18.1856614"
      />
    </g>
  </svg>
);
