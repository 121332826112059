import { currency } from '@rategravity/frontend/modules/numbers';
import React from 'react';
import { NumberInput } from '../../forms/input';
import { CopyPMIButton } from '../fee-modal/copy-pmi-button';
import { BaseCard } from './shared';

type MI = { label: string; readonly: boolean; value?: number; error?: boolean };

interface MonthlyPaymentCardParams {
  data: {
    principalAndInterest: number;
    monthlyInsurance: number;
    monthlyTaxes: number;
    mi?: MI;
  };
  onChange?: (pmi?: number) => void;
  setCopyPMI?: () => void;
}

export const MonthlyPaymentCard = ({
  data: { principalAndInterest, monthlyInsurance, monthlyTaxes, mi },
  onChange,
  setCopyPMI
}: MonthlyPaymentCardParams) => (
  <BaseCard
    title="Monthly payment"
    subtitle={currency(
      principalAndInterest + monthlyInsurance + monthlyTaxes + (mi?.value ?? 0),
      0
    )}
  >
    <NumberInput key="P&I" label="P&I" inputType="money" disabled value={principalAndInterest} />
    <NumberInput
      key="Monthly Insurance"
      label="Monthly Insurance"
      inputType="money"
      disabled
      value={monthlyInsurance}
    />
    <NumberInput
      key="Monthly Taxes"
      label="Monthly Taxes"
      inputType="money"
      disabled
      value={monthlyTaxes}
    />
    {mi !== undefined ? (
      <NumberInput
        key="PMI"
        // show MI when calculated on backend for FHA loans
        label={mi.label}
        inputType="money"
        disabled={mi.readonly}
        onChange={({ value }) => onChange && onChange(value)}
        value={mi.value}
        error={mi.error}
      />
    ) : null}
    {mi !== undefined && !mi.readonly && setCopyPMI ? (
      <CopyPMIButton setCopyPMI={setCopyPMI} />
    ) : null}
  </BaseCard>
);
