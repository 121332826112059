import { loggedInFetch } from '../authentication';
import { queryStringCreator } from '../query-string-creator';
import { standardizeResponse } from '../standardize';
import { Deal, dealsConverter } from './types';

export type FetchDealsResult =
  | {
      success: true;
      json: Record<string, Deal>;
      error: null;
    }
  | {
      success: false;
      json: null;
      error: string;
    };

export const fetchDealsBySourceId = async (sourceId: string) =>
  await standardizeResponse(
    await loggedInFetch(
      `/api/contacts/${encodeURIComponent(sourceId)}/deals-v3${queryStringCreator({
        includeFunded: true,
        getCompleteDealInfo: true
      })}`
    ),
    dealsConverter
  );
