import { Offer } from '../rate-quote/types';

/**
 * Determines if an offer is incomplete by checking if PMI should be displayed
 * (is not a VA loan and LTV is over 80%) and PMI is undefined
 */

export const isIncomplete = (showPMI: boolean, pmi: number | undefined, fees: Offer['fees']) => {
  return (
    (showPMI && pmi === undefined) ||
    fees.some(
      ({ manualValue, computedValue }) => computedValue === 'ManualEntry' && manualValue === null
    )
  );
};
