import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  FETCH_DEALS_ACTION_TYPE,
  FETCH_DEALS_FAILURE_ACTION_TYPE,
  FETCH_DEALS_SUCCESS_ACTION_TYPE,
  FetchDealsAction,
  FetchDealsFailureAction,
  FetchDealsSuccessAction
} from './actions';
import { DealsState, defaultDealsState } from './state';

const handleFetchDealsAction = (state: DealsState, _: FetchDealsAction): DealsState => ({
  ...state,
  loading: true,
  error: null
});

const handleFetchDealsSuccessAction = (
  state: DealsState,
  { payload }: FetchDealsSuccessAction
): DealsState => ({ deals: payload, loading: false, error: null });

const handleFetchDealsFailureAction = (
  state: DealsState,
  { payload }: FetchDealsFailureAction
): DealsState => ({
  ...state,
  loading: false,
  error: `Error fetching deals: ${payload}`
});

export const dealsReducer = composeReducers(
  withDefault(defaultDealsState),
  ofType(FETCH_DEALS_SUCCESS_ACTION_TYPE, handleFetchDealsSuccessAction),
  ofType(FETCH_DEALS_FAILURE_ACTION_TYPE, handleFetchDealsFailureAction),
  ofType(FETCH_DEALS_ACTION_TYPE, handleFetchDealsAction)
);
