import { Logo } from '@rategravity/frontend/components/icons/svg-definitions';
import { styled } from 'baseui';
import React from 'react';
import { Container } from '../container';

const OwnupHeaderWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'align-items': 'center',
  width: '100%',
  height: '120px',
  backgroundColor: $theme.colors!.primary300
}));

export const OwnUpHeader = () => {
  return (
    <OwnupHeaderWrapper className="rg-header-wrapper">
      <Container>
        <svg width="194px" height="45px">
          <Logo />
        </svg>
      </Container>
    </OwnupHeaderWrapper>
  );
};
