import { AllQuoteTableProps } from '../../components/all-quotes-table';

/**
 * returns best offers (lowest rate & lowest closing costs) at each 1/8 increment
 * @param allOffers
 */

export const getBestOffers = (allOffers: AllQuoteTableProps[]): AllQuoteTableProps[] => {
  const best = allOffers.reduce(
    (acc, o) => {
      const { rate, closingCosts } = o;
      const normalizedRate = Math.floor(rate * 8) / 8;
      const current = acc[normalizedRate];
      if (!current) {
        return { ...acc, [normalizedRate]: o };
      }
      if (current.rate === rate) {
        return current.closingCosts < closingCosts ? acc : { ...acc, [normalizedRate]: o };
      }
      return current.rate < rate ? acc : { ...acc, [normalizedRate]: o };
    },
    {} as Record<number, AllQuoteTableProps>
  );

  return Object.values(best);
};
