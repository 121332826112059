import { currency, rate as formatRate } from '@rategravity/frontend/modules/numbers';
import { styled } from 'baseui';
import React from 'react';
import { getQuoteRate } from '../../modules/compute-rate-quote-request-display/get-quote-rate';
import { Offer } from '../../modules/rate-quote/types';
import { Product } from '../../modules/value-types/quote';
import { H4 } from '../headers';
import { Label1, Label2 } from '../labels';
import { Link } from '../link';

const AttributeWrapper = styled('div', {
  marginRight: '40px'
});
const MarginDiv = styled('div', {
  marginBottom: '15px'
});

interface AttributeRowProps<T> {
  label: React.ReactNode;
  value: T;
  format?: (arg: T) => React.ReactNode;
}

const AttributeRow = <T,>({ label, value, format = (i) => i }: AttributeRowProps<T>) => {
  return (
    <React.Fragment>
      <MarginDiv>
        <Label1>{label}</Label1>
        <Label2>{format(value)}</Label2>
      </MarginDiv>
    </React.Fragment>
  );
};

export interface LockOfferAttributes {
  product: Product;
  fullLender: Offer['lender'];
  rate: number;
  lenderCredit?: number;
  points?: number;
}

export const AttributesPanel = ({
  fullLender,
  product,
  rate,
  lenderCredit,
  points
}: LockOfferAttributes) => (
  <AttributeWrapper>
    <H4>ATTRIBUTES</H4>
    <div>
      <AttributeRow
        value={fullLender}
        label={'Lender'}
        format={({ url, name }) => <Link href={url}>{name}</Link>}
      />
      <AttributeRow value={product} label={'Product'} format={getQuoteRate} />
      <AttributeRow value={rate} label={'Rate'} format={(r) => formatRate(r, 3)} />
      {!!lenderCredit && lenderCredit > 0 && (
        <AttributeRow
          value={lenderCredit}
          label={'Lender Credit'}
          format={(lc) => currency(lc || 0, 0)}
        />
      )}
      {!!points && points > 0 && (
        <AttributeRow value={points} label={'Points'} format={() => currency(points || 0, 0)} />
      )}
    </div>
  </AttributeWrapper>
);
