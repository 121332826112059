import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkModal as LinkModalComponent } from '../components/link-modal/link-modal';
import { copyToClipboard } from '../modules/copyToClipboard';
import { closeLinkModal } from '../redux/link-modal/actions';
import {
  linkModalLoadingSelector,
  linkModalMessageSelector,
  linkModalNetworkErrorSelector,
  linkModalStateSelector,
  linkModalTitleSelector,
  linkSelector
} from '../selectors/link-modal';

export const LinkModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(linkModalStateSelector);
  const link = useSelector(linkSelector);
  const loading = useSelector(linkModalLoadingSelector);
  const message = useSelector(linkModalMessageSelector);
  const title = useSelector(linkModalTitleSelector);
  const networkError = useSelector(linkModalNetworkErrorSelector);

  const onClose = () => dispatch(closeLinkModal());

  return (
    <LinkModalComponent
      {...{
        isOpen,
        onClose,
        link,
        loading,
        message,
        title,
        networkError,
        copyToClipboard
      }}
    />
  );
};
