import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  CLOSE_LINK_MODAL_ACTION_TYPE,
  PREVIEW_RATE_QUOTE_ACTION_TYPE,
  PREVIEW_RATE_QUOTE_FAILURE_ACTION_TYPE,
  PREVIEW_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  PreviewRateQuoteFailureAction,
  PreviewRateQuoteSuccessAction,
  PUBLISH_RATE_QUOTE_ACTION_TYPE,
  PUBLISH_RATE_QUOTE_FAILURE_ACTION_TYPE,
  PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  PublishRateQuoteFailureAction,
  SUBMIT_RATE_QUOTE_ACTION_TYPE,
  SUBMIT_RATE_QUOTE_FAILURE_ACTION_TYPE,
  SUBMIT_RATE_QUOTE_SUCCESS_ACTION_TYPE,
  SubmitRateQuoteFailureAction
} from './actions';
import { defaultLinkModalState, LinkModalState } from './state';

export const formatRatesLink = (link: string) => {
  return `${process.env.MY_OWNUP_BASE_URL}rates/${link}`;
};

const handleCloseLinkModalAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  title: null,
  isOpen: false
});

const handlePublishRateQuoteRequestAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  isOpen: true,
  loading: true,
  title: 'Publishing...',
  networkError: null
});

const handlePreviewRateQuoteRequestAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  isOpen: true,
  loading: true,
  title: 'Preparing Preview...',
  networkError: null
});

const handleSubmitRateQuoteRequestAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  isOpen: true,
  loading: true,
  title: 'Submitting...',
  networkError: null
});

const handlePublishRateQuoteSuccessAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  link: null,
  loading: false,
  title: 'Successfully published.',
  message: "Updated offers have been published to the customer's account.",
  networkError: null
});

const handlePreviewRateQuoteSuccessAction = (
  state: LinkModalState,
  { payload }: PreviewRateQuoteSuccessAction
): LinkModalState => ({
  ...state,
  link: formatRatesLink(payload),
  loading: false,
  title: 'Preview rates.',
  message: 'Rates are ready for preview.',
  networkError: null
});

const handleSubmitRateQuoteRequestSuccessAction = (state: LinkModalState): LinkModalState => ({
  ...state,
  link: `${window.location.href}`,
  loading: false,
  title: 'Successfully submitted.',
  message: 'Rates are ready for advisor review.',
  networkError: null
});

const handleFailureAction = (
  state: LinkModalState,
  {
    payload
  }: PublishRateQuoteFailureAction | PreviewRateQuoteFailureAction | SubmitRateQuoteFailureAction
): LinkModalState => ({
  ...state,
  loading: false,
  title: 'Error',
  message: null,
  networkError: payload
});

export const linkModalReducer = composeReducers(
  withDefault(defaultLinkModalState),
  ofType(CLOSE_LINK_MODAL_ACTION_TYPE, handleCloseLinkModalAction),
  ofType(PUBLISH_RATE_QUOTE_ACTION_TYPE, handlePublishRateQuoteRequestAction),
  ofType(PUBLISH_RATE_QUOTE_SUCCESS_ACTION_TYPE, handlePublishRateQuoteSuccessAction),
  ofType(PREVIEW_RATE_QUOTE_ACTION_TYPE, handlePreviewRateQuoteRequestAction),
  ofType(PREVIEW_RATE_QUOTE_SUCCESS_ACTION_TYPE, handlePreviewRateQuoteSuccessAction),
  ofType(SUBMIT_RATE_QUOTE_ACTION_TYPE, handleSubmitRateQuoteRequestAction),
  ofType(SUBMIT_RATE_QUOTE_SUCCESS_ACTION_TYPE, handleSubmitRateQuoteRequestSuccessAction),
  ofType(
    [
      PUBLISH_RATE_QUOTE_FAILURE_ACTION_TYPE,
      PREVIEW_RATE_QUOTE_FAILURE_ACTION_TYPE,
      SUBMIT_RATE_QUOTE_FAILURE_ACTION_TYPE
    ],
    handleFailureAction
  )
);
