import { useMemo } from 'react';

type Predicate<T> = (i: T) => boolean;

const isPredicate = (v: unknown): v is Predicate<unknown> => typeof v === 'function';

type Data<T> = {
  [TKey in keyof T]?: T[TKey] extends Predicate<infer TInput> ? TInput : T[TKey];
};

export const toExclude = <TChecks extends Record<string, unknown>, T extends Array<Data<TChecks>>>(
  excludeIf: TChecks,
  data: T
) =>
  (Object.keys(excludeIf) as Array<keyof TChecks>).reduce(
    (exclude, key) => {
      const v = excludeIf[key];

      const predicateCheck = (r: Data<TChecks>) => {
        if (key in r) {
          if (isPredicate(v)) {
            return v(r[key]);
          } else {
            return r[key] === v;
          }
        }
        return true;
      };

      return {
        ...exclude,
        [key]: data.every((row) => predicateCheck(row))
      };
    },
    {} as { [K in keyof TChecks]: boolean }
  );

/**
 * Hook to evaulate whether or not a column should be excluded from a conditional
 *   column table.
 *
 * `data` and `excludeIf` should have the same fields. It will check each field in
 *   `data` against the criteria defined by `excludeIf`. If all the instances of
 *   `data` match the criteria in `excludeIf`, the column will be marked as excluded.
 *
 * For example:
 *  `excludeIf`: `{ a: 5, b: 3 }`
 *  `data`: `[{ a: 5, b: 2}, { a: 5, b: 3}]`
 *
 * Column `a` will be excluded because every object in `data` matched the exclusion
 *   criteria, but column `b` will not be excluded because some of the data did not
 *   match the exclusion criteria.
 *
 * `excludeIf` may also contain predicates, which are useful when exclusion criteria
 *    must be accessed by iterating over an array.
 *
 * @param excludeIf - Exclusion criteria.
 * @param data - Data set to evalute.
 */
export const useConditionalColumns = <
  TChecks extends Record<string, unknown>,
  T extends Array<Data<TChecks>>
>(
  excludeIf: TChecks,
  data: T
) => {
  return useMemo(() => toExclude(excludeIf, data), [excludeIf, data]);
};
