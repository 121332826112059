import { createSelector } from 'reselect';
import { rateQuoteRequestSelector } from '../../selectors/rate-quote-request';

const noOUTitleStates = {
  purchase: ['IL'],
  refinance: [] as string[]
};

export const disableOUTitleSelector = createSelector(rateQuoteRequestSelector, (rqr) =>
  rqr
    .map(({ purpose, property: { state } }) => noOUTitleStates[purpose].includes(state))
    .orElse(() => false)
);
