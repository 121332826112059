/**
 * Removes all undefined values in an object including all nested objects
 *
 * Our type converter sets values to undefined instead of removing the property
 * This removes the undefined literals and their pair keys
 *
 * @param obj Object to remove undefineds from.
 */
export const removeObjectUndefineds = <T extends object>(obj: T): Partial<T> =>
  Object.entries(obj).reduce((p, [key, val]) => {
    if (val === undefined) {
      return p;
    }
    if (Object.prototype.toString.call(val) === '[object Object]') {
      return {
        ...p,
        [key]: removeObjectUndefineds(val)
      };
    }
    return { ...p, [key]: val };
  }, {});
