import { GetProductDetailsResult } from '../../../modules/rate-quote';
import { EditableOffer } from '../state';

export const VALIDATE_OFFER_DETAILS_FORM_ACTION_TYPE = 'OFFER_DETAILS__VALIDATE_FORM' as const;
export const validateOfferDetailsForm = () => ({
  type: VALIDATE_OFFER_DETAILS_FORM_ACTION_TYPE
});
export type ValidateOfferDetailsFormAction = ReturnType<typeof validateOfferDetailsForm>;

export const CLOSE_OFFER_DETAILS_ACTION_TYPE = 'OFFER_DETAILS__CLOSE_MODAL' as const;
export const closeOfferDetails = () => ({ type: CLOSE_OFFER_DETAILS_ACTION_TYPE });
export type CloseOfferDetailsAction = ReturnType<typeof closeOfferDetails>;

export const CACHE_MODAL_STATE_ACTION_TYPE = 'OFFER_DETAILS__CACHE_MODAL' as const;
export const cacheModalState = (payload: { offer: EditableOffer; allQuotes: boolean }) => ({
  type: CACHE_MODAL_STATE_ACTION_TYPE,
  payload
});
export type CacheModalStateAction = ReturnType<typeof cacheModalState>;

export const RESTORE_MODAL_STATE_CACHE_ACTION_TYPE = 'OFFER_DETAILS__RESTORE_CACHED_MODAL' as const;
export const restoreModalStateCache = (payload: { offer: EditableOffer; allQuotes: boolean }) => ({
  type: RESTORE_MODAL_STATE_CACHE_ACTION_TYPE,
  payload
});
export type RestoreModalStateAction = ReturnType<typeof restoreModalStateCache>;

export const CLEAR_MODAL_STATE_CACHE_ACTION_TYPE = 'OFFER_DETAILS__CLEAR_CACHED_MODAL' as const;
export const clearModalStateCache = () => ({
  type: CLEAR_MODAL_STATE_CACHE_ACTION_TYPE
});
export type ClearModalStateCacheAction = ReturnType<typeof clearModalStateCache>;

// Product details Actions (Notes, Advisories, Adjustments)
export const GET_PRODUCT_DETAILS_ACTION_TYPE = 'OFFER_DETAILS__GET_PRODUCT_DETAILS' as const;
export const getProductDetails = (payload: { productId: number; searchId: string }) => ({
  type: GET_PRODUCT_DETAILS_ACTION_TYPE,
  payload
});
export type GetProductDetailsAction = ReturnType<typeof getProductDetails>;

export const GET_PRODUCT_DETAILS_SUCCESS_ACTION_TYPE =
  'OFFER_DETAILS__GET_PRODUCT_DETAILS_SUCCESS' as const;
export const getProductDetailsSuccess = (payload: GetProductDetailsResult) => ({
  type: GET_PRODUCT_DETAILS_SUCCESS_ACTION_TYPE,
  payload
});
export type GetProductDetailsSuccessAction = ReturnType<typeof getProductDetailsSuccess>;

export const GET_PRODUCT_DETAILS_FAILURE_ACTION_TYPE =
  'OFFER_DETAILS__GET_PRODUCT_DETAILS_FAILURE' as const;
export const getProductDetailsFailure = () => ({
  type: GET_PRODUCT_DETAILS_FAILURE_ACTION_TYPE
});
export type GetProductDetailsFailureAction = ReturnType<typeof getProductDetailsFailure>;
