import * as t from 'type-shift';
import { defaultFees, DefaultFees } from '../../default-fees/types';
import { borrowers, Borrowers } from '../../value-types/borrower';
import { loanType, LoanType } from '../../value-types/loan-type';
import {
  ClosingCostOption,
  closingCostOption,
  ClosingToCover,
  closingToCover,
  EscrowOptions,
  escrowOptions,
  InterestOptions,
  interestOptions
} from '../../value-types/payment-options';
import { property, Property } from '../../value-types/property';
import { adjustableQuote, fixedQuote, product, Product } from '../../value-types/quote';
import { ExistingLoan, existingLoanConverter } from './existing-loans';
import { purchaseOffer, refinanceOffer } from './offer';

interface BaseOfferPermutationAttributes {
  product: Product;
  loanType: LoanType;
  helocLine: number;
  closingCostOption: ClosingCostOption;
  escrowOptions: 'include' | 'waive';
  interestOptions: 'principalAndInterest' | 'interestOnly';
}

const baseOfferPermutationAttributes = t.strict({
  product,
  loanType,
  helocLine: t.number,
  closingCostOption,
  escrowOptions,
  interestOptions
});

interface PurchaseOfferPermutationAttributes extends BaseOfferPermutationAttributes {
  downPayment: number;
}

interface RefiOfferPermutationAttributes extends BaseOfferPermutationAttributes {
  irrrlEligible: boolean;
  existingLoans: ExistingLoan[];
  cashOut: number;
}

const purchaseOfferPermutationAttributes = t.strict({
  ...baseOfferPermutationAttributes.converters,
  downPayment: t.number
});

const refiOfferPermutationAttributes = t.strict({
  ...baseOfferPermutationAttributes.converters,
  irrrlEligible: t.boolean,
  existingLoans: t.array(existingLoanConverter),
  cashOut: t.number
});

export interface OfferPermutation {
  name: string;
  attributes: PurchaseOfferPermutationAttributes | RefiOfferPermutationAttributes;
  offerIds: string[];
}

export const offerPermutation = t.strict<OfferPermutation>({
  name: t.string,
  attributes: t.select<PurchaseOfferPermutationAttributes | RefiOfferPermutationAttributes>(
    (type: any) =>
      type.downPayment !== undefined
        ? purchaseOfferPermutationAttributes
        : refiOfferPermutationAttributes
  ),
  offerIds: t.array(t.string)
});

export interface AutoQuoteStatus {
  name: 'OBError' | 'QuotingError';
  type: 'timeout' | 'validation' | 'failedReq' | 'failedReqThrottling' | 'noProducts' | 'unknown';
}

interface CommonRateQuoteAttributes {
  rateQuoteId: string;
  rateQuoteRequestId: string;
  merchandising: string[];
  status: 'Open' | 'Loading' | 'Published';
  lastModifiedTime: number;
  publishingUser?: string;
  offerPermutations: OfferPermutation[];
  permutations?: any[];
  autoQuoteStatus: AutoQuoteStatus[];
  lockedOfferId: string | null;
}

const commonRateQuoteAttributes = t.strict<CommonRateQuoteAttributes>({
  rateQuoteId: t.string,
  rateQuoteRequestId: t.string,
  merchandising: t.array(t.string),
  status: t.oneOf(['Open', 'Loading', 'Published']),
  lastModifiedTime: t.number,
  publishingUser: t.string.or(t.undefined),
  offerPermutations: t.array(offerPermutation),
  permutations: t.array(t.unknown).or(t.undefined),
  autoQuoteStatus: t.array(
    t.strict({
      name: t.oneOf(['OBError', 'QuotingError']),
      type: t.oneOf([
        'timeout',
        'validation',
        'failedReq',
        'noProducts',
        'unknown',
        'failedReqThrottling'
      ])
    })
  ),
  lockedOfferId: t.string.or(t.null).default(() => null)
});

interface BasePermutedProduct {
  loanAmount: number;
  product: Product & { dti: number };
  closingCostOption: ClosingCostOption;
  closingToCover: ClosingToCover;
  loanType: LoanType;
  helocLine: number;
  escrowOptions: EscrowOptions;
  interestOptions: InterestOptions;
  ltv: number;
  conforming: boolean;
  dti: number;
  lenders: Record<string, DefaultFees>;
  constraints?: any;
}

const basePermutedProduct = t.strict<BasePermutedProduct>({
  loanAmount: t.number,
  product: t.taggedUnion<Product & { dti: number }>('rate', {
    fixed: t.strict({ ...fixedQuote.converters, dti: t.number }),
    adjustable: t.strict({ ...adjustableQuote.converters, dti: t.number })
  }),
  closingCostOption,
  closingToCover,
  loanType,
  helocLine: t.number,
  escrowOptions,
  interestOptions,
  ltv: t.number,
  conforming: t.boolean,
  dti: t.number,
  lenders: t.record(defaultFees),
  constraints: t.undefined.or(t.unknown)
});

const additionalContext = t.array(
  t.strict({
    name: t.string,
    value: t.string
  })
);

export type AdditionalContext = t.TypeOf<typeof additionalContext>;

interface BaseRateQuoteRequest {
  property: Property;
  nonOccupantBorrower: boolean;
  minimumCreditScore: number;
  requester: string | null;
  additionalContext: AdditionalContext;
  borrowers: Borrowers;
  lastModifiedTime: number;
  borrowerScenarioId: string;
  sourceId: string;
  accountId: string;
  dealId: string;
}

interface PurchaseProduct extends BasePermutedProduct {
  downPayment: number;
}

interface PurchaseRateQuoteRequest extends BaseRateQuoteRequest {
  purpose: 'purchase';
  closeDate: number | null;
  contingencyDate: number | null;
  acceptedOfferId: string | null;
  purchasePrice: number;
  property: Property;
  productSet: PurchaseProduct[];
  sellerConcessions: number | null;
}

const purchaseRateQuoteRequest = t.strict<PurchaseRateQuoteRequest>({
  purpose: t.literal('purchase'),
  closeDate: t.number.or(t.null),
  contingencyDate: t.number.or(t.null),
  acceptedOfferId: t.string.or(t.null),
  purchasePrice: t.number,
  property,
  nonOccupantBorrower: t.boolean,
  minimumCreditScore: t.number,
  requester: t.string.or(t.null),
  additionalContext,
  productSet: t.array(
    t.strict({
      ...basePermutedProduct.converters,
      downPayment: t.number
    })
  ),
  borrowers,
  lastModifiedTime: t.number,
  borrowerScenarioId: t.string,
  sourceId: t.string,
  accountId: t.string,
  dealId: t.string,
  sellerConcessions: t.number.or(t.null)
});

interface RefinanceProduct extends BasePermutedProduct {
  cltv: number;
  cashOut: number;
  totalCashOut: number;
  loanAmount: number;
  existingLoans: ExistingLoan[];
  irrrlEligible: boolean;
}

interface RefinanceRateQuoteRequest extends BaseRateQuoteRequest {
  purpose: 'refinance';
  propertyValue: number;
  productSet: RefinanceProduct[];
}

const refinanceRateQuoteRequest = t.strict<RefinanceRateQuoteRequest>({
  purpose: t.literal('refinance'),
  propertyValue: t.number,
  property,
  nonOccupantBorrower: t.boolean,
  minimumCreditScore: t.number,
  requester: t.string.or(t.null),
  additionalContext,
  productSet: t.array(
    t.strict({
      ...basePermutedProduct.converters,
      cltv: t.number,
      cashOut: t.number,
      totalCashOut: t.number,
      loanAmount: t.number,
      existingLoans: t.array(existingLoanConverter),
      irrrlEligible: t.boolean
    })
  ),
  borrowers,
  lastModifiedTime: t.number,
  borrowerScenarioId: t.string,
  sourceId: t.string,
  accountId: t.string,
  dealId: t.string
});

export const isPurchaseRQR = (
  rqr: PurchaseRateQuoteRequest | RefinanceRateQuoteRequest
): rqr is PurchaseRateQuoteRequest => rqr.purpose === 'purchase';

export const purchaseRateQuoteNoRqr = t.strict({
  ...commonRateQuoteAttributes.converters,
  offerType: t.literal('purchase'),
  offers: t.record(purchaseOffer)
});

export const purchaseRateQuote = t.strict({
  ...purchaseRateQuoteNoRqr.converters,
  rateQuoteRequest: purchaseRateQuoteRequest,
  defaultTitleProvider: t.string.or(t.null)
});

export const refinanceRateQuoteNoRqr = t.strict({
  ...commonRateQuoteAttributes.converters,
  offerType: t.literal('refinance'),
  offers: t.record(refinanceOffer)
});

export const refinanceRateQuote = t.strict({
  ...refinanceRateQuoteNoRqr.converters,
  rateQuoteRequest: refinanceRateQuoteRequest,
  defaultTitleProvider: t.string.or(t.null)
});

export const rateQuoteNoRqr = purchaseRateQuoteNoRqr.or(refinanceRateQuoteNoRqr);
export type RateQuoteNoRqr = t.TypeOf<typeof rateQuoteNoRqr>;

export type PurchaseRateQuote = t.TypeOf<typeof purchaseRateQuote>;
export type RefinanceRateQuote = t.TypeOf<typeof refinanceRateQuote>;

export const rateQuote = t.taggedUnion<PurchaseRateQuote | RefinanceRateQuote>('offerType', {
  purchase: purchaseRateQuote,
  refinance: refinanceRateQuote
});

export type RateQuote = t.TypeOf<typeof rateQuote>;
export type LockedRateQuote = Pick<RateQuote, 'rateQuoteId'>;
export type RateQuoteRequest = PurchaseRateQuoteRequest | RefinanceRateQuoteRequest;
export type ProductSet = PurchaseProduct | RefinanceProduct;

export type Purpose = RateQuoteRequest['purpose'];

export const closingCostLabel = (closingCost: string) => {
  switch (closingCost) {
    case 'lenderCredit':
      return 'Lender Credit';
    case 'financeClosing':
      return 'Finance Closing';
    case 'fullCost':
      return 'Full Cost';
    default:
      return 'None';
  }
};

export const closingToCoverLabel = (ctc: string | null) => {
  switch (ctc) {
    case 'justClosing':
      return 'Just Closing';
    case 'allFees':
      return 'All Fees';
    default:
      return 'None';
  }
};
