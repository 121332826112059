import React from 'react';
import { AutoQuoteStatus } from '../../modules/rate-quote/types';
import { BoldTextNotice } from '../notice';

export const AutoQuoteStatusComponent = ({
  status: { name, type }
}: {
  status: AutoQuoteStatus;
}) => {
  switch (type) {
    case 'noProducts':
      return <NoProductsNotice errorName={name} />;
    case 'validation':
      return <ValidationNotice />;
    case 'failedReqThrottling':
      return (
        <BoldTextNotice
          boldText="Throttling Error."
          body=" OB is temporarily limiting Own Up’s ability to pull offers. Please wait a few minutes and try again."
          type="error"
        />
      );
    case 'timeout':
    case 'failedReq':
    case 'unknown':
    default:
      return (
        <BoldTextNotice
          boldText="Error retrieving auto quotes."
          body=" The tech team has been notified. Try refreshing your rate request."
          type="error"
        />
      );
  }
};

const NoProductsNotice = ({ errorName }: { errorName: AutoQuoteStatus['name'] }) =>
  errorName === 'QuotingError' ? (
    <BoldTextNotice
      boldText="No Products Found."
      body=" Broaden your product set or conduct manual quote"
      type="notice"
    />
  ) : (
    <BoldTextNotice boldText="No offers were added." type="notice" />
  );

const ValidationNotice = () => (
  <BoldTextNotice boldText="Autoquotes does not quote non US citizens" type="notice" />
);
