import React from 'react';

export const Recalculate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15">
    <g id="MVP-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="RQR-MVP-6" transform="translate(-1115.000000, -1295.000000)">
        <g id="Group-31" transform="translate(187.000000, 459.000000)">
          <g id="Group-6">
            <g id="Group-40" transform="translate(269.000000, 715.000000)">
              <g id="Group-21" transform="translate(485.000000, 83.000000)">
                <g id="Group-11" transform="translate(11.000000, 31.000000)">
                  <g id="Recalculate" transform="translate(161.000000, 5.000000)">
                    <path
                      d="M9.5,3.02081528 C5.91014913,3.02081528 3,5.93096441 3,9.52081528 C3,13.1106662 5.91014913,16.0208153 9.5,16.0208153 C13.0898509,16.0208153 16,13.1106662 16,9.52081528 C16,7.5974159 15.1645867,5.86913775 13.836821,4.67904168"
                      id="Path-Copy"
                      stroke="#005450"
                      strokeWidth="1.5"
                      transform="translate(9.500000, 9.520815) rotate(-300.000000) translate(-9.500000, -9.520815) "
                    ></path>
                    <polyline
                      id="Path-Copy-2"
                      fill="#005450"
                      transform="translate(13.656854, 5.000000) rotate(-90.000000) translate(-13.656854, -5.000000) "
                      points="16.6568542 8 10.6568542 8 10.6568542 2"
                    ></polyline>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
