export interface LinkModalState {
  isOpen: boolean;
  link: string | null;
  loading: boolean;
  title: string | null;
  message: string | null;
  networkError: string | null;
}

export const defaultLinkModalState: LinkModalState = {
  isOpen: false,
  link: null,
  loading: false,
  title: null,
  message: null,
  networkError: null
};
