import { Card } from 'baseui/card';
import { Table } from 'baseui/table';
import React, { PropsWithChildren } from 'react';
import { Notice } from '../../notice';
import { Spinner } from '../../spinner';
import { cardOverrides, CardText, CardTitle, DetailsContainer } from './shared';

export type NotesAndAdvisories = string[];

export type Adjustments = {
  reason: string;
  adjustor: number;
  type: string;
}[];

const NotesAdvisoriesAndAdjustmentsContainer = ({
  loading,
  error,
  children
}: PropsWithChildren<{ loading: boolean; error: boolean }>) => {
  if (loading) {
    return (
      <DetailsContainer>
        <Spinner size={60} centerVertically={false} />
      </DetailsContainer>
    );
  }
  if (error) {
    return (
      <DetailsContainer>
        <Notice message="Unable to load Notes, Advisories, and Adjustments. If the problem persists please contact #tech-support" />
      </DetailsContainer>
    );
  }
  return <DetailsContainer>{children}</DetailsContainer>;
};

const NotesAndAdvisories = ({ children }: { children: string[] }) => {
  if (children.length > 0) {
    return (
      <ul>
        {children.map((note, i) => (
          <li key={i}>
            <CardText>{note}</CardText>
          </li>
        ))}
      </ul>
    );
  }
  return <CardText>No Notes &amp; Advisories</CardText>;
};

const Adjustments = ({ children }: { children: (string | number)[][] }) => {
  if (children.length > 0) {
    return <Table data={children} columns={['Type', 'Adjustor', 'Reason']} />;
  }
  return <CardText>No Adjustments</CardText>;
};

export interface NotesAdvisoriesAdjustmentsCardProps {
  loading: boolean;
  error: boolean;
  notesAndAdvisories: NotesAndAdvisories;
  adjustments: Adjustments;
}

export const NotesAdvisoriesAdjustmentsCard = ({
  loading,
  error,
  notesAndAdvisories,
  adjustments
}: NotesAdvisoriesAdjustmentsCardProps) => {
  const title = 'Notes, Advisories, and Adjustments';
  const adjustmentsTableData = adjustments.map(({ type, adjustor, reason }) => [
    type,
    adjustor,
    reason
  ]);
  return (
    <Card
      title={title}
      overrides={{
        Title: {
          component: CardTitle,
          props: { title }
        },
        ...cardOverrides
      }}
    >
      <NotesAdvisoriesAndAdjustmentsContainer loading={loading} error={error}>
        <NotesAndAdvisories>{notesAndAdvisories}</NotesAndAdvisories>
        <Adjustments>{adjustmentsTableData}</Adjustments>
      </NotesAdvisoriesAndAdjustmentsContainer>
    </Card>
  );
};
