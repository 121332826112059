import { Offer } from '../../../../modules/rate-quote/types';

/*
 * Given an offer to create/update, this returns an array of offers that should have its PMI values updated
 * to the same value as it is in the original offer
 *
 * @param originalOffer, the offer that is currently being updated/created
 * @param offers, a record of offers currently in the store
 */
export const manualPmiHelpers = (
  originalOffer: Partial<Offer>,
  offers: Record<string, Offer>
): Offer[] => {
  const origLTV = originalOffer?.attributes?.ltv;
  const origTerm = originalOffer?.attributes?.product?.term;
  const origLoanType = originalOffer?.attributes?.loanType;
  return (Object.values(offers) as Offer[]).filter(
    (o) =>
      o.attributes.ltv === origLTV &&
      o.attributes.product.term === origTerm &&
      o.attributes.loanType === origLoanType &&
      o.offerId !== originalOffer.offerId
  );
};
