import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  FETCH_ADVISORS_FAILURE_ACTION_TYPE,
  FETCH_ADVISORS_SUCCESS_ACTION_TYPE,
  FetchAdvisorsFailureAction,
  FetchAdvisorsSuccessAction
} from './actions';
import { AdvisorState } from './state';

const handleFetchAdvisorsSuccess = (
  state: AdvisorState,
  { payload }: FetchAdvisorsSuccessAction
): AdvisorState => ({
  ...state,
  advisors: payload,
  loading: false,
  error: null
});

const handleFetchAdvisorsFailure = (
  state: AdvisorState,
  { error }: FetchAdvisorsFailureAction
): AdvisorState => ({
  ...state,
  loading: false,
  error
});

export const advisorsReducer = composeReducers(
  withDefault({ advisors: {}, loading: true, error: null } as AdvisorState),
  ofType(FETCH_ADVISORS_SUCCESS_ACTION_TYPE, handleFetchAdvisorsSuccess),
  ofType(FETCH_ADVISORS_FAILURE_ACTION_TYPE, handleFetchAdvisorsFailure)
);
