import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Accordion } from '../../components/accordion';
import { AllQuotesTable } from '../../components/all-quotes-table';
import { ErrorModal } from '../../components/error-modal';
import { Bold, Notice } from '../../components/notice';
import { toExclude } from '../../hooks/use-conditional-columns';
import { getBestOffers } from '../../modules/all-quotes/best-offers';
import { addOfferToRQ, toggleSameOfferModal } from '../../redux/all-quotes/slice';
import { getProductDetails, seedOffer } from '../../redux/offer-details/actions';
import {
  allQuotesOfferPermutationsSelector,
  allQuotesSelector,
  allQuotesTableSelector,
  transformQuotes
} from '../../selectors/all-quotes';
import { offersSelector } from '../../selectors/offer';
import { productSetSelector } from '../../selectors/rate-quote/rate-quote';
import { formatAttributes } from '../offer-table';
import { Filters } from './filter-section';

export const offerPermutationsSelector = createSelector(
  allQuotesOfferPermutationsSelector,
  allQuotesSelector,
  productSetSelector,
  offersSelector,
  (permutations, allQuotes, productSet, publishedOffers) =>
    permutations.map((p) => {
      const offers = Object.values(allQuotes)
        .filter((offer) => p.offerIds.includes(offer.offerId))
        .map((offer) => transformQuotes(offer, Object.keys(publishedOffers)));
      const name = !toExclude({ loanType: 'Standard' }, productSet).loanType
        ? p.name + ' - ' + p.attributes.loanType
        : p.name;
      return {
        offers,
        attributes: formatAttributes(p.attributes, productSet),
        name
      };
    })
);

export const AllQuotes = ({}) => {
  const dispatch = useDispatch();
  const { loading, error, includeOfferLoading, isSameOfferModalOpen } =
    useSelector(allQuotesTableSelector);
  const allQuotes = useSelector(allQuotesSelector);
  const allQuotesOfferPermutations = useSelector(offerPermutationsSelector);
  const getOffer = (offerId: string) => allQuotes[offerId];
  const viewDetails = ({
    productId,
    searchId,
    offerId
  }: {
    productId?: number;
    searchId?: string;
    offerId: string;
  }) => {
    const hasAdjustmentsOrNotes =
      getOffer(offerId).adjustments.length > 0 || getOffer(offerId).notesAndAdvisories.length > 0;
    if (!hasAdjustmentsOrNotes && !!productId && !!searchId) {
      dispatch(getProductDetails({ productId, searchId }));
    }
    dispatch(seedOffer(getOffer(offerId), true));
  };
  const includeOffer = (offerId: string) => {
    dispatch(addOfferToRQ({ offerId }));
  };

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (loading) {
      setHasLoaded(true);
    }
  }, [loading]);
  const [showBest, setShowBest] = useState<boolean>(false);

  return (
    <>
      <ErrorModal
        isOpen={isSameOfferModalOpen}
        message="This offer is already included in the Rate Quote."
        onClose={() => dispatch(toggleSameOfferModal({ isOpen: false }))}
      />
      <Filters showBest={showBest} setShowBest={setShowBest} />
      {error}
      {loading && <div>Loading all offers...</div>}
      {allQuotesOfferPermutations.flatMap(({ offers }) => offers).length > 0
        ? allQuotesOfferPermutations.map(({ name, offers, attributes }, i) => {
            const bestOffers = getBestOffers(offers);
            return (
              <Accordion
                name={name}
                arrowDisplay={`${showBest ? `${bestOffers.length} / ` : ''} ${
                  offers.length
                } offers`}
                fields={attributes}
                key={i}
              >
                <AllQuotesTable
                  data={showBest ? bestOffers : offers}
                  viewDetails={viewDetails}
                  includeOffer={includeOffer}
                  includeOfferLoading={includeOfferLoading}
                  bestOffers={bestOffers.map(({ offerId }) => offerId)}
                />
              </Accordion>
            );
          })
        : !loading &&
          hasLoaded && (
            <Notice
              type="notice"
              message={
                <div>
                  <Bold>No Products Found.</Bold> Try adjusting your filters
                </div>
              }
            />
          )}
    </>
  );
};
