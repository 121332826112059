import { LoanType } from '../value-types/loan-type';

/**
 * Determines which label to show between "Conf." "HB" and "Jumbo"
 * These labels only make sense on Standard loan types, so just return default Conf. for other loan types
 * @param loanType
 * @param conforming whether loan is below the county conforming loan limit
 * @param aboveNationalBaseline whether loan is above the national conforming loan limit
 */
export const highBalanceOrJumbo = (
  loanType: LoanType,
  conforming: boolean,
  aboveNationalBaseline: boolean
) => {
  if (loanType === 'Standard' && aboveNationalBaseline) {
    return conforming ? 'HB' : 'Jumbo';
  }
  return 'Conf.';
};
