import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { advisorsReducer } from './advisors/reducer';
import { advisorsSaga } from './advisors/sagas';
import { AdvisorState } from './advisors/state';
import { allQuotesSaga } from './all-quotes/saga';
import { reducer as allQuotesReducer } from './all-quotes/slice';
import { AllQuotesState } from './all-quotes/state';
import { allQuotesFilterOptionsSaga } from './all-quotes-filter-options/saga';
import { reducer as allQuotesFilterOptionsReducer } from './all-quotes-filter-options/slice';
import { AllQuotesFilterOptionsState } from './all-quotes-filter-options/state';
import { dealsReducer } from './deals/reducer';
import { dealsSaga } from './deals/saga';
import { DealsState } from './deals/state';
import { captureErrors as captureErrorsSaga } from './error-logging/sagas';
import { linkModalReducer } from './link-modal/reducer';
import { linkModalSaga } from './link-modal/sagas';
import { LinkModalState } from './link-modal/state';
import { lockModalReducer } from './lock-modal/reducer';

import { lockModalSaga } from './lock-modal/sagas';
import { LockModalState } from './lock-modal/state';
import { offerDetailsReducer } from './offer-details/reducer';
import { offerDetailsSaga } from './offer-details/sagas';
import { OfferDetailsState } from './offer-details/state';
import { rateQuoteReducer } from './rate-quote/reducer';
import { rateQuoteSaga } from './rate-quote/sagas';
import { RateQuoteState } from './rate-quote/state';

export interface ApplicationState {
  rateQuote: RateQuoteState;
  offerDetails: OfferDetailsState;
  advisors: AdvisorState;
  linkModal: LinkModalState;
  lockModal: LockModalState;
  deals: DealsState;
  allQuotes: AllQuotesState;
  allQuotesFilterOptions: AllQuotesFilterOptionsState;
}

const reducer = combineReducers({
  rateQuote: rateQuoteReducer as Reducer<RateQuoteState | undefined>,
  offerDetails: offerDetailsReducer as Reducer<OfferDetailsState | undefined>,
  advisors: advisorsReducer as Reducer<AdvisorState | undefined>,
  linkModal: linkModalReducer as Reducer<LinkModalState | undefined>,
  lockModal: lockModalReducer as Reducer<LockModalState | undefined>,
  deals: dealsReducer as Reducer<DealsState | undefined>,
  allQuotes: allQuotesReducer as Reducer<AllQuotesState | undefined>,
  allQuotesFilterOptions: allQuotesFilterOptionsReducer as Reducer<
    AllQuotesFilterOptionsState | undefined
  >
});

const saga = function* () {
  yield all([
    fork(rateQuoteSaga),
    fork(offerDetailsSaga),
    fork(advisorsSaga),
    fork(linkModalSaga),
    fork(captureErrorsSaga),
    fork(lockModalSaga),
    fork(dealsSaga),
    fork(allQuotesSaga),
    fork(allQuotesFilterOptionsSaga)
  ]);
};

const sagaMiddleware = createSagaMiddleware();

// Attempt to hook up REDUX DevTools if they exist...
const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(saga);
