import { BaseProvider, createTheme, lightThemePrimitives, styled } from 'baseui';
import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import 'normalize.css';
import { Provider as StyletronProvider } from 'styletron-react';
import { GraphikRegular } from './graphik-regular';
import { GraphikSemibold } from './graphik-semibold';
import { StylableBody } from './stylable-body';
import { Tiempos } from './tiempos';

const engine = new Styletron();

const semiboldFontFace = engine.renderFontFace(GraphikSemibold);
export const headerFontFace = engine.renderFontFace(Tiempos);

const lightGrey = '#B8C5C4';
const mediumGrey = '#64807F';
const lightForest = '#3F7E7B';
const mediumForest = '#005450';
const darkForest = '#143F3D';
const darkerForest = '#133A38';
const darkestForest = '#113432';
const blackForest = '#0F2E2D';
const positiveGreenBG = '#CEF9E1';
const positiveGreenText = '#3C9E6A';
const warningYellowGB = '#FFECBE';
const warningYellowText = '#DD9010';
const negativeRedBG = '#FAE5E1';
const negativeRedText = '#DF5133';

const OfferEntryTheme = createTheme(
  {
    ...lightThemePrimitives,
    primary50: lightGrey,
    primary100: mediumGrey,
    primary200: lightForest,
    primary300: mediumForest,
    primary400: darkForest,
    primary500: darkerForest,
    primary600: darkestForest,
    primary700: blackForest,
    mono100: '#F8F8F8',
    mono200: '#F1F1F1',
    mono300: lightGrey,
    mono400: mediumGrey,
    mono500: lightForest,
    mono600: mediumForest,
    mono700: darkForest,
    mono800: darkerForest,
    mono900: darkestForest,
    accent: darkerForest,
    accent50: lightGrey,
    accent100: mediumGrey,
    accent200: lightForest,
    accent300: mediumForest,
    accent400: darkForest,
    accent500: darkerForest,
    accent600: darkestForest,
    accent700: blackForest,
    positive: darkerForest,
    positive50: lightGrey,
    positive100: mediumGrey,
    positive200: lightForest,
    positive300: mediumForest,
    positive400: darkForest,
    positive500: darkerForest,
    positive600: darkestForest,
    positive700: blackForest,
    primaryFontFamily: engine.renderFontFace(GraphikRegular)
  },
  {
    typography: {
      font150: {
        fontFamily: semiboldFontFace
      },
      font250: {
        fontFamily: semiboldFontFace
      },
      font350: {
        fontFamily: semiboldFontFace
      }
    },
    colors: {
      notificationPositiveBackground: positiveGreenBG,
      notificationPositiveText: positiveGreenText,
      notificationWarningBackground: warningYellowGB,
      notificationWarningText: warningYellowText,
      notificationNegativeBackground: negativeRedBG,
      notificationNegativeText: negativeRedText
    }
  }
);

const StyledBody = styled(StylableBody, ({ $theme }) => ({
  backgroundColor: $theme.colors!.mono100,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0
}));

export const ThemeProvider = ({ children }: React.PropsWithChildren<{}>) => (
  <StyletronProvider value={engine}>
    <BaseProvider theme={OfferEntryTheme}>
      <StyledBody>{children}</StyledBody>
    </BaseProvider>
  </StyletronProvider>
);
